import { grey } from '@pelotoncycle/design-system';
import React from 'react';
import styled from 'styled-components';
import { Footer } from '@account/footer';
import { Audience } from '@onewellness/routes';
import { HeaderView } from '@onewellness/ui';

export const PageWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Container>
    <HeaderView audience={Audience.Hyatt} headerStyle="dark" />
    <Inner>{children}</Inner>
    <Footer />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  background: ${grey[80]};
`;
