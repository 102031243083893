import { datadogRum } from '@datadog/browser-rum';
import { MONITORING_ENV, COMMIT_REF } from '@peloton/app-config';

export const initDatadogRum = () => {
  datadogRum.init({
    applicationId: '1c504239-2d6c-4286-a522-a72dcd904bed',
    clientToken: 'pub480f4cef7391531bc264b29e802177b7',
    site: 'datadoghq.com',
    service: 'account',
    env: MONITORING_ENV,
    version: COMMIT_REF,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    silentMultipleInit: true,
  });
  datadogRum.startSessionReplayRecording();
};
