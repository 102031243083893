// THIS IS AN AUTOGENERATED FILE (codegen.yml).
/* eslint-disable */
import { gql } from '@apollo/client';
import * as ApolloReactCommon from './useQuery';
import * as ApolloReactHooks from './useQuery';
export type Maybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * A date-time string at UTC, such as 2007-12-03T10:15:30Z,
   *     compliant with the 'date-time' format outlined in section 5.6 of
   *     the RFC 3339 profile of the ISO 8601 standard for representation
   *     of dates and times using the Gregorian calendar.
   */
  DateTime: any;
  /** The 'Dimension' type represents dimensions as whole numeric values between `1` and `4000`. */
  Dimension: any;
  /** The 'Quality' type represents quality as whole numeric values between `1` and `100`. */
  Quality: any;
  /** The 'HexColor' type represents color in `rgb:ffffff` string format. */
  HexColor: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type _Node = {
  _id: Scalars['ID'];
};

/** This holds all values for the account app. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountKeyValue) */
export type AccountKeyValue = Entry & _Node & {
  __typename?: 'AccountKeyValue';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<AccountKeyValueLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};


/** This holds all values for the account app. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountKeyValue) */
export type AccountKeyValueLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This holds all values for the account app. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountKeyValue) */
export type AccountKeyValueKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** This holds all values for the account app. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountKeyValue) */
export type AccountKeyValueValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type AccountKeyValueCollection = {
  __typename?: 'AccountKeyValueCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<AccountKeyValue>>;
};

export type AccountKeyValueFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<AccountKeyValueFilter>>>;
  AND: Maybe<Array<Maybe<AccountKeyValueFilter>>>;
};

export type AccountKeyValueLinkingCollections = {
  __typename?: 'AccountKeyValueLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type AccountKeyValueLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum AccountKeyValueOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadata = Entry & _Node & {
  __typename?: 'AccountMetadata';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<AccountMetadataLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  keywords: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype: Maybe<Scalars['String']>;
  image: Maybe<Asset>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataKeywordsArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataOgtypeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Account Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/accountMetadata) */
export type AccountMetadataImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type AccountMetadataCollection = {
  __typename?: 'AccountMetadataCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<AccountMetadata>>;
};

export type AccountMetadataFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  keywords_exists: Maybe<Scalars['Boolean']>;
  keywords_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  keywords_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  keywords_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_exists: Maybe<Scalars['Boolean']>;
  ogtype: Maybe<Scalars['String']>;
  ogtype_not: Maybe<Scalars['String']>;
  ogtype_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_contains: Maybe<Scalars['String']>;
  ogtype_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<AccountMetadataFilter>>>;
  AND: Maybe<Array<Maybe<AccountMetadataFilter>>>;
};

export type AccountMetadataLinkingCollections = {
  __typename?: 'AccountMetadataLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type AccountMetadataLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum AccountMetadataOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OgtypeAsc = 'ogtype_ASC',
  OgtypeDesc = 'ogtype_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  contentType: Maybe<Scalars['String']>;
  fileName: Maybe<Scalars['String']>;
  size: Maybe<Scalars['Int']>;
  url: Maybe<Scalars['String']>;
  width: Maybe<Scalars['Int']>;
  height: Maybe<Scalars['Int']>;
  linkedFrom: Maybe<AssetLinkingCollections>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  transform: Maybe<ImageTransformOptions>;
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Asset>>;
};

export type AssetFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  url_exists: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  url_not: Maybe<Scalars['String']>;
  url_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains: Maybe<Scalars['String']>;
  url_not_contains: Maybe<Scalars['String']>;
  size_exists: Maybe<Scalars['Boolean']>;
  size: Maybe<Scalars['Int']>;
  size_not: Maybe<Scalars['Int']>;
  size_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  size_gt: Maybe<Scalars['Int']>;
  size_gte: Maybe<Scalars['Int']>;
  size_lt: Maybe<Scalars['Int']>;
  size_lte: Maybe<Scalars['Int']>;
  contentType_exists: Maybe<Scalars['Boolean']>;
  contentType: Maybe<Scalars['String']>;
  contentType_not: Maybe<Scalars['String']>;
  contentType_in: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType_contains: Maybe<Scalars['String']>;
  contentType_not_contains: Maybe<Scalars['String']>;
  fileName_exists: Maybe<Scalars['Boolean']>;
  fileName: Maybe<Scalars['String']>;
  fileName_not: Maybe<Scalars['String']>;
  fileName_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fileName_contains: Maybe<Scalars['String']>;
  fileName_not_contains: Maybe<Scalars['String']>;
  width_exists: Maybe<Scalars['Boolean']>;
  width: Maybe<Scalars['Int']>;
  width_not: Maybe<Scalars['Int']>;
  width_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  width_gt: Maybe<Scalars['Int']>;
  width_gte: Maybe<Scalars['Int']>;
  width_lt: Maybe<Scalars['Int']>;
  width_lte: Maybe<Scalars['Int']>;
  height_exists: Maybe<Scalars['Boolean']>;
  height: Maybe<Scalars['Int']>;
  height_not: Maybe<Scalars['Int']>;
  height_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  height_gt: Maybe<Scalars['Int']>;
  height_gte: Maybe<Scalars['Int']>;
  height_lt: Maybe<Scalars['Int']>;
  height_lte: Maybe<Scalars['Int']>;
  OR: Maybe<Array<Maybe<AssetFilter>>>;
  AND: Maybe<Array<Maybe<AssetFilter>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productDetailsCardCollection: Maybe<ProductDetailsCardCollection>;
  productRecommendationsTileCollection: Maybe<ProductRecommendationsTileCollection>;
  productCollection: Maybe<ProductCollection>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
  videoCollection: Maybe<VideoCollection>;
  imageCollection: Maybe<ImageCollection>;
  staticAssetCollection: Maybe<StaticAssetCollection>;
  valuePropCollection: Maybe<ValuePropCollection>;
  metadataCollection: Maybe<MetadataCollection>;
  accountMetadataCollection: Maybe<AccountMetadataCollection>;
  pressQuoteCollection: Maybe<PressQuoteCollection>;
  contentType2LKn3Xvlo4UCmGcweGaCyMCollection: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMCollection>;
  heroCollection: Maybe<HeroCollection>;
  boardOfDirectorCollection: Maybe<BoardOfDirectorCollection>;
  trackCollection: Maybe<TrackCollection>;
  contentType1VcIyFnR8OC2Imaa2Su2W2Collection: Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Collection>;
};


export type AssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsProductDetailsCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsProductRecommendationsTileCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsProductCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsStaticAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsValuePropCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsMetadataCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsAccountMetadataCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsPressQuoteCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsContentType2LKn3Xvlo4UCmGcweGaCyMCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsBoardOfDirectorCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsTrackCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type AssetLinkingCollectionsContentType1VcIyFnR8Oc2Imaa2Su2W2CollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum AssetOrder {
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A list of products that Peloton sell on the www site. Used for Marketing and
 * shop purposes. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/availableProducts)
 */
export type AvailableProducts = Entry & _Node & {
  __typename?: 'AvailableProducts';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<AvailableProductsLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  product: Maybe<Scalars['String']>;
  availability: Maybe<Scalars['String']>;
};


/**
 * A list of products that Peloton sell on the www site. Used for Marketing and
 * shop purposes. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/availableProducts)
 */
export type AvailableProductsLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A list of products that Peloton sell on the www site. Used for Marketing and
 * shop purposes. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/availableProducts)
 */
export type AvailableProductsKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A list of products that Peloton sell on the www site. Used for Marketing and
 * shop purposes. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/availableProducts)
 */
export type AvailableProductsProductArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A list of products that Peloton sell on the www site. Used for Marketing and
 * shop purposes. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/availableProducts)
 */
export type AvailableProductsAvailabilityArgs = {
  locale: Maybe<Scalars['String']>;
};

export type AvailableProductsCollection = {
  __typename?: 'AvailableProductsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<AvailableProducts>>;
};

export type AvailableProductsFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  product_exists: Maybe<Scalars['Boolean']>;
  product: Maybe<Scalars['String']>;
  product_not: Maybe<Scalars['String']>;
  product_in: Maybe<Array<Maybe<Scalars['String']>>>;
  product_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  product_contains: Maybe<Scalars['String']>;
  product_not_contains: Maybe<Scalars['String']>;
  availability_exists: Maybe<Scalars['Boolean']>;
  availability: Maybe<Scalars['String']>;
  availability_not: Maybe<Scalars['String']>;
  availability_in: Maybe<Array<Maybe<Scalars['String']>>>;
  availability_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  availability_contains: Maybe<Scalars['String']>;
  availability_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<AvailableProductsFilter>>>;
  AND: Maybe<Array<Maybe<AvailableProductsFilter>>>;
};

export type AvailableProductsLinkingCollections = {
  __typename?: 'AvailableProductsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type AvailableProductsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum AvailableProductsOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductAsc = 'product_ASC',
  ProductDesc = 'product_DESC',
  AvailabilityAsc = 'availability_ASC',
  AvailabilityDesc = 'availability_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A simple banner [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/banner) */
export type Banner = Entry & _Node & {
  __typename?: 'Banner';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<BannerLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
};


/** A simple banner [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/banner) */
export type BannerLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A simple banner [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/banner) */
export type BannerKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A simple banner [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/banner) */
export type BannerCtaArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A simple banner [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/banner) */
export type BannerTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type BannerCollection = {
  __typename?: 'BannerCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Banner>>;
};

export type BannerFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  cta_exists: Maybe<Scalars['Boolean']>;
  cta: Maybe<Scalars['String']>;
  cta_not: Maybe<Scalars['String']>;
  cta_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_contains: Maybe<Scalars['String']>;
  cta_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<BannerFilter>>>;
  AND: Maybe<Array<Maybe<BannerFilter>>>;
};

export type BannerLinkingCollections = {
  __typename?: 'BannerLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type BannerLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum BannerOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirector = Entry & _Node & {
  __typename?: 'BoardOfDirector';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<BoardOfDirectorLinkingCollections>;
  _id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  bio: Maybe<Scalars['String']>;
  headshot: Maybe<Asset>;
  sortOrder: Maybe<Scalars['Int']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorBioArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorHeadshotArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/boardOfDirector) */
export type BoardOfDirectorSortOrderArgs = {
  locale: Maybe<Scalars['String']>;
};

export type BoardOfDirectorCollection = {
  __typename?: 'BoardOfDirectorCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BoardOfDirector>>;
};

export type BoardOfDirectorFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  name_exists: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  name_not: Maybe<Scalars['String']>;
  name_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains: Maybe<Scalars['String']>;
  name_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  bio_exists: Maybe<Scalars['Boolean']>;
  bio: Maybe<Scalars['String']>;
  bio_not: Maybe<Scalars['String']>;
  bio_in: Maybe<Array<Maybe<Scalars['String']>>>;
  bio_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  bio_contains: Maybe<Scalars['String']>;
  bio_not_contains: Maybe<Scalars['String']>;
  headshot_exists: Maybe<Scalars['Boolean']>;
  sortOrder_exists: Maybe<Scalars['Boolean']>;
  sortOrder: Maybe<Scalars['Int']>;
  sortOrder_not: Maybe<Scalars['Int']>;
  sortOrder_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_gt: Maybe<Scalars['Int']>;
  sortOrder_gte: Maybe<Scalars['Int']>;
  sortOrder_lt: Maybe<Scalars['Int']>;
  sortOrder_lte: Maybe<Scalars['Int']>;
  OR: Maybe<Array<Maybe<BoardOfDirectorFilter>>>;
  AND: Maybe<Array<Maybe<BoardOfDirectorFilter>>>;
};

export type BoardOfDirectorLinkingCollections = {
  __typename?: 'BoardOfDirectorLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type BoardOfDirectorLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum BoardOfDirectorOrder {
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideo = Entry & _Node & {
  __typename?: 'BreakpointVideo';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<BreakpointVideoLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  mobile: Maybe<Video>;
  tablet: Maybe<Video>;
  desktop: Maybe<Video>;
  desktopLarge: Maybe<Video>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoMobileArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<VideoFilter>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoTabletArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<VideoFilter>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoDesktopArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<VideoFilter>;
};


/** Breakpoint-specific video assets [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/breakpointVideo) */
export type BreakpointVideoDesktopLargeArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<VideoFilter>;
};

export type BreakpointVideoCollection = {
  __typename?: 'BreakpointVideoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<BreakpointVideo>>;
};

export type BreakpointVideoFilter = {
  mobile: Maybe<CfVideoNestedFilter>;
  tablet: Maybe<CfVideoNestedFilter>;
  desktop: Maybe<CfVideoNestedFilter>;
  desktopLarge: Maybe<CfVideoNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  mobile_exists: Maybe<Scalars['Boolean']>;
  tablet_exists: Maybe<Scalars['Boolean']>;
  desktop_exists: Maybe<Scalars['Boolean']>;
  desktopLarge_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<BreakpointVideoFilter>>>;
  AND: Maybe<Array<Maybe<BreakpointVideoFilter>>>;
};

export type BreakpointVideoLinkingCollections = {
  __typename?: 'BreakpointVideoLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  promoHeroCollection: Maybe<PromoHeroCollection>;
  heroCollection: Maybe<HeroCollection>;
  mediaSectionCollection: Maybe<MediaSectionCollection>;
};


export type BreakpointVideoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type BreakpointVideoLinkingCollectionsPromoHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<BreakpointVideoLinkingCollectionsPromoHeroCollectionOrder>>>;
};


export type BreakpointVideoLinkingCollectionsHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<BreakpointVideoLinkingCollectionsHeroCollectionOrder>>>;
};


export type BreakpointVideoLinkingCollectionsMediaSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<BreakpointVideoLinkingCollectionsMediaSectionCollectionOrder>>>;
};

export enum BreakpointVideoLinkingCollectionsHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BreakpointVideoLinkingCollectionsMediaSectionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BreakpointVideoLinkingCollectionsPromoHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum BreakpointVideoOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureForm = Entry & _Node & {
  __typename?: 'CaptureForm';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CaptureFormLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  initialMessaging: Maybe<CaptureFormMessaging>;
  successMessaging: Maybe<CaptureFormMessaging>;
  failureMessaging: Maybe<CaptureFormMessaging>;
};


/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureFormLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureFormKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureFormInitialMessagingArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CaptureFormMessagingFilter>;
};


/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureFormSuccessMessagingArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CaptureFormMessagingFilter>;
};


/**
 * For forms (i.e. lead capture forms) that may have different messaging for
 * initial, success, and failure states. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureForm)
 */
export type CaptureFormFailureMessagingArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CaptureFormMessagingFilter>;
};

export type CaptureFormCollection = {
  __typename?: 'CaptureFormCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CaptureForm>>;
};

export type CaptureFormFilter = {
  initialMessaging: Maybe<CfCaptureFormMessagingNestedFilter>;
  successMessaging: Maybe<CfCaptureFormMessagingNestedFilter>;
  failureMessaging: Maybe<CfCaptureFormMessagingNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  initialMessaging_exists: Maybe<Scalars['Boolean']>;
  successMessaging_exists: Maybe<Scalars['Boolean']>;
  failureMessaging_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CaptureFormFilter>>>;
  AND: Maybe<Array<Maybe<CaptureFormFilter>>>;
};

export type CaptureFormLinkingCollections = {
  __typename?: 'CaptureFormLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type CaptureFormLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessaging = Entry & _Node & {
  __typename?: 'CaptureFormMessaging';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CaptureFormMessagingLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  cta: Maybe<CtaWithUrl>;
  footnote: Maybe<LegalText>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  afterCallToAction: Maybe<Shop>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingCtaArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingFootnoteArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LegalTextFilter>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For initial, success, and failure messaging on the Capture Form content type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/captureFormMessaging) */
export type CaptureFormMessagingAfterCallToActionArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};

export type CaptureFormMessagingCollection = {
  __typename?: 'CaptureFormMessagingCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CaptureFormMessaging>>;
};

export type CaptureFormMessagingFilter = {
  cta: Maybe<CfCtaWithUrlNestedFilter>;
  footnote: Maybe<CfLegalTextNestedFilter>;
  afterCallToAction: Maybe<CfShopNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  cta_exists: Maybe<Scalars['Boolean']>;
  footnote_exists: Maybe<Scalars['Boolean']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  afterCallToAction_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CaptureFormMessagingFilter>>>;
  AND: Maybe<Array<Maybe<CaptureFormMessagingFilter>>>;
};

export type CaptureFormMessagingLinkingCollections = {
  __typename?: 'CaptureFormMessagingLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  captureFormCollection: Maybe<CaptureFormCollection>;
};


export type CaptureFormMessagingLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type CaptureFormMessagingLinkingCollectionsCaptureFormCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CaptureFormMessagingLinkingCollectionsCaptureFormCollectionOrder>>>;
};

export enum CaptureFormMessagingLinkingCollectionsCaptureFormCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CaptureFormMessagingOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CaptureFormOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type CfaggregateMultiTypeNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfaggregateMultiTypeNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfaggregateMultiTypeNestedFilter>>>;
};

export type CfBreakpointVideoNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  mobile_exists: Maybe<Scalars['Boolean']>;
  tablet_exists: Maybe<Scalars['Boolean']>;
  desktop_exists: Maybe<Scalars['Boolean']>;
  desktopLarge_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfBreakpointVideoNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfBreakpointVideoNestedFilter>>>;
};

export type CfCaptureFormMessagingNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  cta_exists: Maybe<Scalars['Boolean']>;
  footnote_exists: Maybe<Scalars['Boolean']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  afterCallToAction_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfCaptureFormMessagingNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfCaptureFormMessagingNestedFilter>>>;
};

export type CfContentAggregateNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  aggregateCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfContentAggregateNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfContentAggregateNestedFilter>>>;
};

export type CfContentType1OJlz6NgUyeeaIk8Uci2OwNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  sortOrder_exists: Maybe<Scalars['Boolean']>;
  sortOrder: Maybe<Scalars['Int']>;
  sortOrder_not: Maybe<Scalars['Int']>;
  sortOrder_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_gt: Maybe<Scalars['Int']>;
  sortOrder_gte: Maybe<Scalars['Int']>;
  sortOrder_lt: Maybe<Scalars['Int']>;
  sortOrder_lte: Maybe<Scalars['Int']>;
  OR: Maybe<Array<Maybe<CfContentType1OJlz6NgUyeeaIk8Uci2OwNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfContentType1OJlz6NgUyeeaIk8Uci2OwNestedFilter>>>;
};

export type CfctasMultiTypeNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfctasMultiTypeNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfctasMultiTypeNestedFilter>>>;
};

export type CfCtaWithUrlNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  link_exists: Maybe<Scalars['Boolean']>;
  targetBlank_exists: Maybe<Scalars['Boolean']>;
  targetBlank: Maybe<Scalars['Boolean']>;
  targetBlank_not: Maybe<Scalars['Boolean']>;
  ariaLabel_exists: Maybe<Scalars['Boolean']>;
  ariaLabel: Maybe<Scalars['String']>;
  ariaLabel_not: Maybe<Scalars['String']>;
  ariaLabel_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ariaLabel_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ariaLabel_contains: Maybe<Scalars['String']>;
  ariaLabel_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfCtaWithUrlNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfCtaWithUrlNestedFilter>>>;
};

export type CfCtaWithUrlSetNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  ctasCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfCtaWithUrlSetNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfCtaWithUrlSetNestedFilter>>>;
};

export type CfFaqNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  pairsCollection_exists: Maybe<Scalars['Boolean']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfFaqNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfFaqNestedFilter>>>;
};

export type CfFormsNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfFormsNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfFormsNestedFilter>>>;
};

export type CfHeaderBodyImageNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  theme_exists: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  theme_not: Maybe<Scalars['String']>;
  theme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_contains: Maybe<Scalars['String']>;
  theme_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfHeaderBodyImageNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfHeaderBodyImageNestedFilter>>>;
};

export type CfHeaderBodyImageWithLinkNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  link_exists: Maybe<Scalars['Boolean']>;
  link: Maybe<Scalars['String']>;
  link_not: Maybe<Scalars['String']>;
  link_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains: Maybe<Scalars['String']>;
  link_not_contains: Maybe<Scalars['String']>;
  linkRef_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfHeaderBodyImageWithLinkNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfHeaderBodyImageWithLinkNestedFilter>>>;
};

export type CfImageNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  mobile_exists: Maybe<Scalars['Boolean']>;
  tablet_exists: Maybe<Scalars['Boolean']>;
  desktop_exists: Maybe<Scalars['Boolean']>;
  desktopLarge_exists: Maybe<Scalars['Boolean']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfImageNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfImageNestedFilter>>>;
};

export type CfimagesMultiTypeNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfimagesMultiTypeNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfimagesMultiTypeNestedFilter>>>;
};

export type CfKeyValueNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfKeyValueNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfKeyValueNestedFilter>>>;
};

export type CfLegalTextNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfLegalTextNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfLegalTextNestedFilter>>>;
};

export type CflinksMultiTypeNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CflinksMultiTypeNestedFilter>>>;
  AND: Maybe<Array<Maybe<CflinksMultiTypeNestedFilter>>>;
};

export type CfLongHeaderBodyFootnoteNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  footnote_exists: Maybe<Scalars['Boolean']>;
  footnote: Maybe<Scalars['String']>;
  footnote_not: Maybe<Scalars['String']>;
  footnote_in: Maybe<Array<Maybe<Scalars['String']>>>;
  footnote_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  footnote_contains: Maybe<Scalars['String']>;
  footnote_not_contains: Maybe<Scalars['String']>;
  tooltip_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfLongHeaderBodyFootnoteNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfLongHeaderBodyFootnoteNestedFilter>>>;
};

export type CfMarkdownPageNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  overflow_exists: Maybe<Scalars['Boolean']>;
  overflow: Maybe<Scalars['String']>;
  overflow_not: Maybe<Scalars['String']>;
  overflow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  overflow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  overflow_contains: Maybe<Scalars['String']>;
  overflow_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfMarkdownPageNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfMarkdownPageNestedFilter>>>;
};

export type CfPressQuoteNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  logo_exists: Maybe<Scalars['Boolean']>;
  quote_exists: Maybe<Scalars['Boolean']>;
  quote: Maybe<Scalars['String']>;
  quote_not: Maybe<Scalars['String']>;
  quote_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_contains: Maybe<Scalars['String']>;
  quote_not_contains: Maybe<Scalars['String']>;
  publication_exists: Maybe<Scalars['Boolean']>;
  publication: Maybe<Scalars['String']>;
  publication_not: Maybe<Scalars['String']>;
  publication_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_contains: Maybe<Scalars['String']>;
  publication_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfPressQuoteNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfPressQuoteNestedFilter>>>;
};

export type CfProductBlockNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  subhead_exists: Maybe<Scalars['Boolean']>;
  subhead: Maybe<Scalars['String']>;
  subhead_not: Maybe<Scalars['String']>;
  subhead_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_contains: Maybe<Scalars['String']>;
  subhead_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  ctaWithUrl_exists: Maybe<Scalars['Boolean']>;
  featuredProduct_exists: Maybe<Scalars['Boolean']>;
  featuredProduct: Maybe<Scalars['String']>;
  featuredProduct_not: Maybe<Scalars['String']>;
  featuredProduct_in: Maybe<Array<Maybe<Scalars['String']>>>;
  featuredProduct_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  featuredProduct_contains: Maybe<Scalars['String']>;
  featuredProduct_not_contains: Maybe<Scalars['String']>;
  financingStyle_exists: Maybe<Scalars['Boolean']>;
  financingStyle: Maybe<Scalars['String']>;
  financingStyle_not: Maybe<Scalars['String']>;
  financingStyle_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStyle_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStyle_contains: Maybe<Scalars['String']>;
  financingStyle_not_contains: Maybe<Scalars['String']>;
  subCtaText_exists: Maybe<Scalars['Boolean']>;
  subCtaText: Maybe<Scalars['String']>;
  subCtaText_not: Maybe<Scalars['String']>;
  subCtaText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subCtaText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subCtaText_contains: Maybe<Scalars['String']>;
  subCtaText_not_contains: Maybe<Scalars['String']>;
  textBackground_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfProductBlockNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfProductBlockNestedFilter>>>;
};

export type CfProductBlockSetNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  productBlocksCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfProductBlockSetNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfProductBlockSetNestedFilter>>>;
};

export type CfProductDetailsCardNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  productName_exists: Maybe<Scalars['Boolean']>;
  productName: Maybe<Scalars['String']>;
  productName_not: Maybe<Scalars['String']>;
  productName_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productName_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productName_contains: Maybe<Scalars['String']>;
  productName_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  device_exists: Maybe<Scalars['Boolean']>;
  device: Maybe<Scalars['String']>;
  device_not: Maybe<Scalars['String']>;
  device_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_contains: Maybe<Scalars['String']>;
  device_not_contains: Maybe<Scalars['String']>;
  featureLists_exists: Maybe<Scalars['Boolean']>;
  primaryCta_exists: Maybe<Scalars['Boolean']>;
  secondaryCta_exists: Maybe<Scalars['Boolean']>;
  ribbonText_exists: Maybe<Scalars['Boolean']>;
  ribbonText: Maybe<Scalars['String']>;
  ribbonText_not: Maybe<Scalars['String']>;
  ribbonText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ribbonText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ribbonText_contains: Maybe<Scalars['String']>;
  ribbonText_not_contains: Maybe<Scalars['String']>;
  promoBadgeText_exists: Maybe<Scalars['Boolean']>;
  promoBadgeText: Maybe<Scalars['String']>;
  promoBadgeText_not: Maybe<Scalars['String']>;
  promoBadgeText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_contains: Maybe<Scalars['String']>;
  promoBadgeText_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfProductDetailsCardNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfProductDetailsCardNestedFilter>>>;
};

export type CfProductNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  slug_exists: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  slug_not: Maybe<Scalars['String']>;
  slug_in: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains: Maybe<Scalars['String']>;
  slug_not_contains: Maybe<Scalars['String']>;
  name_exists: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  name_not: Maybe<Scalars['String']>;
  name_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains: Maybe<Scalars['String']>;
  name_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  imageCollection_exists: Maybe<Scalars['Boolean']>;
  badge_exists: Maybe<Scalars['Boolean']>;
  badge: Maybe<Scalars['String']>;
  badge_not: Maybe<Scalars['String']>;
  badge_in: Maybe<Array<Maybe<Scalars['String']>>>;
  badge_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  badge_contains: Maybe<Scalars['String']>;
  badge_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfProductNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfProductNestedFilter>>>;
};

export type CfProductRecommendationsTileNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  productSlug_exists: Maybe<Scalars['Boolean']>;
  productSlug: Maybe<Scalars['String']>;
  productSlug_not: Maybe<Scalars['String']>;
  productSlug_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productSlug_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productSlug_contains: Maybe<Scalars['String']>;
  productSlug_not_contains: Maybe<Scalars['String']>;
  factoid_exists: Maybe<Scalars['Boolean']>;
  factoid: Maybe<Scalars['String']>;
  factoid_not: Maybe<Scalars['String']>;
  factoid_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoid_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoid_contains: Maybe<Scalars['String']>;
  factoid_not_contains: Maybe<Scalars['String']>;
  pill_exists: Maybe<Scalars['Boolean']>;
  pill: Maybe<Scalars['String']>;
  pill_not: Maybe<Scalars['String']>;
  pill_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pill_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pill_contains: Maybe<Scalars['String']>;
  pill_not_contains: Maybe<Scalars['String']>;
  factoidIcon_exists: Maybe<Scalars['Boolean']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  pillTheme_exists: Maybe<Scalars['Boolean']>;
  pillTheme: Maybe<Scalars['String']>;
  pillTheme_not: Maybe<Scalars['String']>;
  pillTheme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pillTheme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pillTheme_contains: Maybe<Scalars['String']>;
  pillTheme_not_contains: Maybe<Scalars['String']>;
  priceDisplay_exists: Maybe<Scalars['Boolean']>;
  priceDisplay: Maybe<Scalars['String']>;
  priceDisplay_not: Maybe<Scalars['String']>;
  priceDisplay_in: Maybe<Array<Maybe<Scalars['String']>>>;
  priceDisplay_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  priceDisplay_contains: Maybe<Scalars['String']>;
  priceDisplay_not_contains: Maybe<Scalars['String']>;
  factoidEyebrow_exists: Maybe<Scalars['Boolean']>;
  factoidEyebrow: Maybe<Scalars['String']>;
  factoidEyebrow_not: Maybe<Scalars['String']>;
  factoidEyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoidEyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoidEyebrow_contains: Maybe<Scalars['String']>;
  factoidEyebrow_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfProductRecommendationsTileNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfProductRecommendationsTileNestedFilter>>>;
};

export type CfPromoBannerNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  theme_exists: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  theme_not: Maybe<Scalars['String']>;
  theme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_contains: Maybe<Scalars['String']>;
  theme_not_contains: Maybe<Scalars['String']>;
  linksCollection_exists: Maybe<Scalars['Boolean']>;
  hide_exists: Maybe<Scalars['Boolean']>;
  hide: Maybe<Scalars['Boolean']>;
  hide_not: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfPromoBannerNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfPromoBannerNestedFilter>>>;
};

export type CfPromoHeroNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  subhead_exists: Maybe<Scalars['Boolean']>;
  subhead: Maybe<Scalars['String']>;
  subhead_not: Maybe<Scalars['String']>;
  subhead_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_contains: Maybe<Scalars['String']>;
  subhead_not_contains: Maybe<Scalars['String']>;
  ctasCollection_exists: Maybe<Scalars['Boolean']>;
  support_exists: Maybe<Scalars['Boolean']>;
  support: Maybe<Scalars['String']>;
  support_not: Maybe<Scalars['String']>;
  support_in: Maybe<Array<Maybe<Scalars['String']>>>;
  support_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  support_contains: Maybe<Scalars['String']>;
  support_not_contains: Maybe<Scalars['String']>;
  backgroundMedia_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfPromoHeroNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfPromoHeroNestedFilter>>>;
};

export type CfPromoHeroSetNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  heroItemsCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfPromoHeroSetNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfPromoHeroSetNestedFilter>>>;
};

export type CfPromotionalTextNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfPromotionalTextNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfPromotionalTextNestedFilter>>>;
};

export type CfQAndANestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  question_exists: Maybe<Scalars['Boolean']>;
  question: Maybe<Scalars['String']>;
  question_not: Maybe<Scalars['String']>;
  question_in: Maybe<Array<Maybe<Scalars['String']>>>;
  question_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  question_contains: Maybe<Scalars['String']>;
  question_not_contains: Maybe<Scalars['String']>;
  answer_exists: Maybe<Scalars['Boolean']>;
  answer: Maybe<Scalars['String']>;
  answer_not: Maybe<Scalars['String']>;
  answer_in: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_contains: Maybe<Scalars['String']>;
  answer_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfQAndANestedFilter>>>;
  AND: Maybe<Array<Maybe<CfQAndANestedFilter>>>;
};

export type CfSectionNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfSectionNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfSectionNestedFilter>>>;
};

export type CfShopNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfShopNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfShopNestedFilter>>>;
};

export type CfsubnavsMultiTypeNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfsubnavsMultiTypeNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfsubnavsMultiTypeNestedFilter>>>;
};

export type CfSvgIconNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  iconComponent_exists: Maybe<Scalars['Boolean']>;
  iconComponent: Maybe<Scalars['String']>;
  iconComponent_not: Maybe<Scalars['String']>;
  iconComponent_in: Maybe<Array<Maybe<Scalars['String']>>>;
  iconComponent_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  iconComponent_contains: Maybe<Scalars['String']>;
  iconComponent_not_contains: Maybe<Scalars['String']>;
  fill_exists: Maybe<Scalars['Boolean']>;
  fill: Maybe<Scalars['String']>;
  fill_not: Maybe<Scalars['String']>;
  fill_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fill_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fill_contains: Maybe<Scalars['String']>;
  fill_not_contains: Maybe<Scalars['String']>;
  tooltip_exists: Maybe<Scalars['Boolean']>;
  tooltip: Maybe<Scalars['String']>;
  tooltip_not: Maybe<Scalars['String']>;
  tooltip_in: Maybe<Array<Maybe<Scalars['String']>>>;
  tooltip_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  tooltip_contains: Maybe<Scalars['String']>;
  tooltip_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfSvgIconNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfSvgIconNestedFilter>>>;
};

export type CfTrackNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  kind_exists: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  kind_not: Maybe<Scalars['String']>;
  kind_in: Maybe<Array<Maybe<Scalars['String']>>>;
  kind_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  kind_contains: Maybe<Scalars['String']>;
  kind_not_contains: Maybe<Scalars['String']>;
  src_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfTrackNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfTrackNestedFilter>>>;
};

export type CfValuePropNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  icon_exists: Maybe<Scalars['Boolean']>;
  svgIcon_exists: Maybe<Scalars['Boolean']>;
  onClick_exists: Maybe<Scalars['Boolean']>;
  onClick: Maybe<Scalars['String']>;
  onClick_not: Maybe<Scalars['String']>;
  onClick_in: Maybe<Array<Maybe<Scalars['String']>>>;
  onClick_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  onClick_contains: Maybe<Scalars['String']>;
  onClick_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfValuePropNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfValuePropNestedFilter>>>;
};

export type CfValuePropSetNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  valuePropItemCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CfValuePropSetNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfValuePropSetNestedFilter>>>;
};

export type CfVideoNestedFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  poster_exists: Maybe<Scalars['Boolean']>;
  video_exists: Maybe<Scalars['Boolean']>;
  tracksCollection_exists: Maybe<Scalars['Boolean']>;
  loop_exists: Maybe<Scalars['Boolean']>;
  loop: Maybe<Scalars['Boolean']>;
  loop_not: Maybe<Scalars['Boolean']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CfVideoNestedFilter>>>;
  AND: Maybe<Array<Maybe<CfVideoNestedFilter>>>;
};

/** Text on any of the company pages: /company, /team, /contact [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/companyPageText) */
export type CompanyPageText = Entry & _Node & {
  __typename?: 'CompanyPageText';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CompanyPageTextLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};


/** Text on any of the company pages: /company, /team, /contact [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/companyPageText) */
export type CompanyPageTextLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Text on any of the company pages: /company, /team, /contact [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/companyPageText) */
export type CompanyPageTextKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Text on any of the company pages: /company, /team, /contact [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/companyPageText) */
export type CompanyPageTextValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type CompanyPageTextCollection = {
  __typename?: 'CompanyPageTextCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CompanyPageText>>;
};

export type CompanyPageTextFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CompanyPageTextFilter>>>;
  AND: Maybe<Array<Maybe<CompanyPageTextFilter>>>;
};

export type CompanyPageTextLinkingCollections = {
  __typename?: 'CompanyPageTextLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type CompanyPageTextLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum CompanyPageTextOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGen = Entry & _Node & {
  __typename?: 'ComponentLeadGen';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ComponentLeadGenLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  campaignName: Maybe<Scalars['String']>;
  campaignId: Maybe<Scalars['String']>;
  theme: Maybe<Scalars['String']>;
  headlineText: Maybe<Scalars['String']>;
  placeholder: Maybe<Scalars['String']>;
  supportText: Maybe<Scalars['String']>;
  ctaText: Maybe<Scalars['String']>;
  emailCaptureSuccess: Maybe<Scalars['String']>;
  emailCaptureFailure: Maybe<Scalars['String']>;
  bodyText: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenCampaignNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenCampaignIdArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenThemeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenHeadlineTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenPlaceholderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenSupportTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenCtaTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenEmailCaptureSuccessArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenEmailCaptureFailureArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/componentLeadGen) */
export type ComponentLeadGenBodyTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ComponentLeadGenCollection = {
  __typename?: 'ComponentLeadGenCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ComponentLeadGen>>;
};

export type ComponentLeadGenFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  campaignName_exists: Maybe<Scalars['Boolean']>;
  campaignName: Maybe<Scalars['String']>;
  campaignName_not: Maybe<Scalars['String']>;
  campaignName_in: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignName_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignName_contains: Maybe<Scalars['String']>;
  campaignName_not_contains: Maybe<Scalars['String']>;
  campaignId_exists: Maybe<Scalars['Boolean']>;
  campaignId: Maybe<Scalars['String']>;
  campaignId_not: Maybe<Scalars['String']>;
  campaignId_in: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignId_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  campaignId_contains: Maybe<Scalars['String']>;
  campaignId_not_contains: Maybe<Scalars['String']>;
  theme_exists: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  theme_not: Maybe<Scalars['String']>;
  theme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_contains: Maybe<Scalars['String']>;
  theme_not_contains: Maybe<Scalars['String']>;
  headlineText_exists: Maybe<Scalars['Boolean']>;
  headlineText: Maybe<Scalars['String']>;
  headlineText_not: Maybe<Scalars['String']>;
  headlineText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headlineText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headlineText_contains: Maybe<Scalars['String']>;
  headlineText_not_contains: Maybe<Scalars['String']>;
  placeholder_exists: Maybe<Scalars['Boolean']>;
  placeholder: Maybe<Scalars['String']>;
  placeholder_not: Maybe<Scalars['String']>;
  placeholder_in: Maybe<Array<Maybe<Scalars['String']>>>;
  placeholder_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  placeholder_contains: Maybe<Scalars['String']>;
  placeholder_not_contains: Maybe<Scalars['String']>;
  supportText_exists: Maybe<Scalars['Boolean']>;
  supportText: Maybe<Scalars['String']>;
  supportText_not: Maybe<Scalars['String']>;
  supportText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  supportText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  supportText_contains: Maybe<Scalars['String']>;
  supportText_not_contains: Maybe<Scalars['String']>;
  ctaText_exists: Maybe<Scalars['Boolean']>;
  ctaText: Maybe<Scalars['String']>;
  ctaText_not: Maybe<Scalars['String']>;
  ctaText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ctaText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ctaText_contains: Maybe<Scalars['String']>;
  ctaText_not_contains: Maybe<Scalars['String']>;
  emailCaptureSuccess_exists: Maybe<Scalars['Boolean']>;
  emailCaptureSuccess: Maybe<Scalars['String']>;
  emailCaptureSuccess_not: Maybe<Scalars['String']>;
  emailCaptureSuccess_in: Maybe<Array<Maybe<Scalars['String']>>>;
  emailCaptureSuccess_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  emailCaptureSuccess_contains: Maybe<Scalars['String']>;
  emailCaptureSuccess_not_contains: Maybe<Scalars['String']>;
  emailCaptureFailure_exists: Maybe<Scalars['Boolean']>;
  emailCaptureFailure: Maybe<Scalars['String']>;
  emailCaptureFailure_not: Maybe<Scalars['String']>;
  emailCaptureFailure_in: Maybe<Array<Maybe<Scalars['String']>>>;
  emailCaptureFailure_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  emailCaptureFailure_contains: Maybe<Scalars['String']>;
  emailCaptureFailure_not_contains: Maybe<Scalars['String']>;
  bodyText_exists: Maybe<Scalars['Boolean']>;
  bodyText: Maybe<Scalars['String']>;
  bodyText_not: Maybe<Scalars['String']>;
  bodyText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  bodyText_contains: Maybe<Scalars['String']>;
  bodyText_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ComponentLeadGenFilter>>>;
  AND: Maybe<Array<Maybe<ComponentLeadGenFilter>>>;
};

export type ComponentLeadGenLinkingCollections = {
  __typename?: 'ComponentLeadGenLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type ComponentLeadGenLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ComponentLeadGenLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ComponentLeadGenLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum ComponentLeadGenLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ComponentLeadGenOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  CampaignNameAsc = 'campaignName_ASC',
  CampaignNameDesc = 'campaignName_DESC',
  CampaignIdAsc = 'campaignId_ASC',
  CampaignIdDesc = 'campaignId_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  HeadlineTextAsc = 'headlineText_ASC',
  HeadlineTextDesc = 'headlineText_DESC',
  PlaceholderAsc = 'placeholder_ASC',
  PlaceholderDesc = 'placeholder_DESC',
  CtaTextAsc = 'ctaText_ASC',
  CtaTextDesc = 'ctaText_DESC',
  EmailCaptureSuccessAsc = 'emailCaptureSuccess_ASC',
  EmailCaptureSuccessDesc = 'emailCaptureSuccess_DESC',
  EmailCaptureFailureAsc = 'emailCaptureFailure_ASC',
  EmailCaptureFailureDesc = 'emailCaptureFailure_DESC',
  BodyTextAsc = 'bodyText_ASC',
  BodyTextDesc = 'bodyText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A key referencing an ordered list of other contentful objects. Jupiter will not
 * know the type of the referenced object, but this helps us guarantee order and
 * correctness of the keys. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentAggregate)
 */
export type ContentAggregate = Entry & _Node & {
  __typename?: 'ContentAggregate';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentAggregateLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  aggregateCollection: Maybe<ContentAggregateAggregateCollection>;
};


/**
 * A key referencing an ordered list of other contentful objects. Jupiter will not
 * know the type of the referenced object, but this helps us guarantee order and
 * correctness of the keys. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentAggregate)
 */
export type ContentAggregateLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A key referencing an ordered list of other contentful objects. Jupiter will not
 * know the type of the referenced object, but this helps us guarantee order and
 * correctness of the keys. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentAggregate)
 */
export type ContentAggregateKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A key referencing an ordered list of other contentful objects. Jupiter will not
 * know the type of the referenced object, but this helps us guarantee order and
 * correctness of the keys. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentAggregate)
 */
export type ContentAggregateAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateAggregateFilter>;
};

export type ContentAggregateAggregateCollection = {
  __typename?: 'ContentAggregateAggregateCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentAggregateAggregateItem>>;
};

export type ContentAggregateAggregateFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ContentAggregateAggregateFilter>>>;
  AND: Maybe<Array<Maybe<ContentAggregateAggregateFilter>>>;
};

export type ContentAggregateAggregateItem = ComponentLeadGen | ContentAggregate | CtaWithUrl | CtaWithUrlSet | HeaderBodyImage | HeaderBodyImageWithLink | Image | JsonKeyValue | KeyValue | LegalText | LongHeaderBodyFootnote | Navigation | ProductBlock | ProductBlockSet | ProductRecommendationsCohort | PromotionalText | Shop | SvgIcon | Video;

export type ContentAggregateCollection = {
  __typename?: 'ContentAggregateCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentAggregate>>;
};

export type ContentAggregateFilter = {
  aggregate: Maybe<CfaggregateMultiTypeNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  aggregateCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ContentAggregateFilter>>>;
  AND: Maybe<Array<Maybe<ContentAggregateFilter>>>;
};

export type ContentAggregateLinkingCollections = {
  __typename?: 'ContentAggregateLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  navigationCollection: Maybe<NavigationCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  promoCollection: Maybe<PromoCollection>;
};


export type ContentAggregateLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ContentAggregateLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ContentAggregateLinkingCollectionsNavigationCollectionOrder>>>;
};


export type ContentAggregateLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ContentAggregateLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type ContentAggregateLinkingCollectionsPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ContentAggregateLinkingCollectionsPromoCollectionOrder>>>;
};

export enum ContentAggregateLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ContentAggregateLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ContentAggregateLinkingCollectionsPromoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ContentAggregateOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfu = Entry & _Node & {
  __typename?: 'ContentByCfu';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentByCfuLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  bike: Maybe<Shop>;
  tread: Maybe<Shop>;
  bikePlus: Maybe<Shop>;
  treadPlus: Maybe<Shop>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuBikeArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuTreadArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuBikePlusArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};


/** A content model for collecting info for CFUs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/contentByCfu) */
export type ContentByCfuTreadPlusArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};

export type ContentByCfuCollection = {
  __typename?: 'ContentByCfuCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentByCfu>>;
};

export type ContentByCfuFilter = {
  bike: Maybe<CfShopNestedFilter>;
  tread: Maybe<CfShopNestedFilter>;
  bikePlus: Maybe<CfShopNestedFilter>;
  treadPlus: Maybe<CfShopNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  bike_exists: Maybe<Scalars['Boolean']>;
  tread_exists: Maybe<Scalars['Boolean']>;
  bikePlus_exists: Maybe<Scalars['Boolean']>;
  treadPlus_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ContentByCfuFilter>>>;
  AND: Maybe<Array<Maybe<ContentByCfuFilter>>>;
};

export type ContentByCfuLinkingCollections = {
  __typename?: 'ContentByCfuLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ContentByCfuLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ContentByCfuOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
  concepts: Array<Maybe<TaxonomyConcept>>;
};

export type ContentfulMetadataConceptsDescendantsFilter = {
  id_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentfulMetadataConceptsFilter = {
  id_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  descendants: Maybe<ContentfulMetadataConceptsDescendantsFilter>;
};

export type ContentfulMetadataFilter = {
  tags_exists: Maybe<Scalars['Boolean']>;
  tags: Maybe<ContentfulMetadataTagsFilter>;
  concepts_exists: Maybe<Scalars['Boolean']>;
  concepts: Maybe<ContentfulMetadataConceptsFilter>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *       Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

/** Employee departments [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1OJlz6ngUYEEAIk8uci2OW) */
export type ContentType1OJlz6NgUyeeaIk8Uci2Ow = Entry & _Node & {
  __typename?: 'ContentType1OJlz6NgUyeeaIk8Uci2Ow';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollections>;
  _id: Scalars['ID'];
  title: Maybe<Scalars['String']>;
  sortOrder: Maybe<Scalars['Int']>;
};


/** Employee departments [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1OJlz6ngUYEEAIk8uci2OW) */
export type ContentType1OJlz6NgUyeeaIk8Uci2OwLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Employee departments [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1OJlz6ngUYEEAIk8uci2OW) */
export type ContentType1OJlz6NgUyeeaIk8Uci2OwTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Employee departments [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1OJlz6ngUYEEAIk8uci2OW) */
export type ContentType1OJlz6NgUyeeaIk8Uci2OwSortOrderArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ContentType1OJlz6NgUyeeaIk8Uci2OwCollection = {
  __typename?: 'ContentType1OJlz6NgUyeeaIk8Uci2OwCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentType1OJlz6NgUyeeaIk8Uci2Ow>>;
};

export type ContentType1OJlz6NgUyeeaIk8Uci2OwFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  sortOrder_exists: Maybe<Scalars['Boolean']>;
  sortOrder: Maybe<Scalars['Int']>;
  sortOrder_not: Maybe<Scalars['Int']>;
  sortOrder_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_gt: Maybe<Scalars['Int']>;
  sortOrder_gte: Maybe<Scalars['Int']>;
  sortOrder_lt: Maybe<Scalars['Int']>;
  sortOrder_lte: Maybe<Scalars['Int']>;
  OR: Maybe<Array<Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwFilter>>>;
  AND: Maybe<Array<Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwFilter>>>;
};

export type ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollections = {
  __typename?: 'ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentType2LKn3Xvlo4UCmGcweGaCyMCollection: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMCollection>;
};


export type ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollectionsContentType2LKn3Xvlo4UCmGcweGaCyMCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollectionsContentType2LKn3Xvlo4UCmGcweGaCyMCollectionOrder>>>;
};

export enum ContentType1OJlz6NgUyeeaIk8Uci2OwLinkingCollectionsContentType2LKn3Xvlo4UCmGcweGaCyMCollectionOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  IsLeadershipAsc = 'isLeadership_ASC',
  IsLeadershipDesc = 'isLeadership_DESC',
  DepartmentDeprecatedAsc = 'department_deprecated_ASC',
  DepartmentDeprecatedDesc = 'department_deprecated_DESC',
  PelotonUsernameAsc = 'pelotonUsername_ASC',
  PelotonUsernameDesc = 'pelotonUsername_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  IsBoardMemberAsc = 'isBoardMember_ASC',
  IsBoardMemberDesc = 'isBoardMember_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ContentType1OJlz6NgUyeeaIk8Uci2OwOrder {
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2 = Entry & _Node & {
  __typename?: 'ContentType1VcIyFnR8OC2Imaa2Su2W2';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2LinkingCollections>;
  _id: Scalars['ID'];
  publication: Maybe<Scalars['String']>;
  date: Maybe<Scalars['DateTime']>;
  link: Maybe<Scalars['String']>;
  logo: Maybe<Asset>;
  quote: Maybe<Scalars['String']>;
  headerImage: Maybe<Asset>;
  countries: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2LinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2PublicationArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2DateArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2LinkArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2LogoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2QuoteArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2HeaderImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/1VcIyFnR8oC2IMAA2SU2W2) */
export type ContentType1VcIyFnR8Oc2Imaa2Su2W2CountriesArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ContentType1VcIyFnR8Oc2Imaa2Su2W2Collection = {
  __typename?: 'ContentType1VcIyFnR8OC2Imaa2Su2W2Collection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2>>;
};

export type ContentType1VcIyFnR8Oc2Imaa2Su2W2Filter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  publication_exists: Maybe<Scalars['Boolean']>;
  publication: Maybe<Scalars['String']>;
  publication_not: Maybe<Scalars['String']>;
  publication_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_contains: Maybe<Scalars['String']>;
  publication_not_contains: Maybe<Scalars['String']>;
  date_exists: Maybe<Scalars['Boolean']>;
  date: Maybe<Scalars['DateTime']>;
  date_not: Maybe<Scalars['DateTime']>;
  date_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_gt: Maybe<Scalars['DateTime']>;
  date_gte: Maybe<Scalars['DateTime']>;
  date_lt: Maybe<Scalars['DateTime']>;
  date_lte: Maybe<Scalars['DateTime']>;
  link_exists: Maybe<Scalars['Boolean']>;
  link: Maybe<Scalars['String']>;
  link_not: Maybe<Scalars['String']>;
  link_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains: Maybe<Scalars['String']>;
  link_not_contains: Maybe<Scalars['String']>;
  logo_exists: Maybe<Scalars['Boolean']>;
  quote_exists: Maybe<Scalars['Boolean']>;
  quote: Maybe<Scalars['String']>;
  quote_not: Maybe<Scalars['String']>;
  quote_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_contains: Maybe<Scalars['String']>;
  quote_not_contains: Maybe<Scalars['String']>;
  headerImage_exists: Maybe<Scalars['Boolean']>;
  countries_exists: Maybe<Scalars['Boolean']>;
  countries_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  countries_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  countries_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  OR: Maybe<Array<Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Filter>>>;
  AND: Maybe<Array<Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Filter>>>;
};

export type ContentType1VcIyFnR8Oc2Imaa2Su2W2LinkingCollections = {
  __typename?: 'ContentType1VcIyFnR8OC2Imaa2Su2W2LinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ContentType1VcIyFnR8Oc2Imaa2Su2W2LinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ContentType1VcIyFnR8Oc2Imaa2Su2W2Order {
  PublicationAsc = 'publication_ASC',
  PublicationDesc = 'publication_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyM = Entry & _Node & {
  __typename?: 'ContentType2LKn3Xvlo4UCmGcweGaCyM';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMLinkingCollections>;
  _id: Scalars['ID'];
  fullName: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
  isLeadership: Maybe<Scalars['Boolean']>;
  department: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2Ow>;
  departmentDeprecated: Maybe<Scalars['String']>;
  pelotonUsername: Maybe<Scalars['String']>;
  linkedInUrl: Maybe<Scalars['String']>;
  headshot: Maybe<Asset>;
  transparentHeadshot: Maybe<Asset>;
  biography: Maybe<Scalars['String']>;
  sortOrder: Maybe<Scalars['Int']>;
  isBoardMember: Maybe<Scalars['Boolean']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMFullNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMSubtitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMIsLeadershipArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMDepartmentArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwFilter>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMDepartmentDeprecatedArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMPelotonUsernameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMLinkedInUrlArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMHeadshotArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMTransparentHeadshotArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMBiographyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMSortOrderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Details about a Peloton Employee [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/2LKn3xvlo4UCmGCWEGaCyM) */
export type ContentType2LKn3Xvlo4UCmGcweGaCyMIsBoardMemberArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ContentType2LKn3Xvlo4UCmGcweGaCyMCollection = {
  __typename?: 'ContentType2LKn3Xvlo4UCmGcweGaCyMCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyM>>;
};

export type ContentType2LKn3Xvlo4UCmGcweGaCyMFilter = {
  department: Maybe<CfContentType1OJlz6NgUyeeaIk8Uci2OwNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  fullName_exists: Maybe<Scalars['Boolean']>;
  fullName: Maybe<Scalars['String']>;
  fullName_not: Maybe<Scalars['String']>;
  fullName_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fullName_contains: Maybe<Scalars['String']>;
  fullName_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  subtitle_exists: Maybe<Scalars['Boolean']>;
  subtitle: Maybe<Scalars['String']>;
  subtitle_not: Maybe<Scalars['String']>;
  subtitle_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_contains: Maybe<Scalars['String']>;
  subtitle_not_contains: Maybe<Scalars['String']>;
  isLeadership_exists: Maybe<Scalars['Boolean']>;
  isLeadership: Maybe<Scalars['Boolean']>;
  isLeadership_not: Maybe<Scalars['Boolean']>;
  department_exists: Maybe<Scalars['Boolean']>;
  department_deprecated_exists: Maybe<Scalars['Boolean']>;
  department_deprecated: Maybe<Scalars['String']>;
  department_deprecated_not: Maybe<Scalars['String']>;
  department_deprecated_in: Maybe<Array<Maybe<Scalars['String']>>>;
  department_deprecated_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  department_deprecated_contains: Maybe<Scalars['String']>;
  department_deprecated_not_contains: Maybe<Scalars['String']>;
  pelotonUsername_exists: Maybe<Scalars['Boolean']>;
  pelotonUsername: Maybe<Scalars['String']>;
  pelotonUsername_not: Maybe<Scalars['String']>;
  pelotonUsername_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pelotonUsername_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pelotonUsername_contains: Maybe<Scalars['String']>;
  pelotonUsername_not_contains: Maybe<Scalars['String']>;
  linkedInUrl_exists: Maybe<Scalars['Boolean']>;
  linkedInUrl: Maybe<Scalars['String']>;
  linkedInUrl_not: Maybe<Scalars['String']>;
  linkedInUrl_in: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedInUrl_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  linkedInUrl_contains: Maybe<Scalars['String']>;
  linkedInUrl_not_contains: Maybe<Scalars['String']>;
  headshot_exists: Maybe<Scalars['Boolean']>;
  transparentHeadshot_exists: Maybe<Scalars['Boolean']>;
  biography_exists: Maybe<Scalars['Boolean']>;
  biography: Maybe<Scalars['String']>;
  biography_not: Maybe<Scalars['String']>;
  biography_in: Maybe<Array<Maybe<Scalars['String']>>>;
  biography_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  biography_contains: Maybe<Scalars['String']>;
  biography_not_contains: Maybe<Scalars['String']>;
  sortOrder_exists: Maybe<Scalars['Boolean']>;
  sortOrder: Maybe<Scalars['Int']>;
  sortOrder_not: Maybe<Scalars['Int']>;
  sortOrder_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  sortOrder_gt: Maybe<Scalars['Int']>;
  sortOrder_gte: Maybe<Scalars['Int']>;
  sortOrder_lt: Maybe<Scalars['Int']>;
  sortOrder_lte: Maybe<Scalars['Int']>;
  isBoardMember_exists: Maybe<Scalars['Boolean']>;
  isBoardMember: Maybe<Scalars['Boolean']>;
  isBoardMember_not: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMFilter>>>;
  AND: Maybe<Array<Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMFilter>>>;
};

export type ContentType2LKn3Xvlo4UCmGcweGaCyMLinkingCollections = {
  __typename?: 'ContentType2LKn3Xvlo4UCmGcweGaCyMLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ContentType2LKn3Xvlo4UCmGcweGaCyMLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ContentType2LKn3Xvlo4UCmGcweGaCyMOrder {
  FullNameAsc = 'fullName_ASC',
  FullNameDesc = 'fullName_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  IsLeadershipAsc = 'isLeadership_ASC',
  IsLeadershipDesc = 'isLeadership_DESC',
  DepartmentDeprecatedAsc = 'department_deprecated_ASC',
  DepartmentDeprecatedDesc = 'department_deprecated_DESC',
  PelotonUsernameAsc = 'pelotonUsername_ASC',
  PelotonUsernameDesc = 'pelotonUsername_DESC',
  LinkedInUrlAsc = 'linkedInUrl_ASC',
  LinkedInUrlDesc = 'linkedInUrl_DESC',
  SortOrderAsc = 'sortOrder_ASC',
  SortOrderDesc = 'sortOrder_DESC',
  IsBoardMemberAsc = 'isBoardMember_ASC',
  IsBoardMemberDesc = 'isBoardMember_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAm = Entry & _Node & {
  __typename?: 'ContentType3FI5SLqlYIeaKguyIkeyAm';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmLinkingCollections>;
  _id: Scalars['ID'];
  publication: Maybe<Scalars['String']>;
  headline: Maybe<Scalars['String']>;
  date: Maybe<Scalars['DateTime']>;
  link: Maybe<Scalars['String']>;
  tags: Maybe<Array<Maybe<Scalars['String']>>>;
  countries: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmPublicationArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmHeadlineArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmDateArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmLinkArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmTagsArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/3fI5sLqlYIeaKguyIkeyAM) */
export type ContentType3Fi5SLqlYIeaKguyIkeyAmCountriesArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ContentType3Fi5SLqlYIeaKguyIkeyAmCollection = {
  __typename?: 'ContentType3FI5SLqlYIeaKguyIkeyAmCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAm>>;
};

export type ContentType3Fi5SLqlYIeaKguyIkeyAmFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  publication_exists: Maybe<Scalars['Boolean']>;
  publication: Maybe<Scalars['String']>;
  publication_not: Maybe<Scalars['String']>;
  publication_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_contains: Maybe<Scalars['String']>;
  publication_not_contains: Maybe<Scalars['String']>;
  headline_exists: Maybe<Scalars['Boolean']>;
  headline: Maybe<Scalars['String']>;
  headline_not: Maybe<Scalars['String']>;
  headline_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_contains: Maybe<Scalars['String']>;
  headline_not_contains: Maybe<Scalars['String']>;
  date_exists: Maybe<Scalars['Boolean']>;
  date: Maybe<Scalars['DateTime']>;
  date_not: Maybe<Scalars['DateTime']>;
  date_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  date_gt: Maybe<Scalars['DateTime']>;
  date_gte: Maybe<Scalars['DateTime']>;
  date_lt: Maybe<Scalars['DateTime']>;
  date_lte: Maybe<Scalars['DateTime']>;
  link_exists: Maybe<Scalars['Boolean']>;
  link: Maybe<Scalars['String']>;
  link_not: Maybe<Scalars['String']>;
  link_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains: Maybe<Scalars['String']>;
  link_not_contains: Maybe<Scalars['String']>;
  tags_exists: Maybe<Scalars['Boolean']>;
  tags_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  tags_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  countries_exists: Maybe<Scalars['Boolean']>;
  countries_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  countries_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  countries_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  OR: Maybe<Array<Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmFilter>>>;
  AND: Maybe<Array<Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmFilter>>>;
};

export type ContentType3Fi5SLqlYIeaKguyIkeyAmLinkingCollections = {
  __typename?: 'ContentType3FI5SLqlYIeaKguyIkeyAmLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ContentType3Fi5SLqlYIeaKguyIkeyAmLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ContentType3Fi5SLqlYIeaKguyIkeyAmOrder {
  PublicationAsc = 'publication_ASC',
  PublicationDesc = 'publication_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Allows you to start a drift interaction using a drift interaction Id. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithDriftInteraction) */
export type CtaWithDriftInteraction = Entry & _Node & {
  __typename?: 'CtaWithDriftInteraction';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CtaWithDriftInteractionLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  interactionId: Maybe<Scalars['String']>;
};


/** Allows you to start a drift interaction using a drift interaction Id. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithDriftInteraction) */
export type CtaWithDriftInteractionLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Allows you to start a drift interaction using a drift interaction Id. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithDriftInteraction) */
export type CtaWithDriftInteractionKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Allows you to start a drift interaction using a drift interaction Id. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithDriftInteraction) */
export type CtaWithDriftInteractionTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Allows you to start a drift interaction using a drift interaction Id. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithDriftInteraction) */
export type CtaWithDriftInteractionInteractionIdArgs = {
  locale: Maybe<Scalars['String']>;
};

export type CtaWithDriftInteractionCollection = {
  __typename?: 'CtaWithDriftInteractionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithDriftInteraction>>;
};

export type CtaWithDriftInteractionFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  interactionId_exists: Maybe<Scalars['Boolean']>;
  interactionId: Maybe<Scalars['String']>;
  interactionId_not: Maybe<Scalars['String']>;
  interactionId_in: Maybe<Array<Maybe<Scalars['String']>>>;
  interactionId_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  interactionId_contains: Maybe<Scalars['String']>;
  interactionId_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CtaWithDriftInteractionFilter>>>;
  AND: Maybe<Array<Maybe<CtaWithDriftInteractionFilter>>>;
};

export type CtaWithDriftInteractionLinkingCollections = {
  __typename?: 'CtaWithDriftInteractionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  ctaWithUrlSetCollection: Maybe<CtaWithUrlSetCollection>;
  promoBannerCollection: Maybe<PromoBannerCollection>;
};


export type CtaWithDriftInteractionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type CtaWithDriftInteractionLinkingCollectionsCtaWithUrlSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithDriftInteractionLinkingCollectionsCtaWithUrlSetCollectionOrder>>>;
};


export type CtaWithDriftInteractionLinkingCollectionsPromoBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithDriftInteractionLinkingCollectionsPromoBannerCollectionOrder>>>;
};

export enum CtaWithDriftInteractionLinkingCollectionsCtaWithUrlSetCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithDriftInteractionLinkingCollectionsPromoBannerCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  HideAsc = 'hide_ASC',
  HideDesc = 'hide_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithDriftInteractionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  InteractionIdAsc = 'interactionId_ASC',
  InteractionIdDesc = 'interactionId_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrl = Entry & _Node & {
  __typename?: 'CtaWithUrl';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CtaWithUrlLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  link: Maybe<CtaWithUrlLink>;
  targetBlank: Maybe<Scalars['Boolean']>;
  ariaLabel: Maybe<Scalars['String']>;
  image: Maybe<Asset>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlLinkArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlTargetBlankArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlAriaLabelArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Allows you to write CTA text and select the destination from a dropdown of
 * supported URLs [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrl)
 */
export type CtaWithUrlImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type CtaWithUrlCollection = {
  __typename?: 'CtaWithUrlCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithUrl>>;
};

export type CtaWithUrlFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  link_exists: Maybe<Scalars['Boolean']>;
  targetBlank_exists: Maybe<Scalars['Boolean']>;
  targetBlank: Maybe<Scalars['Boolean']>;
  targetBlank_not: Maybe<Scalars['Boolean']>;
  ariaLabel_exists: Maybe<Scalars['Boolean']>;
  ariaLabel: Maybe<Scalars['String']>;
  ariaLabel_not: Maybe<Scalars['String']>;
  ariaLabel_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ariaLabel_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ariaLabel_contains: Maybe<Scalars['String']>;
  ariaLabel_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CtaWithUrlFilter>>>;
  AND: Maybe<Array<Maybe<CtaWithUrlFilter>>>;
};

export type CtaWithUrlLink = DigitalLink | ExternalLink | StudioSiteLink | WwwLink;

export type CtaWithUrlLinkingCollections = {
  __typename?: 'CtaWithUrlLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productDetailsCardCollection: Maybe<ProductDetailsCardCollection>;
  headbandCollection: Maybe<HeadbandCollection>;
  ctaWithUrlSetCollection: Maybe<CtaWithUrlSetCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  productBlockCollection: Maybe<ProductBlockCollection>;
  captureFormMessagingCollection: Maybe<CaptureFormMessagingCollection>;
  headerBodyImageWithLinkCollection: Maybe<HeaderBodyImageWithLinkCollection>;
  promoHeroCollection: Maybe<PromoHeroCollection>;
  promoBannerCollection: Maybe<PromoBannerCollection>;
  heroCollection: Maybe<HeroCollection>;
};


export type CtaWithUrlLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type CtaWithUrlLinkingCollectionsProductDetailsCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsProductDetailsCardCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsHeadbandCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsHeadbandCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsCtaWithUrlSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsCtaWithUrlSetCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsProductBlockCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsProductBlockCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsCaptureFormMessagingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsCaptureFormMessagingCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsHeaderBodyImageWithLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsHeaderBodyImageWithLinkCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsPromoHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsPromoHeroCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsPromoBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsPromoBannerCollectionOrder>>>;
};


export type CtaWithUrlLinkingCollectionsHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlLinkingCollectionsHeroCollectionOrder>>>;
};

export enum CtaWithUrlLinkingCollectionsCaptureFormMessagingCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsCtaWithUrlSetCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsHeadbandCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsHeaderBodyImageWithLinkCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsProductBlockCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  FeaturedProductAsc = 'featuredProduct_ASC',
  FeaturedProductDesc = 'featuredProduct_DESC',
  FinancingStyleAsc = 'financingStyle_ASC',
  FinancingStyleDesc = 'financingStyle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsProductDetailsCardCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductNameAsc = 'productName_ASC',
  ProductNameDesc = 'productName_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  RibbonTextAsc = 'ribbonText_ASC',
  RibbonTextDesc = 'ribbonText_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsPromoBannerCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  HideAsc = 'hide_ASC',
  HideDesc = 'hide_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlLinkingCollectionsPromoHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A set of CTAs with URLs with an optional title field [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrlSet) */
export type CtaWithUrlSet = Entry & _Node & {
  __typename?: 'CtaWithUrlSet';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<CtaWithUrlSetLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  ctasCollection: Maybe<CtaWithUrlSetCtasCollection>;
};


/** A set of CTAs with URLs with an optional title field [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrlSet) */
export type CtaWithUrlSetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A set of CTAs with URLs with an optional title field [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrlSet) */
export type CtaWithUrlSetKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A set of CTAs with URLs with an optional title field [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrlSet) */
export type CtaWithUrlSetTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A set of CTAs with URLs with an optional title field [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/ctaWithUrlSet) */
export type CtaWithUrlSetCtasCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlSetCtasFilter>;
};

export type CtaWithUrlSetCollection = {
  __typename?: 'CtaWithUrlSetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithUrlSet>>;
};

export type CtaWithUrlSetCtasCollection = {
  __typename?: 'CtaWithUrlSetCtasCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithUrlSetCtasItem>>;
};

export type CtaWithUrlSetCtasFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<CtaWithUrlSetCtasFilter>>>;
  AND: Maybe<Array<Maybe<CtaWithUrlSetCtasFilter>>>;
};

export type CtaWithUrlSetCtasItem = CtaWithDriftInteraction | CtaWithUrl;

export type CtaWithUrlSetFilter = {
  ctas: Maybe<CfctasMultiTypeNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  ctasCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<CtaWithUrlSetFilter>>>;
  AND: Maybe<Array<Maybe<CtaWithUrlSetFilter>>>;
};

export type CtaWithUrlSetLinkingCollections = {
  __typename?: 'CtaWithUrlSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productNavCollection: Maybe<ProductNavCollection>;
  navigationCollection: Maybe<NavigationCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type CtaWithUrlSetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type CtaWithUrlSetLinkingCollectionsProductNavCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlSetLinkingCollectionsProductNavCollectionOrder>>>;
};


export type CtaWithUrlSetLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlSetLinkingCollectionsNavigationCollectionOrder>>>;
};


export type CtaWithUrlSetLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<CtaWithUrlSetLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum CtaWithUrlSetLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlSetLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlSetLinkingCollectionsProductNavCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum CtaWithUrlSetOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckout = Entry & _Node & {
  __typename?: 'DigitalCheckout';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<DigitalCheckoutLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  sidePaneImage: Maybe<Image>;
  introSection: Maybe<Section>;
  valuePropSet: Maybe<ValuePropSet>;
  emailLabel: Maybe<Forms>;
  secondStepIntro: Maybe<Section>;
  product: Maybe<Product>;
  backButton: Maybe<KeyValue>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutSidePaneImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutIntroSectionArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SectionFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutValuePropSetArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ValuePropSetFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutEmailLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutSecondStepIntroArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SectionFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutProductArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductFilter>;
};


/**
 * This model is designed to capture all of the keys used in the new digital
 * checkout workflow. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalCheckout)
 */
export type DigitalCheckoutBackButtonArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<KeyValueFilter>;
};

export type DigitalCheckoutCollection = {
  __typename?: 'DigitalCheckoutCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DigitalCheckout>>;
};

export type DigitalCheckoutFilter = {
  sidePaneImage: Maybe<CfImageNestedFilter>;
  introSection: Maybe<CfSectionNestedFilter>;
  valuePropSet: Maybe<CfValuePropSetNestedFilter>;
  emailLabel: Maybe<CfFormsNestedFilter>;
  secondStepIntro: Maybe<CfSectionNestedFilter>;
  product: Maybe<CfProductNestedFilter>;
  backButton: Maybe<CfKeyValueNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  sidePaneImage_exists: Maybe<Scalars['Boolean']>;
  introSection_exists: Maybe<Scalars['Boolean']>;
  valuePropSet_exists: Maybe<Scalars['Boolean']>;
  emailLabel_exists: Maybe<Scalars['Boolean']>;
  secondStepIntro_exists: Maybe<Scalars['Boolean']>;
  product_exists: Maybe<Scalars['Boolean']>;
  backButton_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<DigitalCheckoutFilter>>>;
  AND: Maybe<Array<Maybe<DigitalCheckoutFilter>>>;
};

export type DigitalCheckoutLinkingCollections = {
  __typename?: 'DigitalCheckoutLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type DigitalCheckoutLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum DigitalCheckoutOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A predefined list of digital app URLs (ex: /classes, /preferences, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalLink) */
export type DigitalLink = Entry & _Node & {
  __typename?: 'DigitalLink';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<DigitalLinkLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};


/** A predefined list of digital app URLs (ex: /classes, /preferences, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalLink) */
export type DigitalLinkLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A predefined list of digital app URLs (ex: /classes, /preferences, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalLink) */
export type DigitalLinkKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A predefined list of digital app URLs (ex: /classes, /preferences, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalLink) */
export type DigitalLinkUrlArgs = {
  locale: Maybe<Scalars['String']>;
};

export type DigitalLinkCollection = {
  __typename?: 'DigitalLinkCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DigitalLink>>;
};

export type DigitalLinkFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  url_exists: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  url_not: Maybe<Scalars['String']>;
  url_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains: Maybe<Scalars['String']>;
  url_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<DigitalLinkFilter>>>;
  AND: Maybe<Array<Maybe<DigitalLinkFilter>>>;
};

export type DigitalLinkLinkingCollections = {
  __typename?: 'DigitalLinkLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
};


export type DigitalLinkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type DigitalLinkLinkingCollectionsCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<DigitalLinkLinkingCollectionsCtaWithUrlCollectionOrder>>>;
};

export enum DigitalLinkLinkingCollectionsCtaWithUrlCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum DigitalLinkOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotion = Entry & _Node & {
  __typename?: 'DigitalPromotion';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<DigitalPromotionLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  topCardLogo: Maybe<Image>;
  topCardMessage: Maybe<Scalars['String']>;
  introSection: Maybe<Section>;
  additionalEmailMessage: Maybe<Scalars['String']>;
  creditCardLegend: Maybe<Forms>;
  acceptedCards: Maybe<KeyValue>;
  product: Maybe<Product>;
  digitalFaq: Maybe<Faq>;
  legalText: Maybe<LegalText>;
  digitalWarning: Maybe<Forms>;
  valuePropSet: Maybe<ValuePropSet>;
  emailLabel: Maybe<Forms>;
  termsAndConditions: Maybe<Forms>;
  accessCodeLabel: Maybe<Forms>;
  sidePaneImage: Maybe<Image>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionTopCardLogoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionTopCardMessageArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionIntroSectionArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SectionFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionAdditionalEmailMessageArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionCreditCardLegendArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionAcceptedCardsArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<KeyValueFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionProductArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionDigitalFaqArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FaqFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionLegalTextArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LegalTextFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionDigitalWarningArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionValuePropSetArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ValuePropSetFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionEmailLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionTermsAndConditionsArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionAccessCodeLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/digitalPromotion) */
export type DigitalPromotionSidePaneImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};

export type DigitalPromotionCollection = {
  __typename?: 'DigitalPromotionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<DigitalPromotion>>;
};

export type DigitalPromotionFilter = {
  topCardLogo: Maybe<CfImageNestedFilter>;
  introSection: Maybe<CfSectionNestedFilter>;
  creditCardLegend: Maybe<CfFormsNestedFilter>;
  acceptedCards: Maybe<CfKeyValueNestedFilter>;
  product: Maybe<CfProductNestedFilter>;
  digitalFaq: Maybe<CfFaqNestedFilter>;
  legalText: Maybe<CfLegalTextNestedFilter>;
  digitalWarning: Maybe<CfFormsNestedFilter>;
  valuePropSet: Maybe<CfValuePropSetNestedFilter>;
  emailLabel: Maybe<CfFormsNestedFilter>;
  termsAndConditions: Maybe<CfFormsNestedFilter>;
  accessCodeLabel: Maybe<CfFormsNestedFilter>;
  sidePaneImage: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  topCardLogo_exists: Maybe<Scalars['Boolean']>;
  topCardMessage_exists: Maybe<Scalars['Boolean']>;
  topCardMessage: Maybe<Scalars['String']>;
  topCardMessage_not: Maybe<Scalars['String']>;
  topCardMessage_in: Maybe<Array<Maybe<Scalars['String']>>>;
  topCardMessage_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  topCardMessage_contains: Maybe<Scalars['String']>;
  topCardMessage_not_contains: Maybe<Scalars['String']>;
  introSection_exists: Maybe<Scalars['Boolean']>;
  additionalEmailMessage_exists: Maybe<Scalars['Boolean']>;
  additionalEmailMessage: Maybe<Scalars['String']>;
  additionalEmailMessage_not: Maybe<Scalars['String']>;
  additionalEmailMessage_in: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalEmailMessage_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  additionalEmailMessage_contains: Maybe<Scalars['String']>;
  additionalEmailMessage_not_contains: Maybe<Scalars['String']>;
  creditCardLegend_exists: Maybe<Scalars['Boolean']>;
  acceptedCards_exists: Maybe<Scalars['Boolean']>;
  product_exists: Maybe<Scalars['Boolean']>;
  digitalFaq_exists: Maybe<Scalars['Boolean']>;
  legalText_exists: Maybe<Scalars['Boolean']>;
  digitalWarning_exists: Maybe<Scalars['Boolean']>;
  valuePropSet_exists: Maybe<Scalars['Boolean']>;
  emailLabel_exists: Maybe<Scalars['Boolean']>;
  termsAndConditions_exists: Maybe<Scalars['Boolean']>;
  accessCodeLabel_exists: Maybe<Scalars['Boolean']>;
  sidePaneImage_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<DigitalPromotionFilter>>>;
  AND: Maybe<Array<Maybe<DigitalPromotionFilter>>>;
};

export type DigitalPromotionLinkingCollections = {
  __typename?: 'DigitalPromotionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type DigitalPromotionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum DigitalPromotionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type Entry = {
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Entry>>;
};

export type EntryFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  OR: Maybe<Array<Maybe<EntryFilter>>>;
  AND: Maybe<Array<Maybe<EntryFilter>>>;
};

export enum EntryOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A text field that only accepts URLs. Should be used for non-www pages (ex:
 * support pages, blog posts, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/externalLink)
 */
export type ExternalLink = Entry & _Node & {
  __typename?: 'ExternalLink';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ExternalLinkLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};


/**
 * A text field that only accepts URLs. Should be used for non-www pages (ex:
 * support pages, blog posts, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/externalLink)
 */
export type ExternalLinkLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A text field that only accepts URLs. Should be used for non-www pages (ex:
 * support pages, blog posts, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/externalLink)
 */
export type ExternalLinkKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A text field that only accepts URLs. Should be used for non-www pages (ex:
 * support pages, blog posts, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/externalLink)
 */
export type ExternalLinkUrlArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ExternalLinkCollection = {
  __typename?: 'ExternalLinkCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ExternalLink>>;
};

export type ExternalLinkFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  url_exists: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  url_not: Maybe<Scalars['String']>;
  url_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains: Maybe<Scalars['String']>;
  url_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ExternalLinkFilter>>>;
  AND: Maybe<Array<Maybe<ExternalLinkFilter>>>;
};

export type ExternalLinkLinkingCollections = {
  __typename?: 'ExternalLinkLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
};


export type ExternalLinkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ExternalLinkLinkingCollectionsCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ExternalLinkLinkingCollectionsCtaWithUrlCollectionOrder>>>;
};

export enum ExternalLinkLinkingCollectionsCtaWithUrlCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ExternalLinkOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Frequently Asked Question Set [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/faq) */
export type Faq = Entry & _Node & {
  __typename?: 'Faq';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<FaqLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  pairsCollection: Maybe<FaqPairsCollection>;
  title: Maybe<Scalars['String']>;
};


/** Frequently Asked Question Set [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/faq) */
export type FaqLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Frequently Asked Question Set [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/faq) */
export type FaqKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Frequently Asked Question Set [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/faq) */
export type FaqPairsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<QAndAFilter>;
  order: Maybe<Array<Maybe<FaqPairsCollectionOrder>>>;
};


/** Frequently Asked Question Set [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/faq) */
export type FaqTitleArgs = {
  locale: Maybe<Scalars['String']>;
};

export type FaqCollection = {
  __typename?: 'FaqCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Faq>>;
};

export type FaqFilter = {
  pairs: Maybe<CfQAndANestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  pairsCollection_exists: Maybe<Scalars['Boolean']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<FaqFilter>>>;
  AND: Maybe<Array<Maybe<FaqFilter>>>;
};

export type FaqLinkingCollections = {
  __typename?: 'FaqLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type FaqLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type FaqLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<FaqLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum FaqLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FaqOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type FaqPairsCollection = {
  __typename?: 'FaqPairsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<QAndA>>;
};

export enum FaqPairsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Key value mappings that fall under the forms domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/forms)
 */
export type Forms = Entry & _Node & {
  __typename?: 'Forms';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<FormsLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};


/**
 * Key value mappings that fall under the forms domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/forms)
 */
export type FormsLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Key value mappings that fall under the forms domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/forms)
 */
export type FormsKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Key value mappings that fall under the forms domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/forms)
 */
export type FormsValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type FormsCollection = {
  __typename?: 'FormsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Forms>>;
};

export type FormsFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<FormsFilter>>>;
  AND: Maybe<Array<Maybe<FormsFilter>>>;
};

export type FormsLinkingCollections = {
  __typename?: 'FormsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type FormsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type FormsLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<FormsLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type FormsLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<FormsLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum FormsLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FormsLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum FormsOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type Headband = Entry & _Node & {
  __typename?: 'Headband';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeadbandLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  financingText: Maybe<PromotionalText>;
  device: Maybe<Scalars['String']>;
  ctaLink: Maybe<CtaWithUrl>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandFinancingTextArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromotionalTextFilter>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandDeviceArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product info, financing text, and CTA link/text. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headband) */
export type HeadbandCtaLinkArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};

export type HeadbandCollection = {
  __typename?: 'HeadbandCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Headband>>;
};

export type HeadbandFilter = {
  financingText: Maybe<CfPromotionalTextNestedFilter>;
  ctaLink: Maybe<CfCtaWithUrlNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  financingText_exists: Maybe<Scalars['Boolean']>;
  device_exists: Maybe<Scalars['Boolean']>;
  device: Maybe<Scalars['String']>;
  device_not: Maybe<Scalars['String']>;
  device_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_contains: Maybe<Scalars['String']>;
  device_not_contains: Maybe<Scalars['String']>;
  ctaLink_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<HeadbandFilter>>>;
  AND: Maybe<Array<Maybe<HeadbandFilter>>>;
};

export type HeadbandLinkingCollections = {
  __typename?: 'HeadbandLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type HeadbandLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum HeadbandOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBody = Entry & _Node & {
  __typename?: 'HeaderBody';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeaderBodyLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  footnote: Maybe<LegalText>;
};


/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBodyLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBodyKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBodyHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBodyBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** For Header - Body pairings [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBody) */
export type HeaderBodyFootnoteArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LegalTextFilter>;
};

export type HeaderBodyCollection = {
  __typename?: 'HeaderBodyCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBody>>;
};

export type HeaderBodyFilter = {
  footnote: Maybe<CfLegalTextNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  footnote_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<HeaderBodyFilter>>>;
  AND: Maybe<Array<Maybe<HeaderBodyFilter>>>;
};

/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImage = Entry & _Node & {
  __typename?: 'HeaderBodyImage';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeaderBodyImageLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  theme: Maybe<Scalars['String']>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** A generic module with a header, some body text and an image [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImage) */
export type HeaderBodyImageThemeArgs = {
  locale: Maybe<Scalars['String']>;
};

export type HeaderBodyImageCollection = {
  __typename?: 'HeaderBodyImageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImage>>;
};

export type HeaderBodyImageFilter = {
  image: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  theme_exists: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  theme_not: Maybe<Scalars['String']>;
  theme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_contains: Maybe<Scalars['String']>;
  theme_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<HeaderBodyImageFilter>>>;
  AND: Maybe<Array<Maybe<HeaderBodyImageFilter>>>;
};

/** A collection of HeaderBodyImage items [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageGallery) */
export type HeaderBodyImageGallery = Entry & _Node & {
  __typename?: 'HeaderBodyImageGallery';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeaderBodyImageGalleryLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  itemsCollection: Maybe<HeaderBodyImageGalleryItemsCollection>;
};


/** A collection of HeaderBodyImage items [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageGallery) */
export type HeaderBodyImageGalleryLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A collection of HeaderBodyImage items [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageGallery) */
export type HeaderBodyImageGalleryKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A collection of HeaderBodyImage items [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageGallery) */
export type HeaderBodyImageGalleryItemsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageGalleryItemsCollectionOrder>>>;
};

export type HeaderBodyImageGalleryCollection = {
  __typename?: 'HeaderBodyImageGalleryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImageGallery>>;
};

export type HeaderBodyImageGalleryFilter = {
  items: Maybe<CfHeaderBodyImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  itemsCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<HeaderBodyImageGalleryFilter>>>;
  AND: Maybe<Array<Maybe<HeaderBodyImageGalleryFilter>>>;
};

export type HeaderBodyImageGalleryItemsCollection = {
  __typename?: 'HeaderBodyImageGalleryItemsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImage>>;
};

export enum HeaderBodyImageGalleryItemsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeaderBodyImageGalleryLinkingCollections = {
  __typename?: 'HeaderBodyImageGalleryLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type HeaderBodyImageGalleryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum HeaderBodyImageGalleryOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeaderBodyImageLinkingCollections = {
  __typename?: 'HeaderBodyImageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  headerBodyImageGalleryCollection: Maybe<HeaderBodyImageGalleryCollection>;
};


export type HeaderBodyImageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type HeaderBodyImageLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<HeaderBodyImageLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type HeaderBodyImageLinkingCollectionsHeaderBodyImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<HeaderBodyImageLinkingCollectionsHeaderBodyImageGalleryCollectionOrder>>>;
};

export enum HeaderBodyImageLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum HeaderBodyImageLinkingCollectionsHeaderBodyImageGalleryCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum HeaderBodyImageOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLink = Entry & _Node & {
  __typename?: 'HeaderBodyImageWithLink';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeaderBodyImageWithLinkLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  eyebrow: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  link: Maybe<Scalars['String']>;
  linkRef: Maybe<CtaWithUrl>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkLinkArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Header, body, image, and a link! [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLink) */
export type HeaderBodyImageWithLinkLinkRefArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};

export type HeaderBodyImageWithLinkCollection = {
  __typename?: 'HeaderBodyImageWithLinkCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImageWithLink>>;
};

export type HeaderBodyImageWithLinkFilter = {
  image: Maybe<CfImageNestedFilter>;
  linkRef: Maybe<CfCtaWithUrlNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  link_exists: Maybe<Scalars['Boolean']>;
  link: Maybe<Scalars['String']>;
  link_not: Maybe<Scalars['String']>;
  link_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  link_contains: Maybe<Scalars['String']>;
  link_not_contains: Maybe<Scalars['String']>;
  linkRef_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<HeaderBodyImageWithLinkFilter>>>;
  AND: Maybe<Array<Maybe<HeaderBodyImageWithLinkFilter>>>;
};

/** A set of headerBodyImageWithLink entries [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLinkGallery) */
export type HeaderBodyImageWithLinkGallery = Entry & _Node & {
  __typename?: 'HeaderBodyImageWithLinkGallery';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeaderBodyImageWithLinkGalleryLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  itemsCollection: Maybe<HeaderBodyImageWithLinkGalleryItemsCollection>;
};


/** A set of headerBodyImageWithLink entries [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLinkGallery) */
export type HeaderBodyImageWithLinkGalleryLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A set of headerBodyImageWithLink entries [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLinkGallery) */
export type HeaderBodyImageWithLinkGalleryKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A set of headerBodyImageWithLink entries [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/headerBodyImageWithLinkGallery) */
export type HeaderBodyImageWithLinkGalleryItemsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageWithLinkFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageWithLinkGalleryItemsCollectionOrder>>>;
};

export type HeaderBodyImageWithLinkGalleryCollection = {
  __typename?: 'HeaderBodyImageWithLinkGalleryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImageWithLinkGallery>>;
};

export type HeaderBodyImageWithLinkGalleryFilter = {
  items: Maybe<CfHeaderBodyImageWithLinkNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  itemsCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<HeaderBodyImageWithLinkGalleryFilter>>>;
  AND: Maybe<Array<Maybe<HeaderBodyImageWithLinkGalleryFilter>>>;
};

export type HeaderBodyImageWithLinkGalleryItemsCollection = {
  __typename?: 'HeaderBodyImageWithLinkGalleryItemsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<HeaderBodyImageWithLink>>;
};

export enum HeaderBodyImageWithLinkGalleryItemsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeaderBodyImageWithLinkGalleryLinkingCollections = {
  __typename?: 'HeaderBodyImageWithLinkGalleryLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type HeaderBodyImageWithLinkGalleryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum HeaderBodyImageWithLinkGalleryOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeaderBodyImageWithLinkLinkingCollections = {
  __typename?: 'HeaderBodyImageWithLinkLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  headerBodyImageWithLinkGalleryCollection: Maybe<HeaderBodyImageWithLinkGalleryCollection>;
};


export type HeaderBodyImageWithLinkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type HeaderBodyImageWithLinkLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<HeaderBodyImageWithLinkLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type HeaderBodyImageWithLinkLinkingCollectionsHeaderBodyImageWithLinkGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<HeaderBodyImageWithLinkLinkingCollectionsHeaderBodyImageWithLinkGalleryCollectionOrder>>>;
};

export enum HeaderBodyImageWithLinkLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum HeaderBodyImageWithLinkLinkingCollectionsHeaderBodyImageWithLinkGalleryCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum HeaderBodyImageWithLinkOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeaderBodyLinkingCollections = {
  __typename?: 'HeaderBodyLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type HeaderBodyLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum HeaderBodyOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type Hero = Entry & _Node & {
  __typename?: 'Hero';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<HeroLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  video: Maybe<Asset>;
  headline: Maybe<Scalars['String']>;
  subhead: Maybe<Scalars['String']>;
  eyebrow: Maybe<Scalars['String']>;
  poster: Maybe<Asset>;
  image: Maybe<Image>;
  breakpointVideo: Maybe<BreakpointVideo>;
  ctasWithUrlsCollection: Maybe<HeroCtasWithUrlsCollection>;
  cta: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroVideoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroHeadlineArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroSubheadArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroPosterArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroBreakpointVideoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<BreakpointVideoFilter>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroCtasWithUrlsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
  order: Maybe<Array<Maybe<HeroCtasWithUrlsCollectionOrder>>>;
};


/** A page hero component [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/hero) */
export type HeroCtaArgs = {
  locale: Maybe<Scalars['String']>;
};

export type HeroCollection = {
  __typename?: 'HeroCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Hero>>;
};

export type HeroCtasWithUrlsCollection = {
  __typename?: 'HeroCtasWithUrlsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithUrl>>;
};

export enum HeroCtasWithUrlsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type HeroFilter = {
  image: Maybe<CfImageNestedFilter>;
  breakpointVideo: Maybe<CfBreakpointVideoNestedFilter>;
  ctasWithUrls: Maybe<CfCtaWithUrlNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  video_exists: Maybe<Scalars['Boolean']>;
  headline_exists: Maybe<Scalars['Boolean']>;
  headline: Maybe<Scalars['String']>;
  headline_not: Maybe<Scalars['String']>;
  headline_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_contains: Maybe<Scalars['String']>;
  headline_not_contains: Maybe<Scalars['String']>;
  subhead_exists: Maybe<Scalars['Boolean']>;
  subhead: Maybe<Scalars['String']>;
  subhead_not: Maybe<Scalars['String']>;
  subhead_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_contains: Maybe<Scalars['String']>;
  subhead_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  poster_exists: Maybe<Scalars['Boolean']>;
  image_exists: Maybe<Scalars['Boolean']>;
  breakpointVideo_exists: Maybe<Scalars['Boolean']>;
  ctasWithUrlsCollection_exists: Maybe<Scalars['Boolean']>;
  cta_exists: Maybe<Scalars['Boolean']>;
  cta: Maybe<Scalars['String']>;
  cta_not: Maybe<Scalars['String']>;
  cta_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_contains: Maybe<Scalars['String']>;
  cta_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<HeroFilter>>>;
  AND: Maybe<Array<Maybe<HeroFilter>>>;
};

export type HeroLinkingCollections = {
  __typename?: 'HeroLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type HeroLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum HeroOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type Image = Entry & _Node & {
  __typename?: 'Image';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ImageLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  mobile: Maybe<Asset>;
  tablet: Maybe<Asset>;
  desktop: Maybe<Asset>;
  desktopLarge: Maybe<Asset>;
  caption: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageMobileArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageTabletArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageDesktopArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageDesktopLargeArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * An image content type encapsulating image breakpoints [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/image)
 */
export type ImageCaptionArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ImageCollection = {
  __typename?: 'ImageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Image>>;
};

export type ImageFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  mobile_exists: Maybe<Scalars['Boolean']>;
  tablet_exists: Maybe<Scalars['Boolean']>;
  desktop_exists: Maybe<Scalars['Boolean']>;
  desktopLarge_exists: Maybe<Scalars['Boolean']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ImageFilter>>>;
  AND: Maybe<Array<Maybe<ImageFilter>>>;
};

export enum ImageFormat {
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
  Avif = 'AVIF'
}

/**
 * A set of instances of the Image TYPE, as distinct from the Image ASSET. If you
 * have to use the Media tab to find the item, it's an asset, not a type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/imageGallery)
 */
export type ImageGallery = Entry & _Node & {
  __typename?: 'ImageGallery';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ImageGalleryLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  imagesCollection: Maybe<ImageGalleryImagesCollection>;
};


/**
 * A set of instances of the Image TYPE, as distinct from the Image ASSET. If you
 * have to use the Media tab to find the item, it's an asset, not a type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/imageGallery)
 */
export type ImageGalleryLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A set of instances of the Image TYPE, as distinct from the Image ASSET. If you
 * have to use the Media tab to find the item, it's an asset, not a type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/imageGallery)
 */
export type ImageGalleryKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A set of instances of the Image TYPE, as distinct from the Image ASSET. If you
 * have to use the Media tab to find the item, it's an asset, not a type. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/imageGallery)
 */
export type ImageGalleryImagesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageGalleryImagesFilter>;
};

export type ImageGalleryCollection = {
  __typename?: 'ImageGalleryCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ImageGallery>>;
};

export type ImageGalleryFilter = {
  images: Maybe<CfimagesMultiTypeNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  imagesCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ImageGalleryFilter>>>;
  AND: Maybe<Array<Maybe<ImageGalleryFilter>>>;
};

export type ImageGalleryImagesCollection = {
  __typename?: 'ImageGalleryImagesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ImageGalleryImagesItem>>;
};

export type ImageGalleryImagesFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ImageGalleryImagesFilter>>>;
  AND: Maybe<Array<Maybe<ImageGalleryImagesFilter>>>;
};

export type ImageGalleryImagesItem = Image | Video;

export type ImageGalleryLinkingCollections = {
  __typename?: 'ImageGalleryLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ImageGalleryLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ImageGalleryOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ImageLinkingCollections = {
  __typename?: 'ImageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productRecommendationsCohortCollection: Maybe<ProductRecommendationsCohortCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  productBlockCollection: Maybe<ProductBlockCollection>;
  imageGalleryCollection: Maybe<ImageGalleryCollection>;
  headerBodyImageWithLinkCollection: Maybe<HeaderBodyImageWithLinkCollection>;
  promoHeroCollection: Maybe<PromoHeroCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
  heroCollection: Maybe<HeroCollection>;
  headerBodyImageCollection: Maybe<HeaderBodyImageCollection>;
  mediaSectionCollection: Maybe<MediaSectionCollection>;
  registrationPageCollection: Maybe<RegistrationPageCollection>;
};


export type ImageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ImageLinkingCollectionsProductRecommendationsCohortCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsProductRecommendationsCohortCollectionOrder>>>;
};


export type ImageLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type ImageLinkingCollectionsProductBlockCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsProductBlockCollectionOrder>>>;
};


export type ImageLinkingCollectionsImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsImageGalleryCollectionOrder>>>;
};


export type ImageLinkingCollectionsHeaderBodyImageWithLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsHeaderBodyImageWithLinkCollectionOrder>>>;
};


export type ImageLinkingCollectionsPromoHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsPromoHeroCollectionOrder>>>;
};


export type ImageLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type ImageLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};


export type ImageLinkingCollectionsHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsHeroCollectionOrder>>>;
};


export type ImageLinkingCollectionsHeaderBodyImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsHeaderBodyImageCollectionOrder>>>;
};


export type ImageLinkingCollectionsMediaSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsMediaSectionCollectionOrder>>>;
};


export type ImageLinkingCollectionsRegistrationPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ImageLinkingCollectionsRegistrationPageCollectionOrder>>>;
};

export enum ImageLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsHeaderBodyImageCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsHeaderBodyImageWithLinkCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsImageGalleryCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsMediaSectionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsProductBlockCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  FeaturedProductAsc = 'featuredProduct_ASC',
  FeaturedProductDesc = 'featuredProduct_DESC',
  FinancingStyleAsc = 'financingStyle_ASC',
  FinancingStyleDesc = 'financingStyle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsProductRecommendationsCohortCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubHeadlineAsc = 'subHeadline_ASC',
  SubHeadlineDesc = 'subHeadline_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsPromoHeroCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageLinkingCollectionsRegistrationPageCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ImageResizeFocus {
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES'
}

export enum ImageResizeStrategy {
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB'
}

export type ImageTransformOptions = {
  /** Desired width in pixels. Defaults to the original image width. */
  width: Maybe<Scalars['Dimension']>;
  /** Desired height in pixels. Defaults to the original image height. */
  height: Maybe<Scalars['Dimension']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality: Maybe<Scalars['Quality']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius: Maybe<Scalars['Int']>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy: Maybe<ImageResizeStrategy>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus: Maybe<ImageResizeFocus>;
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor: Maybe<Scalars['HexColor']>;
  /** Desired image format. Defaults to the original image format. */
  format: Maybe<ImageFormat>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/jsonKeyValue) */
export type JsonKeyValue = Entry & _Node & {
  __typename?: 'JsonKeyValue';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<JsonKeyValueLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['JSON']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/jsonKeyValue) */
export type JsonKeyValueLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/jsonKeyValue) */
export type JsonKeyValueKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/jsonKeyValue) */
export type JsonKeyValueValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type JsonKeyValueCollection = {
  __typename?: 'JsonKeyValueCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<JsonKeyValue>>;
};

export type JsonKeyValueFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<JsonKeyValueFilter>>>;
  AND: Maybe<Array<Maybe<JsonKeyValueFilter>>>;
};

export type JsonKeyValueLinkingCollections = {
  __typename?: 'JsonKeyValueLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type JsonKeyValueLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type JsonKeyValueLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<JsonKeyValueLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum JsonKeyValueLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum JsonKeyValueOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** key-value content migrated automatically from localized strings. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/keyValue) */
export type KeyValue = Entry & _Node & {
  __typename?: 'KeyValue';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<KeyValueLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};


/** key-value content migrated automatically from localized strings. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/keyValue) */
export type KeyValueLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** key-value content migrated automatically from localized strings. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/keyValue) */
export type KeyValueKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** key-value content migrated automatically from localized strings. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/keyValue) */
export type KeyValueValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type KeyValueCollection = {
  __typename?: 'KeyValueCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<KeyValue>>;
};

export type KeyValueFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<KeyValueFilter>>>;
  AND: Maybe<Array<Maybe<KeyValueFilter>>>;
};

export type KeyValueLinkingCollections = {
  __typename?: 'KeyValueLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  navigationCollection: Maybe<NavigationCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type KeyValueLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type KeyValueLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<KeyValueLinkingCollectionsNavigationCollectionOrder>>>;
};


export type KeyValueLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<KeyValueLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type KeyValueLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<KeyValueLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type KeyValueLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<KeyValueLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum KeyValueLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum KeyValueLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum KeyValueLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum KeyValueLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum KeyValueOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Legal text blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/legalText) */
export type LegalText = Entry & _Node & {
  __typename?: 'LegalText';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<LegalTextLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
};


/** Legal text blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/legalText) */
export type LegalTextLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Legal text blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/legalText) */
export type LegalTextKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Legal text blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/legalText) */
export type LegalTextTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type LegalTextCollection = {
  __typename?: 'LegalTextCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<LegalText>>;
};

export type LegalTextFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<LegalTextFilter>>>;
  AND: Maybe<Array<Maybe<LegalTextFilter>>>;
};

export type LegalTextLinkingCollections = {
  __typename?: 'LegalTextLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  promoCollection: Maybe<PromoCollection>;
  headerBodyCollection: Maybe<HeaderBodyCollection>;
  captureFormMessagingCollection: Maybe<CaptureFormMessagingCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type LegalTextLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type LegalTextLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LegalTextLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type LegalTextLinkingCollectionsPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LegalTextLinkingCollectionsPromoCollectionOrder>>>;
};


export type LegalTextLinkingCollectionsHeaderBodyCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LegalTextLinkingCollectionsHeaderBodyCollectionOrder>>>;
};


export type LegalTextLinkingCollectionsCaptureFormMessagingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LegalTextLinkingCollectionsCaptureFormMessagingCollectionOrder>>>;
};


export type LegalTextLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LegalTextLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum LegalTextLinkingCollectionsCaptureFormMessagingCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LegalTextLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LegalTextLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LegalTextLinkingCollectionsHeaderBodyCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LegalTextLinkingCollectionsPromoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LegalTextOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnote = Entry & _Node & {
  __typename?: 'LongHeaderBodyFootnote';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<LongHeaderBodyFootnoteLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  footnote: Maybe<Scalars['String']>;
  tooltip: Maybe<Shop>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteFootnoteArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * For Header - Body pairings w/ optional footnote (this differs from "HeaderBody"
 * in that the LongHeaderBodyFootnote's Header has a "Long text" type instead of
 * "Short text") [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/longHeaderBodyFootnote)
 */
export type LongHeaderBodyFootnoteTooltipArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
};

export type LongHeaderBodyFootnoteCollection = {
  __typename?: 'LongHeaderBodyFootnoteCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<LongHeaderBodyFootnote>>;
};

export type LongHeaderBodyFootnoteFilter = {
  tooltip: Maybe<CfShopNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  footnote_exists: Maybe<Scalars['Boolean']>;
  footnote: Maybe<Scalars['String']>;
  footnote_not: Maybe<Scalars['String']>;
  footnote_in: Maybe<Array<Maybe<Scalars['String']>>>;
  footnote_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  footnote_contains: Maybe<Scalars['String']>;
  footnote_not_contains: Maybe<Scalars['String']>;
  tooltip_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<LongHeaderBodyFootnoteFilter>>>;
  AND: Maybe<Array<Maybe<LongHeaderBodyFootnoteFilter>>>;
};

export type LongHeaderBodyFootnoteLinkingCollections = {
  __typename?: 'LongHeaderBodyFootnoteLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  otdLookupCollection: Maybe<OtdLookupCollection>;
};


export type LongHeaderBodyFootnoteLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type LongHeaderBodyFootnoteLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LongHeaderBodyFootnoteLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type LongHeaderBodyFootnoteLinkingCollectionsOtdLookupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<LongHeaderBodyFootnoteLinkingCollectionsOtdLookupCollectionOrder>>>;
};

export enum LongHeaderBodyFootnoteLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LongHeaderBodyFootnoteLinkingCollectionsOtdLookupCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum LongHeaderBodyFootnoteOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** An entire page of markdown e.g. Privacy Policy and Membership Terms [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/markdownPage) */
export type MarkdownPage = Entry & _Node & {
  __typename?: 'MarkdownPage';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<MarkdownPageLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  overflow: Maybe<Scalars['String']>;
};


/** An entire page of markdown e.g. Privacy Policy and Membership Terms [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/markdownPage) */
export type MarkdownPageLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** An entire page of markdown e.g. Privacy Policy and Membership Terms [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/markdownPage) */
export type MarkdownPageKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** An entire page of markdown e.g. Privacy Policy and Membership Terms [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/markdownPage) */
export type MarkdownPageBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** An entire page of markdown e.g. Privacy Policy and Membership Terms [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/markdownPage) */
export type MarkdownPageOverflowArgs = {
  locale: Maybe<Scalars['String']>;
};

export type MarkdownPageCollection = {
  __typename?: 'MarkdownPageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<MarkdownPage>>;
};

export type MarkdownPageFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  overflow_exists: Maybe<Scalars['Boolean']>;
  overflow: Maybe<Scalars['String']>;
  overflow_not: Maybe<Scalars['String']>;
  overflow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  overflow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  overflow_contains: Maybe<Scalars['String']>;
  overflow_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<MarkdownPageFilter>>>;
  AND: Maybe<Array<Maybe<MarkdownPageFilter>>>;
};

export type MarkdownPageLinkingCollections = {
  __typename?: 'MarkdownPageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productDetailsCardCollection: Maybe<ProductDetailsCardCollection>;
};


export type MarkdownPageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type MarkdownPageLinkingCollectionsProductDetailsCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<MarkdownPageLinkingCollectionsProductDetailsCardCollectionOrder>>>;
};

export enum MarkdownPageLinkingCollectionsProductDetailsCardCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductNameAsc = 'productName_ASC',
  ProductNameDesc = 'productName_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  RibbonTextAsc = 'ribbonText_ASC',
  RibbonTextDesc = 'ribbonText_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum MarkdownPageOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSection = Entry & _Node & {
  __typename?: 'MediaSection';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<MediaSectionLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  video: Maybe<BreakpointVideo>;
  image: Maybe<Image>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionVideoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<BreakpointVideoFilter>;
};


/**
 * A section with text (header and body), video, and optional image fallbacks for
 * videos [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/mediaSection)
 */
export type MediaSectionImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};

export type MediaSectionCollection = {
  __typename?: 'MediaSectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<MediaSection>>;
};

export type MediaSectionFilter = {
  video: Maybe<CfBreakpointVideoNestedFilter>;
  image: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  video_exists: Maybe<Scalars['Boolean']>;
  image_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<MediaSectionFilter>>>;
  AND: Maybe<Array<Maybe<MediaSectionFilter>>>;
};

export type MediaSectionLinkingCollections = {
  __typename?: 'MediaSectionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type MediaSectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum MediaSectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type Metadata = Entry & _Node & {
  __typename?: 'Metadata';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<MetadataLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  keywords: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype: Maybe<Scalars['String']>;
  image: Maybe<Asset>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataKeywordsArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataOgtypeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Page level metadata tags [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/metadata) */
export type MetadataImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type MetadataCollection = {
  __typename?: 'MetadataCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Metadata>>;
};

export type MetadataFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  keywords_exists: Maybe<Scalars['Boolean']>;
  keywords_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  keywords_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  keywords_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_exists: Maybe<Scalars['Boolean']>;
  ogtype: Maybe<Scalars['String']>;
  ogtype_not: Maybe<Scalars['String']>;
  ogtype_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ogtype_contains: Maybe<Scalars['String']>;
  ogtype_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<MetadataFilter>>>;
  AND: Maybe<Array<Maybe<MetadataFilter>>>;
};

export type MetadataLinkingCollections = {
  __typename?: 'MetadataLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type MetadataLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum MetadataOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OgtypeAsc = 'ogtype_ASC',
  OgtypeDesc = 'ogtype_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Basic content type for a modal with some markdown [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/modal)
 */
export type Modal = Entry & _Node & {
  __typename?: 'Modal';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ModalLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  contentLabel: Maybe<Scalars['String']>;
  content: Maybe<Scalars['String']>;
};


/**
 * Basic content type for a modal with some markdown [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/modal)
 */
export type ModalLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Basic content type for a modal with some markdown [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/modal)
 */
export type ModalKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Basic content type for a modal with some markdown [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/modal)
 */
export type ModalContentLabelArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Basic content type for a modal with some markdown [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/modal)
 */
export type ModalContentArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ModalCollection = {
  __typename?: 'ModalCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Modal>>;
};

export type ModalFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  contentLabel_exists: Maybe<Scalars['Boolean']>;
  contentLabel: Maybe<Scalars['String']>;
  contentLabel_not: Maybe<Scalars['String']>;
  contentLabel_in: Maybe<Array<Maybe<Scalars['String']>>>;
  contentLabel_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  contentLabel_contains: Maybe<Scalars['String']>;
  contentLabel_not_contains: Maybe<Scalars['String']>;
  content_exists: Maybe<Scalars['Boolean']>;
  content: Maybe<Scalars['String']>;
  content_not: Maybe<Scalars['String']>;
  content_in: Maybe<Array<Maybe<Scalars['String']>>>;
  content_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  content_contains: Maybe<Scalars['String']>;
  content_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ModalFilter>>>;
  AND: Maybe<Array<Maybe<ModalFilter>>>;
};

export type ModalLinkingCollections = {
  __typename?: 'ModalLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ModalLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ModalOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ContentLabelAsc = 'contentLabel_ASC',
  ContentLabelDesc = 'contentLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Content type intended to be used for content-related configuration of concrete modules.
 * E.g. how many reviews to display on a product landing page. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/moduleConfiguration)
 */
export type ModuleConfiguration = Entry & _Node & {
  __typename?: 'ModuleConfiguration';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ModuleConfigurationLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  quantityOfItems: Maybe<Scalars['Int']>;
};


/**
 * Content type intended to be used for content-related configuration of concrete modules.
 * E.g. how many reviews to display on a product landing page. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/moduleConfiguration)
 */
export type ModuleConfigurationLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Content type intended to be used for content-related configuration of concrete modules.
 * E.g. how many reviews to display on a product landing page. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/moduleConfiguration)
 */
export type ModuleConfigurationKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Content type intended to be used for content-related configuration of concrete modules.
 * E.g. how many reviews to display on a product landing page. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/moduleConfiguration)
 */
export type ModuleConfigurationQuantityOfItemsArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ModuleConfigurationCollection = {
  __typename?: 'ModuleConfigurationCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ModuleConfiguration>>;
};

export type ModuleConfigurationFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  quantityOfItems_exists: Maybe<Scalars['Boolean']>;
  quantityOfItems: Maybe<Scalars['Int']>;
  quantityOfItems_not: Maybe<Scalars['Int']>;
  quantityOfItems_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  quantityOfItems_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  quantityOfItems_gt: Maybe<Scalars['Int']>;
  quantityOfItems_gte: Maybe<Scalars['Int']>;
  quantityOfItems_lt: Maybe<Scalars['Int']>;
  quantityOfItems_lte: Maybe<Scalars['Int']>;
  OR: Maybe<Array<Maybe<ModuleConfigurationFilter>>>;
  AND: Maybe<Array<Maybe<ModuleConfigurationFilter>>>;
};

export type ModuleConfigurationLinkingCollections = {
  __typename?: 'ModuleConfigurationLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ModuleConfigurationLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ModuleConfigurationOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  QuantityOfItemsAsc = 'quantityOfItems_ASC',
  QuantityOfItemsDesc = 'quantityOfItems_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type Navigation = Entry & _Node & {
  __typename?: 'Navigation';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<NavigationLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  subnavsCollection: Maybe<NavigationSubnavsCollection>;
  geoMenu: Maybe<ContentAggregate>;
  userMenu: Maybe<ContentAggregate>;
  panelNavLinks: Maybe<CtaWithUrlSet>;
  ariaLabel: Maybe<NavigationAriaLabel>;
  logoAriaLabel: Maybe<NavigationLogoAriaLabel>;
  hamburgerButtonLabel: Maybe<KeyValue>;
  cartButtonLabel: Maybe<KeyValue>;
  toggleLocator: Maybe<Scalars['Boolean']>;
  toggleAccount: Maybe<Scalars['Boolean']>;
  toggleCart: Maybe<Scalars['Boolean']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationSubnavsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<NavigationSubnavsFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationGeoMenuArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationUserMenuArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationPanelNavLinksArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlSetFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationAriaLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationLogoAriaLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationHamburgerButtonLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<KeyValueFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationCartButtonLabelArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<KeyValueFilter>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationToggleLocatorArgs = {
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationToggleAccountArgs = {
  locale: Maybe<Scalars['String']>;
};


/** www site nav [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/navigation) */
export type NavigationToggleCartArgs = {
  locale: Maybe<Scalars['String']>;
};

export type NavigationAriaLabel = KeyValue | PromotionalText;

export type NavigationCollection = {
  __typename?: 'NavigationCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Navigation>>;
};

export type NavigationFilter = {
  subnavs: Maybe<CfsubnavsMultiTypeNestedFilter>;
  geoMenu: Maybe<CfContentAggregateNestedFilter>;
  userMenu: Maybe<CfContentAggregateNestedFilter>;
  panelNavLinks: Maybe<CfCtaWithUrlSetNestedFilter>;
  hamburgerButtonLabel: Maybe<CfKeyValueNestedFilter>;
  cartButtonLabel: Maybe<CfKeyValueNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  subnavsCollection_exists: Maybe<Scalars['Boolean']>;
  geoMenu_exists: Maybe<Scalars['Boolean']>;
  userMenu_exists: Maybe<Scalars['Boolean']>;
  panelNavLinks_exists: Maybe<Scalars['Boolean']>;
  ariaLabel_exists: Maybe<Scalars['Boolean']>;
  logoAriaLabel_exists: Maybe<Scalars['Boolean']>;
  hamburgerButtonLabel_exists: Maybe<Scalars['Boolean']>;
  cartButtonLabel_exists: Maybe<Scalars['Boolean']>;
  toggleLocator_exists: Maybe<Scalars['Boolean']>;
  toggleLocator: Maybe<Scalars['Boolean']>;
  toggleLocator_not: Maybe<Scalars['Boolean']>;
  toggleAccount_exists: Maybe<Scalars['Boolean']>;
  toggleAccount: Maybe<Scalars['Boolean']>;
  toggleAccount_not: Maybe<Scalars['Boolean']>;
  toggleCart_exists: Maybe<Scalars['Boolean']>;
  toggleCart: Maybe<Scalars['Boolean']>;
  toggleCart_not: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<NavigationFilter>>>;
  AND: Maybe<Array<Maybe<NavigationFilter>>>;
};

export type NavigationLinkingCollections = {
  __typename?: 'NavigationLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  navigationCollection: Maybe<NavigationCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type NavigationLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type NavigationLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<NavigationLinkingCollectionsNavigationCollectionOrder>>>;
};


export type NavigationLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<NavigationLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum NavigationLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum NavigationLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type NavigationLogoAriaLabel = KeyValue | PromotionalText;

export enum NavigationOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type NavigationSubnavsCollection = {
  __typename?: 'NavigationSubnavsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<NavigationSubnavsItem>>;
};

export type NavigationSubnavsFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<NavigationSubnavsFilter>>>;
  AND: Maybe<Array<Maybe<NavigationSubnavsFilter>>>;
};

export type NavigationSubnavsItem = CtaWithUrlSet | Navigation | ProductNav;

/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookup = Entry & _Node & {
  __typename?: 'OtdLookup';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<OtdLookupLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  initial: Maybe<LongHeaderBodyFootnote>;
  ok: Maybe<LongHeaderBodyFootnote>;
  insufficientData: Maybe<LongHeaderBodyFootnote>;
  postalCodeCantSelfSchedule: Maybe<LongHeaderBodyFootnote>;
  error: Maybe<LongHeaderBodyFootnote>;
  staticOtd: Maybe<LongHeaderBodyFootnote>;
  invalidPostalCode: Maybe<LongHeaderBodyFootnote>;
  ineligiblePostalCode: Maybe<LongHeaderBodyFootnote>;
  disabled: Maybe<LongHeaderBodyFootnote>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupInitialArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupOkArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupInsufficientDataArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupPostalCodeCantSelfScheduleArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupErrorArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupStaticOtdArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupInvalidPostalCodeArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupIneligiblePostalCodeArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};


/**
 * Lookup Form that contains all calculation statuses (aka -- whether OX/MOCHI
 * service was able to compute the OTD estimate) and initial states for capture
 * form. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/otdLookup)
 */
export type OtdLookupDisabledArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
};

export type OtdLookupCollection = {
  __typename?: 'OtdLookupCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<OtdLookup>>;
};

export type OtdLookupFilter = {
  initial: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  ok: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  insufficientData: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  postalCodeCantSelfSchedule: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  error: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  staticOtd: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  invalidPostalCode: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  ineligiblePostalCode: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  disabled: Maybe<CfLongHeaderBodyFootnoteNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  initial_exists: Maybe<Scalars['Boolean']>;
  ok_exists: Maybe<Scalars['Boolean']>;
  insufficientData_exists: Maybe<Scalars['Boolean']>;
  postalCodeCantSelfSchedule_exists: Maybe<Scalars['Boolean']>;
  error_exists: Maybe<Scalars['Boolean']>;
  staticOtd_exists: Maybe<Scalars['Boolean']>;
  invalidPostalCode_exists: Maybe<Scalars['Boolean']>;
  ineligiblePostalCode_exists: Maybe<Scalars['Boolean']>;
  disabled_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<OtdLookupFilter>>>;
  AND: Maybe<Array<Maybe<OtdLookupFilter>>>;
};

export type OtdLookupLinkingCollections = {
  __typename?: 'OtdLookupLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type OtdLookupLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum OtdLookupOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A mapping of package names to various slug names used in various locales for
 * redirection via GeoModal [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/packageToSlugMapping)
 */
export type PackageToSlugMapping = Entry & _Node & {
  __typename?: 'PackageToSlugMapping';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PackageToSlugMappingLinkingCollections>;
  _id: Scalars['ID'];
  packageNameToSlug: Maybe<Scalars['JSON']>;
};


/**
 * A mapping of package names to various slug names used in various locales for
 * redirection via GeoModal [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/packageToSlugMapping)
 */
export type PackageToSlugMappingLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A mapping of package names to various slug names used in various locales for
 * redirection via GeoModal [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/packageToSlugMapping)
 */
export type PackageToSlugMappingPackageNameToSlugArgs = {
  locale: Maybe<Scalars['String']>;
};

export type PackageToSlugMappingCollection = {
  __typename?: 'PackageToSlugMappingCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PackageToSlugMapping>>;
};

export type PackageToSlugMappingFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  packageNameToSlug_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<PackageToSlugMappingFilter>>>;
  AND: Maybe<Array<Maybe<PackageToSlugMappingFilter>>>;
};

export type PackageToSlugMappingLinkingCollections = {
  __typename?: 'PackageToSlugMappingLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type PackageToSlugMappingLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum PackageToSlugMappingOrder {
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuote = Entry & _Node & {
  __typename?: 'PressQuote';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PressQuoteLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  logo: Maybe<Asset>;
  quote: Maybe<Scalars['String']>;
  publication: Maybe<Scalars['String']>;
};


/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuoteLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuoteKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuoteLogoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuoteQuoteArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A quote with an image, quote text, byline [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/pressQuote) */
export type PressQuotePublicationArgs = {
  locale: Maybe<Scalars['String']>;
};

export type PressQuoteCollection = {
  __typename?: 'PressQuoteCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PressQuote>>;
};

export type PressQuoteFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  logo_exists: Maybe<Scalars['Boolean']>;
  quote_exists: Maybe<Scalars['Boolean']>;
  quote: Maybe<Scalars['String']>;
  quote_not: Maybe<Scalars['String']>;
  quote_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  quote_contains: Maybe<Scalars['String']>;
  quote_not_contains: Maybe<Scalars['String']>;
  publication_exists: Maybe<Scalars['Boolean']>;
  publication: Maybe<Scalars['String']>;
  publication_not: Maybe<Scalars['String']>;
  publication_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  publication_contains: Maybe<Scalars['String']>;
  publication_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<PressQuoteFilter>>>;
  AND: Maybe<Array<Maybe<PressQuoteFilter>>>;
};

export type PressQuoteLinkingCollections = {
  __typename?: 'PressQuoteLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  quoteBannerCollection: Maybe<QuoteBannerCollection>;
};


export type PressQuoteLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type PressQuoteLinkingCollectionsQuoteBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PressQuoteLinkingCollectionsQuoteBannerCollectionOrder>>>;
};

export enum PressQuoteLinkingCollectionsQuoteBannerCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PressQuoteOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  QuoteAsc = 'quote_ASC',
  QuoteDesc = 'quote_DESC',
  PublicationAsc = 'publication_ASC',
  PublicationDesc = 'publication_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type Product = Entry & _Node & {
  __typename?: 'Product';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  slug: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  imageCollection: Maybe<AssetCollection>;
  badge: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductSlugArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** A representation of a product for display purposes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/product) */
export type ProductBadgeArgs = {
  locale: Maybe<Scalars['String']>;
};

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlock = Entry & _Node & {
  __typename?: 'ProductBlock';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductBlockLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  eyebrow: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  subhead: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  ctaWithUrl: Maybe<CtaWithUrl>;
  featuredProduct: Maybe<Scalars['String']>;
  financingStyle: Maybe<Scalars['String']>;
  subCtaText: Maybe<Scalars['String']>;
  textBackground: Maybe<Image>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockSubheadArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockCtaWithUrlArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockFeaturedProductArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockFinancingStyleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockSubCtaTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlock) */
export type ProductBlockTextBackgroundArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};

export type ProductBlockCollection = {
  __typename?: 'ProductBlockCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductBlock>>;
};

export type ProductBlockFilter = {
  image: Maybe<CfImageNestedFilter>;
  ctaWithUrl: Maybe<CfCtaWithUrlNestedFilter>;
  textBackground: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  subhead_exists: Maybe<Scalars['Boolean']>;
  subhead: Maybe<Scalars['String']>;
  subhead_not: Maybe<Scalars['String']>;
  subhead_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_contains: Maybe<Scalars['String']>;
  subhead_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  ctaWithUrl_exists: Maybe<Scalars['Boolean']>;
  featuredProduct_exists: Maybe<Scalars['Boolean']>;
  featuredProduct: Maybe<Scalars['String']>;
  featuredProduct_not: Maybe<Scalars['String']>;
  featuredProduct_in: Maybe<Array<Maybe<Scalars['String']>>>;
  featuredProduct_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  featuredProduct_contains: Maybe<Scalars['String']>;
  featuredProduct_not_contains: Maybe<Scalars['String']>;
  financingStyle_exists: Maybe<Scalars['Boolean']>;
  financingStyle: Maybe<Scalars['String']>;
  financingStyle_not: Maybe<Scalars['String']>;
  financingStyle_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStyle_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingStyle_contains: Maybe<Scalars['String']>;
  financingStyle_not_contains: Maybe<Scalars['String']>;
  subCtaText_exists: Maybe<Scalars['Boolean']>;
  subCtaText: Maybe<Scalars['String']>;
  subCtaText_not: Maybe<Scalars['String']>;
  subCtaText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subCtaText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subCtaText_contains: Maybe<Scalars['String']>;
  subCtaText_not_contains: Maybe<Scalars['String']>;
  textBackground_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ProductBlockFilter>>>;
  AND: Maybe<Array<Maybe<ProductBlockFilter>>>;
};

export type ProductBlockLinkingCollections = {
  __typename?: 'ProductBlockLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  productBlockSetCollection: Maybe<ProductBlockSetCollection>;
};


export type ProductBlockLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductBlockLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductBlockLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type ProductBlockLinkingCollectionsProductBlockSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductBlockLinkingCollectionsProductBlockSetCollectionOrder>>>;
};

export enum ProductBlockLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductBlockLinkingCollectionsProductBlockSetCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductBlockOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  FeaturedProductAsc = 'featuredProduct_ASC',
  FeaturedProductDesc = 'featuredProduct_DESC',
  FinancingStyleAsc = 'financingStyle_ASC',
  FinancingStyleDesc = 'financingStyle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A collection of product blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlockSet) */
export type ProductBlockSet = Entry & _Node & {
  __typename?: 'ProductBlockSet';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductBlockSetLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  productBlocksCollection: Maybe<ProductBlockSetProductBlocksCollection>;
};


/** A collection of product blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlockSet) */
export type ProductBlockSetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A collection of product blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlockSet) */
export type ProductBlockSetKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A collection of product blocks [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productBlockSet) */
export type ProductBlockSetProductBlocksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductBlockFilter>;
  order: Maybe<Array<Maybe<ProductBlockSetProductBlocksCollectionOrder>>>;
};

export type ProductBlockSetCollection = {
  __typename?: 'ProductBlockSetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductBlockSet>>;
};

export type ProductBlockSetFilter = {
  productBlocks: Maybe<CfProductBlockNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  productBlocksCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ProductBlockSetFilter>>>;
  AND: Maybe<Array<Maybe<ProductBlockSetFilter>>>;
};

export type ProductBlockSetLinkingCollections = {
  __typename?: 'ProductBlockSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  promoCollection: Maybe<PromoCollection>;
};


export type ProductBlockSetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductBlockSetLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductBlockSetLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type ProductBlockSetLinkingCollectionsPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductBlockSetLinkingCollectionsPromoCollectionOrder>>>;
};

export enum ProductBlockSetLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductBlockSetLinkingCollectionsPromoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductBlockSetOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProductBlockSetProductBlocksCollection = {
  __typename?: 'ProductBlockSetProductBlocksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductBlock>>;
};

export enum ProductBlockSetProductBlocksCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  FeaturedProductAsc = 'featuredProduct_ASC',
  FeaturedProductDesc = 'featuredProduct_DESC',
  FinancingStyleAsc = 'financingStyle_ASC',
  FinancingStyleDesc = 'financingStyle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProductCollection = {
  __typename?: 'ProductCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Product>>;
};

/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCard = Entry & _Node & {
  __typename?: 'ProductDetailsCard';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductDetailsCardLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  image: Maybe<Asset>;
  productName: Maybe<Scalars['String']>;
  description: Maybe<Scalars['String']>;
  device: Maybe<Scalars['String']>;
  featureLists: Maybe<MarkdownPage>;
  primaryCta: Maybe<CtaWithUrl>;
  secondaryCta: Maybe<CtaWithUrl>;
  ribbonText: Maybe<Scalars['String']>;
  promoBadgeText: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardProductNameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardDeviceArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardFeatureListsArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<MarkdownPageFilter>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardPrimaryCtaArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardSecondaryCtaArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardRibbonTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Product cards used on our overview pages and comparison pages/modals [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productDetailsCard) */
export type ProductDetailsCardPromoBadgeTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ProductDetailsCardCollection = {
  __typename?: 'ProductDetailsCardCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductDetailsCard>>;
};

export type ProductDetailsCardFilter = {
  featureLists: Maybe<CfMarkdownPageNestedFilter>;
  primaryCta: Maybe<CfCtaWithUrlNestedFilter>;
  secondaryCta: Maybe<CfCtaWithUrlNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  productName_exists: Maybe<Scalars['Boolean']>;
  productName: Maybe<Scalars['String']>;
  productName_not: Maybe<Scalars['String']>;
  productName_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productName_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productName_contains: Maybe<Scalars['String']>;
  productName_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  device_exists: Maybe<Scalars['Boolean']>;
  device: Maybe<Scalars['String']>;
  device_not: Maybe<Scalars['String']>;
  device_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  device_contains: Maybe<Scalars['String']>;
  device_not_contains: Maybe<Scalars['String']>;
  featureLists_exists: Maybe<Scalars['Boolean']>;
  primaryCta_exists: Maybe<Scalars['Boolean']>;
  secondaryCta_exists: Maybe<Scalars['Boolean']>;
  ribbonText_exists: Maybe<Scalars['Boolean']>;
  ribbonText: Maybe<Scalars['String']>;
  ribbonText_not: Maybe<Scalars['String']>;
  ribbonText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ribbonText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  ribbonText_contains: Maybe<Scalars['String']>;
  ribbonText_not_contains: Maybe<Scalars['String']>;
  promoBadgeText_exists: Maybe<Scalars['Boolean']>;
  promoBadgeText: Maybe<Scalars['String']>;
  promoBadgeText_not: Maybe<Scalars['String']>;
  promoBadgeText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_contains: Maybe<Scalars['String']>;
  promoBadgeText_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ProductDetailsCardFilter>>>;
  AND: Maybe<Array<Maybe<ProductDetailsCardFilter>>>;
};

export type ProductDetailsCardLinkingCollections = {
  __typename?: 'ProductDetailsCardLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productNavCollection: Maybe<ProductNavCollection>;
};


export type ProductDetailsCardLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductDetailsCardLinkingCollectionsProductNavCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductDetailsCardLinkingCollectionsProductNavCollectionOrder>>>;
};

export enum ProductDetailsCardLinkingCollectionsProductNavCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductDetailsCardOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductNameAsc = 'productName_ASC',
  ProductNameDesc = 'productName_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  RibbonTextAsc = 'ribbonText_ASC',
  RibbonTextDesc = 'ribbonText_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProductFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  slug_exists: Maybe<Scalars['Boolean']>;
  slug: Maybe<Scalars['String']>;
  slug_not: Maybe<Scalars['String']>;
  slug_in: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  slug_contains: Maybe<Scalars['String']>;
  slug_not_contains: Maybe<Scalars['String']>;
  name_exists: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  name_not: Maybe<Scalars['String']>;
  name_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  name_contains: Maybe<Scalars['String']>;
  name_not_contains: Maybe<Scalars['String']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  imageCollection_exists: Maybe<Scalars['Boolean']>;
  badge_exists: Maybe<Scalars['Boolean']>;
  badge: Maybe<Scalars['String']>;
  badge_not: Maybe<Scalars['String']>;
  badge_in: Maybe<Array<Maybe<Scalars['String']>>>;
  badge_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  badge_contains: Maybe<Scalars['String']>;
  badge_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ProductFilter>>>;
  AND: Maybe<Array<Maybe<ProductFilter>>>;
};

export type ProductLinkingCollections = {
  __typename?: 'ProductLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type ProductLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type ProductLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum ProductLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNav = Entry & _Node & {
  __typename?: 'ProductNav';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductNavLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  productsCollection: Maybe<ProductNavProductsCollection>;
  secondaryLinks: Maybe<CtaWithUrlSet>;
  promoBadgeText: Maybe<Scalars['String']>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavProductsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductDetailsCardFilter>;
  order: Maybe<Array<Maybe<ProductNavProductsCollectionOrder>>>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavSecondaryLinksArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlSetFilter>;
};


/** A product details card and a set of links related to that product [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productNav) */
export type ProductNavPromoBadgeTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ProductNavCollection = {
  __typename?: 'ProductNavCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductNav>>;
};

export type ProductNavFilter = {
  products: Maybe<CfProductDetailsCardNestedFilter>;
  secondaryLinks: Maybe<CfCtaWithUrlSetNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  productsCollection_exists: Maybe<Scalars['Boolean']>;
  secondaryLinks_exists: Maybe<Scalars['Boolean']>;
  promoBadgeText_exists: Maybe<Scalars['Boolean']>;
  promoBadgeText: Maybe<Scalars['String']>;
  promoBadgeText_not: Maybe<Scalars['String']>;
  promoBadgeText_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  promoBadgeText_contains: Maybe<Scalars['String']>;
  promoBadgeText_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ProductNavFilter>>>;
  AND: Maybe<Array<Maybe<ProductNavFilter>>>;
};

export type ProductNavLinkingCollections = {
  __typename?: 'ProductNavLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  navigationCollection: Maybe<NavigationCollection>;
};


export type ProductNavLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductNavLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductNavLinkingCollectionsNavigationCollectionOrder>>>;
};

export enum ProductNavLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductNavOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProductNavProductsCollection = {
  __typename?: 'ProductNavProductsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductDetailsCard>>;
};

export enum ProductNavProductsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductNameAsc = 'productName_ASC',
  ProductNameDesc = 'productName_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  RibbonTextAsc = 'ribbonText_ASC',
  RibbonTextDesc = 'ribbonText_DESC',
  PromoBadgeTextAsc = 'promoBadgeText_ASC',
  PromoBadgeTextDesc = 'promoBadgeText_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  BadgeAsc = 'badge_ASC',
  BadgeDesc = 'badge_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohort = Entry & _Node & {
  __typename?: 'ProductRecommendationsCohort';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductRecommendationsCohortLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  headline: Maybe<Scalars['String']>;
  subHeadline: Maybe<Scalars['String']>;
  productsCollection: Maybe<ProductRecommendationsCohortProductsCollection>;
  position: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  eyebrow: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  subtitle: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortHeadlineArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortSubHeadlineArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortProductsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductRecommendationsTileFilter>;
  order: Maybe<Array<Maybe<ProductRecommendationsCohortProductsCollectionOrder>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortPositionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsCohort) */
export type ProductRecommendationsCohortSubtitleArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ProductRecommendationsCohortCollection = {
  __typename?: 'ProductRecommendationsCohortCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductRecommendationsCohort>>;
};

export type ProductRecommendationsCohortFilter = {
  products: Maybe<CfProductRecommendationsTileNestedFilter>;
  image: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  headline_exists: Maybe<Scalars['Boolean']>;
  headline: Maybe<Scalars['String']>;
  headline_not: Maybe<Scalars['String']>;
  headline_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  headline_contains: Maybe<Scalars['String']>;
  headline_not_contains: Maybe<Scalars['String']>;
  subHeadline_exists: Maybe<Scalars['Boolean']>;
  subHeadline: Maybe<Scalars['String']>;
  subHeadline_not: Maybe<Scalars['String']>;
  subHeadline_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subHeadline_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subHeadline_contains: Maybe<Scalars['String']>;
  subHeadline_not_contains: Maybe<Scalars['String']>;
  productsCollection_exists: Maybe<Scalars['Boolean']>;
  position_exists: Maybe<Scalars['Boolean']>;
  position: Maybe<Scalars['String']>;
  position_not: Maybe<Scalars['String']>;
  position_in: Maybe<Array<Maybe<Scalars['String']>>>;
  position_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  position_contains: Maybe<Scalars['String']>;
  position_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  subtitle_exists: Maybe<Scalars['Boolean']>;
  subtitle: Maybe<Scalars['String']>;
  subtitle_not: Maybe<Scalars['String']>;
  subtitle_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subtitle_contains: Maybe<Scalars['String']>;
  subtitle_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ProductRecommendationsCohortFilter>>>;
  AND: Maybe<Array<Maybe<ProductRecommendationsCohortFilter>>>;
};

export type ProductRecommendationsCohortLinkingCollections = {
  __typename?: 'ProductRecommendationsCohortLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type ProductRecommendationsCohortLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductRecommendationsCohortLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductRecommendationsCohortLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum ProductRecommendationsCohortLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductRecommendationsCohortOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubHeadlineAsc = 'subHeadline_ASC',
  SubHeadlineDesc = 'subHeadline_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ProductRecommendationsCohortProductsCollection = {
  __typename?: 'ProductRecommendationsCohortProductsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductRecommendationsTile>>;
};

export enum ProductRecommendationsCohortProductsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductSlugAsc = 'productSlug_ASC',
  ProductSlugDesc = 'productSlug_DESC',
  FactoidAsc = 'factoid_ASC',
  FactoidDesc = 'factoid_DESC',
  PillAsc = 'pill_ASC',
  PillDesc = 'pill_DESC',
  PillThemeAsc = 'pillTheme_ASC',
  PillThemeDesc = 'pillTheme_DESC',
  PriceDisplayAsc = 'priceDisplay_ASC',
  PriceDisplayDesc = 'priceDisplay_DESC',
  FactoidEyebrowAsc = 'factoidEyebrow_ASC',
  FactoidEyebrowDesc = 'factoidEyebrow_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTile = Entry & _Node & {
  __typename?: 'ProductRecommendationsTile';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProductRecommendationsTileLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  productSlug: Maybe<Scalars['String']>;
  factoid: Maybe<Scalars['String']>;
  pill: Maybe<Scalars['String']>;
  factoidIcon: Maybe<Asset>;
  description: Maybe<Scalars['String']>;
  pillTheme: Maybe<Scalars['String']>;
  priceDisplay: Maybe<Scalars['String']>;
  factoidEyebrow: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileProductSlugArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileFactoidArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTilePillArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileFactoidIconArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileDescriptionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTilePillThemeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTilePriceDisplayArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/productRecommendationsTile) */
export type ProductRecommendationsTileFactoidEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ProductRecommendationsTileCollection = {
  __typename?: 'ProductRecommendationsTileCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ProductRecommendationsTile>>;
};

export type ProductRecommendationsTileFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  productSlug_exists: Maybe<Scalars['Boolean']>;
  productSlug: Maybe<Scalars['String']>;
  productSlug_not: Maybe<Scalars['String']>;
  productSlug_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productSlug_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  productSlug_contains: Maybe<Scalars['String']>;
  productSlug_not_contains: Maybe<Scalars['String']>;
  factoid_exists: Maybe<Scalars['Boolean']>;
  factoid: Maybe<Scalars['String']>;
  factoid_not: Maybe<Scalars['String']>;
  factoid_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoid_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoid_contains: Maybe<Scalars['String']>;
  factoid_not_contains: Maybe<Scalars['String']>;
  pill_exists: Maybe<Scalars['Boolean']>;
  pill: Maybe<Scalars['String']>;
  pill_not: Maybe<Scalars['String']>;
  pill_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pill_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pill_contains: Maybe<Scalars['String']>;
  pill_not_contains: Maybe<Scalars['String']>;
  factoidIcon_exists: Maybe<Scalars['Boolean']>;
  description_exists: Maybe<Scalars['Boolean']>;
  description: Maybe<Scalars['String']>;
  description_not: Maybe<Scalars['String']>;
  description_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  description_contains: Maybe<Scalars['String']>;
  description_not_contains: Maybe<Scalars['String']>;
  pillTheme_exists: Maybe<Scalars['Boolean']>;
  pillTheme: Maybe<Scalars['String']>;
  pillTheme_not: Maybe<Scalars['String']>;
  pillTheme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pillTheme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  pillTheme_contains: Maybe<Scalars['String']>;
  pillTheme_not_contains: Maybe<Scalars['String']>;
  priceDisplay_exists: Maybe<Scalars['Boolean']>;
  priceDisplay: Maybe<Scalars['String']>;
  priceDisplay_not: Maybe<Scalars['String']>;
  priceDisplay_in: Maybe<Array<Maybe<Scalars['String']>>>;
  priceDisplay_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  priceDisplay_contains: Maybe<Scalars['String']>;
  priceDisplay_not_contains: Maybe<Scalars['String']>;
  factoidEyebrow_exists: Maybe<Scalars['Boolean']>;
  factoidEyebrow: Maybe<Scalars['String']>;
  factoidEyebrow_not: Maybe<Scalars['String']>;
  factoidEyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoidEyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  factoidEyebrow_contains: Maybe<Scalars['String']>;
  factoidEyebrow_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ProductRecommendationsTileFilter>>>;
  AND: Maybe<Array<Maybe<ProductRecommendationsTileFilter>>>;
};

export type ProductRecommendationsTileLinkingCollections = {
  __typename?: 'ProductRecommendationsTileLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  productRecommendationsCohortCollection: Maybe<ProductRecommendationsCohortCollection>;
};


export type ProductRecommendationsTileLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ProductRecommendationsTileLinkingCollectionsProductRecommendationsCohortCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ProductRecommendationsTileLinkingCollectionsProductRecommendationsCohortCollectionOrder>>>;
};

export enum ProductRecommendationsTileLinkingCollectionsProductRecommendationsCohortCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeadlineAsc = 'headline_ASC',
  HeadlineDesc = 'headline_DESC',
  SubHeadlineAsc = 'subHeadline_ASC',
  SubHeadlineDesc = 'subHeadline_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SubtitleAsc = 'subtitle_ASC',
  SubtitleDesc = 'subtitle_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ProductRecommendationsTileOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ProductSlugAsc = 'productSlug_ASC',
  ProductSlugDesc = 'productSlug_DESC',
  FactoidAsc = 'factoid_ASC',
  FactoidDesc = 'factoid_DESC',
  PillAsc = 'pill_ASC',
  PillDesc = 'pill_DESC',
  PillThemeAsc = 'pillTheme_ASC',
  PillThemeDesc = 'pillTheme_DESC',
  PriceDisplayAsc = 'priceDisplay_ASC',
  PriceDisplayDesc = 'priceDisplay_DESC',
  FactoidEyebrowAsc = 'factoidEyebrow_ASC',
  FactoidEyebrowDesc = 'factoidEyebrow_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type Programs = Entry & _Node & {
  __typename?: 'Programs';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ProgramsLinkingCollections>;
  _id: Scalars['ID'];
  app: Maybe<Scalars['String']>;
  financingPartner: Maybe<Scalars['String']>;
  secondaryFinancingPartner: Maybe<Scalars['String']>;
  showrooms: Maybe<Scalars['String']>;
  virtualShowrooms: Maybe<Scalars['Boolean']>;
  homeTrial: Maybe<Scalars['Boolean']>;
  valueCalculator: Maybe<Scalars['Boolean']>;
  apparel: Maybe<Scalars['Boolean']>;
  tradeIn: Maybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsAppArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsFinancingPartnerArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsSecondaryFinancingPartnerArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsShowroomsArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsVirtualShowroomsArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsHomeTrialArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsValueCalculatorArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsApparelArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/programs) */
export type ProgramsTradeInArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ProgramsCollection = {
  __typename?: 'ProgramsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Programs>>;
};

export type ProgramsFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  app_exists: Maybe<Scalars['Boolean']>;
  app: Maybe<Scalars['String']>;
  app_not: Maybe<Scalars['String']>;
  app_in: Maybe<Array<Maybe<Scalars['String']>>>;
  app_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  app_contains: Maybe<Scalars['String']>;
  app_not_contains: Maybe<Scalars['String']>;
  financingPartner_exists: Maybe<Scalars['Boolean']>;
  financingPartner: Maybe<Scalars['String']>;
  financingPartner_not: Maybe<Scalars['String']>;
  financingPartner_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingPartner_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  financingPartner_contains: Maybe<Scalars['String']>;
  financingPartner_not_contains: Maybe<Scalars['String']>;
  secondaryFinancingPartner_exists: Maybe<Scalars['Boolean']>;
  secondaryFinancingPartner: Maybe<Scalars['String']>;
  secondaryFinancingPartner_not: Maybe<Scalars['String']>;
  secondaryFinancingPartner_in: Maybe<Array<Maybe<Scalars['String']>>>;
  secondaryFinancingPartner_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  secondaryFinancingPartner_contains: Maybe<Scalars['String']>;
  secondaryFinancingPartner_not_contains: Maybe<Scalars['String']>;
  showrooms_exists: Maybe<Scalars['Boolean']>;
  showrooms: Maybe<Scalars['String']>;
  showrooms_not: Maybe<Scalars['String']>;
  showrooms_in: Maybe<Array<Maybe<Scalars['String']>>>;
  showrooms_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  showrooms_contains: Maybe<Scalars['String']>;
  showrooms_not_contains: Maybe<Scalars['String']>;
  virtualShowrooms_exists: Maybe<Scalars['Boolean']>;
  virtualShowrooms: Maybe<Scalars['Boolean']>;
  virtualShowrooms_not: Maybe<Scalars['Boolean']>;
  homeTrial_exists: Maybe<Scalars['Boolean']>;
  homeTrial: Maybe<Scalars['Boolean']>;
  homeTrial_not: Maybe<Scalars['Boolean']>;
  valueCalculator_exists: Maybe<Scalars['Boolean']>;
  valueCalculator: Maybe<Scalars['Boolean']>;
  valueCalculator_not: Maybe<Scalars['Boolean']>;
  apparel_exists: Maybe<Scalars['Boolean']>;
  apparel: Maybe<Scalars['Boolean']>;
  apparel_not: Maybe<Scalars['Boolean']>;
  tradeIn_exists: Maybe<Scalars['Boolean']>;
  tradeIn: Maybe<Scalars['Boolean']>;
  tradeIn_not: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ProgramsFilter>>>;
  AND: Maybe<Array<Maybe<ProgramsFilter>>>;
};

export type ProgramsLinkingCollections = {
  __typename?: 'ProgramsLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ProgramsLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ProgramsOrder {
  AppAsc = 'app_ASC',
  AppDesc = 'app_DESC',
  FinancingPartnerAsc = 'financingPartner_ASC',
  FinancingPartnerDesc = 'financingPartner_DESC',
  SecondaryFinancingPartnerAsc = 'secondaryFinancingPartner_ASC',
  SecondaryFinancingPartnerDesc = 'secondaryFinancingPartner_DESC',
  ShowroomsAsc = 'showrooms_ASC',
  ShowroomsDesc = 'showrooms_DESC',
  VirtualShowroomsAsc = 'virtualShowrooms_ASC',
  VirtualShowroomsDesc = 'virtualShowrooms_DESC',
  HomeTrialAsc = 'homeTrial_ASC',
  HomeTrialDesc = 'homeTrial_DESC',
  ValueCalculatorAsc = 'valueCalculator_ASC',
  ValueCalculatorDesc = 'valueCalculator_DESC',
  ApparelAsc = 'apparel_ASC',
  ApparelDesc = 'apparel_DESC',
  TradeInAsc = 'tradeIn_ASC',
  TradeInDesc = 'tradeIn_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type Promo = Entry & _Node & {
  __typename?: 'Promo';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PromoLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  validLocales: Maybe<Array<Maybe<Scalars['String']>>>;
  start: Maybe<Scalars['DateTime']>;
  end: Maybe<Scalars['DateTime']>;
  generalBanner: Maybe<PromoBanner>;
  bikesBanner: Maybe<PromoBanner>;
  bikeBanner: Maybe<PromoBanner>;
  bikePlusBanner: Maybe<PromoBanner>;
  treadsBanner: Maybe<PromoBanner>;
  guideBanner: Maybe<PromoBanner>;
  shopGuideBanner: Maybe<PromoBanner>;
  rowBanner: Maybe<PromoBanner>;
  shopRowBanner: Maybe<PromoBanner>;
  treadBanner: Maybe<PromoBanner>;
  treadPlusBanner: Maybe<PromoBanner>;
  appBanner: Maybe<PromoBanner>;
  appCheckoutBanner: Maybe<PromoBanner>;
  shopBikeBanner: Maybe<PromoBanner>;
  shopBikePlusBanner: Maybe<PromoBanner>;
  shopTreadBanner: Maybe<PromoBanner>;
  shopTreadPlusBanner: Maybe<PromoBanner>;
  homepageHero: Maybe<PromoHeroSet>;
  homepageProductBlocks: Maybe<ProductBlockSet>;
  legalText: Maybe<LegalText>;
  globalFinancingBanner: Maybe<PromoBanner>;
  multiPromoBannerCollection: Maybe<PromoMultiPromoBannerCollection>;
  checkoutBanner: Maybe<PromoBanner>;
  chaseBanner: Maybe<PromoBanner>;
  urgencyBanner: Maybe<ContentAggregate>;
  promoRibbons: Maybe<ContentAggregate>;
  promoModules: Maybe<ContentAggregate>;
  productBlocksCollection: Maybe<PromoProductBlocksCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoValidLocalesArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoStartArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoEndArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoGeneralBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoBikesBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoBikeBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoBikePlusBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoTreadsBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoGuideBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopGuideBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoRowBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopRowBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoTreadBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoTreadPlusBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoAppBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoAppCheckoutBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopBikeBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopBikePlusBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopTreadBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoShopTreadPlusBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoHomepageHeroArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoHeroSetFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoHomepageProductBlocksArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductBlockSetFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoLegalTextArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LegalTextFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoGlobalFinancingBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoMultiPromoBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
  order: Maybe<Array<Maybe<PromoMultiPromoBannerCollectionOrder>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoCheckoutBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoChaseBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoUrgencyBannerArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoPromoRibbonsArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoPromoModulesArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promo) */
export type PromoProductBlocksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
  order: Maybe<Array<Maybe<PromoProductBlocksCollectionOrder>>>;
};

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBanner = Entry & _Node & {
  __typename?: 'PromoBanner';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PromoBannerLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
  theme: Maybe<Scalars['String']>;
  linksCollection: Maybe<PromoBannerLinksCollection>;
  hide: Maybe<Scalars['Boolean']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerTextArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerThemeArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerLinksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerLinksFilter>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoBanner) */
export type PromoBannerHideArgs = {
  locale: Maybe<Scalars['String']>;
};

export type PromoBannerCollection = {
  __typename?: 'PromoBannerCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoBanner>>;
};

export type PromoBannerFilter = {
  links: Maybe<CflinksMultiTypeNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  theme_exists: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  theme_not: Maybe<Scalars['String']>;
  theme_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  theme_contains: Maybe<Scalars['String']>;
  theme_not_contains: Maybe<Scalars['String']>;
  linksCollection_exists: Maybe<Scalars['Boolean']>;
  hide_exists: Maybe<Scalars['Boolean']>;
  hide: Maybe<Scalars['Boolean']>;
  hide_not: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<PromoBannerFilter>>>;
  AND: Maybe<Array<Maybe<PromoBannerFilter>>>;
};

export type PromoBannerLinkingCollections = {
  __typename?: 'PromoBannerLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  promoCollection: Maybe<PromoCollection>;
};


export type PromoBannerLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type PromoBannerLinkingCollectionsPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromoBannerLinkingCollectionsPromoCollectionOrder>>>;
};

export enum PromoBannerLinkingCollectionsPromoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoBannerLinksCollection = {
  __typename?: 'PromoBannerLinksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoBannerLinksItem>>;
};

export type PromoBannerLinksFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<PromoBannerLinksFilter>>>;
  AND: Maybe<Array<Maybe<PromoBannerLinksFilter>>>;
};

export type PromoBannerLinksItem = CtaWithDriftInteraction | CtaWithUrl;

export enum PromoBannerOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  HideAsc = 'hide_ASC',
  HideDesc = 'hide_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoCollection = {
  __typename?: 'PromoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Promo>>;
};

export type PromoFilter = {
  generalBanner: Maybe<CfPromoBannerNestedFilter>;
  bikesBanner: Maybe<CfPromoBannerNestedFilter>;
  bikeBanner: Maybe<CfPromoBannerNestedFilter>;
  bikePlusBanner: Maybe<CfPromoBannerNestedFilter>;
  treadsBanner: Maybe<CfPromoBannerNestedFilter>;
  guideBanner: Maybe<CfPromoBannerNestedFilter>;
  shopGuideBanner: Maybe<CfPromoBannerNestedFilter>;
  rowBanner: Maybe<CfPromoBannerNestedFilter>;
  shopRowBanner: Maybe<CfPromoBannerNestedFilter>;
  treadBanner: Maybe<CfPromoBannerNestedFilter>;
  treadPlusBanner: Maybe<CfPromoBannerNestedFilter>;
  appBanner: Maybe<CfPromoBannerNestedFilter>;
  appCheckoutBanner: Maybe<CfPromoBannerNestedFilter>;
  shopBikeBanner: Maybe<CfPromoBannerNestedFilter>;
  shopBikePlusBanner: Maybe<CfPromoBannerNestedFilter>;
  shopTreadBanner: Maybe<CfPromoBannerNestedFilter>;
  shopTreadPlusBanner: Maybe<CfPromoBannerNestedFilter>;
  homepageHero: Maybe<CfPromoHeroSetNestedFilter>;
  homepageProductBlocks: Maybe<CfProductBlockSetNestedFilter>;
  legalText: Maybe<CfLegalTextNestedFilter>;
  globalFinancingBanner: Maybe<CfPromoBannerNestedFilter>;
  multiPromoBanner: Maybe<CfPromoBannerNestedFilter>;
  checkoutBanner: Maybe<CfPromoBannerNestedFilter>;
  chaseBanner: Maybe<CfPromoBannerNestedFilter>;
  urgencyBanner: Maybe<CfContentAggregateNestedFilter>;
  promoRibbons: Maybe<CfContentAggregateNestedFilter>;
  promoModules: Maybe<CfContentAggregateNestedFilter>;
  productBlocks: Maybe<CfContentAggregateNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  validLocales_exists: Maybe<Scalars['Boolean']>;
  validLocales_contains_all: Maybe<Array<Maybe<Scalars['String']>>>;
  validLocales_contains_some: Maybe<Array<Maybe<Scalars['String']>>>;
  validLocales_contains_none: Maybe<Array<Maybe<Scalars['String']>>>;
  start_exists: Maybe<Scalars['Boolean']>;
  start: Maybe<Scalars['DateTime']>;
  start_not: Maybe<Scalars['DateTime']>;
  start_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  start_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  start_gt: Maybe<Scalars['DateTime']>;
  start_gte: Maybe<Scalars['DateTime']>;
  start_lt: Maybe<Scalars['DateTime']>;
  start_lte: Maybe<Scalars['DateTime']>;
  end_exists: Maybe<Scalars['Boolean']>;
  end: Maybe<Scalars['DateTime']>;
  end_not: Maybe<Scalars['DateTime']>;
  end_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  end_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  end_gt: Maybe<Scalars['DateTime']>;
  end_gte: Maybe<Scalars['DateTime']>;
  end_lt: Maybe<Scalars['DateTime']>;
  end_lte: Maybe<Scalars['DateTime']>;
  generalBanner_exists: Maybe<Scalars['Boolean']>;
  bikesBanner_exists: Maybe<Scalars['Boolean']>;
  bikeBanner_exists: Maybe<Scalars['Boolean']>;
  bikePlusBanner_exists: Maybe<Scalars['Boolean']>;
  treadsBanner_exists: Maybe<Scalars['Boolean']>;
  guideBanner_exists: Maybe<Scalars['Boolean']>;
  shopGuideBanner_exists: Maybe<Scalars['Boolean']>;
  rowBanner_exists: Maybe<Scalars['Boolean']>;
  shopRowBanner_exists: Maybe<Scalars['Boolean']>;
  treadBanner_exists: Maybe<Scalars['Boolean']>;
  treadPlusBanner_exists: Maybe<Scalars['Boolean']>;
  appBanner_exists: Maybe<Scalars['Boolean']>;
  appCheckoutBanner_exists: Maybe<Scalars['Boolean']>;
  shopBikeBanner_exists: Maybe<Scalars['Boolean']>;
  shopBikePlusBanner_exists: Maybe<Scalars['Boolean']>;
  shopTreadBanner_exists: Maybe<Scalars['Boolean']>;
  shopTreadPlusBanner_exists: Maybe<Scalars['Boolean']>;
  homepageHero_exists: Maybe<Scalars['Boolean']>;
  homepageProductBlocks_exists: Maybe<Scalars['Boolean']>;
  legalText_exists: Maybe<Scalars['Boolean']>;
  globalFinancingBanner_exists: Maybe<Scalars['Boolean']>;
  multiPromoBannerCollection_exists: Maybe<Scalars['Boolean']>;
  checkoutBanner_exists: Maybe<Scalars['Boolean']>;
  chaseBanner_exists: Maybe<Scalars['Boolean']>;
  urgencyBanner_exists: Maybe<Scalars['Boolean']>;
  promoRibbons_exists: Maybe<Scalars['Boolean']>;
  promoModules_exists: Maybe<Scalars['Boolean']>;
  productBlocksCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<PromoFilter>>>;
  AND: Maybe<Array<Maybe<PromoFilter>>>;
};

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHero = Entry & _Node & {
  __typename?: 'PromoHero';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PromoHeroLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  eyebrow: Maybe<Scalars['String']>;
  header: Maybe<Scalars['String']>;
  subhead: Maybe<Scalars['String']>;
  ctasCollection: Maybe<PromoHeroCtasCollection>;
  support: Maybe<Scalars['String']>;
  backgroundMedia: Maybe<PromoHeroBackgroundMedia>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroHeaderArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroSubheadArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroCtasCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
  order: Maybe<Array<Maybe<PromoHeroCtasCollectionOrder>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroSupportArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHero) */
export type PromoHeroBackgroundMediaArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type PromoHeroBackgroundMedia = BreakpointVideo | Image;

export type PromoHeroCollection = {
  __typename?: 'PromoHeroCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoHero>>;
};

export type PromoHeroCtasCollection = {
  __typename?: 'PromoHeroCtasCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<CtaWithUrl>>;
};

export enum PromoHeroCtasCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoHeroFilter = {
  ctas: Maybe<CfCtaWithUrlNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  header_exists: Maybe<Scalars['Boolean']>;
  header: Maybe<Scalars['String']>;
  header_not: Maybe<Scalars['String']>;
  header_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  header_contains: Maybe<Scalars['String']>;
  header_not_contains: Maybe<Scalars['String']>;
  subhead_exists: Maybe<Scalars['Boolean']>;
  subhead: Maybe<Scalars['String']>;
  subhead_not: Maybe<Scalars['String']>;
  subhead_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  subhead_contains: Maybe<Scalars['String']>;
  subhead_not_contains: Maybe<Scalars['String']>;
  ctasCollection_exists: Maybe<Scalars['Boolean']>;
  support_exists: Maybe<Scalars['Boolean']>;
  support: Maybe<Scalars['String']>;
  support_not: Maybe<Scalars['String']>;
  support_in: Maybe<Array<Maybe<Scalars['String']>>>;
  support_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  support_contains: Maybe<Scalars['String']>;
  support_not_contains: Maybe<Scalars['String']>;
  backgroundMedia_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<PromoHeroFilter>>>;
  AND: Maybe<Array<Maybe<PromoHeroFilter>>>;
};

export type PromoHeroLinkingCollections = {
  __typename?: 'PromoHeroLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  promoHeroSetCollection: Maybe<PromoHeroSetCollection>;
};


export type PromoHeroLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type PromoHeroLinkingCollectionsPromoHeroSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromoHeroLinkingCollectionsPromoHeroSetCollectionOrder>>>;
};

export enum PromoHeroLinkingCollectionsPromoHeroSetCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromoHeroOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHeroSet) */
export type PromoHeroSet = Entry & _Node & {
  __typename?: 'PromoHeroSet';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PromoHeroSetLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  heroItemsCollection: Maybe<PromoHeroSetHeroItemsCollection>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHeroSet) */
export type PromoHeroSetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHeroSet) */
export type PromoHeroSetKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promoHeroSet) */
export type PromoHeroSetHeroItemsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoHeroFilter>;
  order: Maybe<Array<Maybe<PromoHeroSetHeroItemsCollectionOrder>>>;
};

export type PromoHeroSetCollection = {
  __typename?: 'PromoHeroSetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoHeroSet>>;
};

export type PromoHeroSetFilter = {
  heroItems: Maybe<CfPromoHeroNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  heroItemsCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<PromoHeroSetFilter>>>;
  AND: Maybe<Array<Maybe<PromoHeroSetFilter>>>;
};

export type PromoHeroSetHeroItemsCollection = {
  __typename?: 'PromoHeroSetHeroItemsCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoHero>>;
};

export enum PromoHeroSetHeroItemsCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SubheadAsc = 'subhead_ASC',
  SubheadDesc = 'subhead_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoHeroSetLinkingCollections = {
  __typename?: 'PromoHeroSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  promoCollection: Maybe<PromoCollection>;
};


export type PromoHeroSetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type PromoHeroSetLinkingCollectionsPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromoHeroSetLinkingCollectionsPromoCollectionOrder>>>;
};

export enum PromoHeroSetLinkingCollectionsPromoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromoHeroSetOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoLinkingCollections = {
  __typename?: 'PromoLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type PromoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type PromoMultiPromoBannerCollection = {
  __typename?: 'PromoMultiPromoBannerCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromoBanner>>;
};

export enum PromoMultiPromoBannerCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  ThemeAsc = 'theme_ASC',
  ThemeDesc = 'theme_DESC',
  HideAsc = 'hide_ASC',
  HideDesc = 'hide_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromoOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  StartAsc = 'start_ASC',
  StartDesc = 'start_DESC',
  EndAsc = 'end_ASC',
  EndDesc = 'end_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type PromoProductBlocksCollection = {
  __typename?: 'PromoProductBlocksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ContentAggregate>>;
};

export enum PromoProductBlocksCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Promotional Copy, Decorators, and other miscellanea. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promotionalText) */
export type PromotionalText = Entry & _Node & {
  __typename?: 'PromotionalText';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<PromotionalTextLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  text: Maybe<Scalars['String']>;
};


/** Promotional Copy, Decorators, and other miscellanea. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promotionalText) */
export type PromotionalTextLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Promotional Copy, Decorators, and other miscellanea. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promotionalText) */
export type PromotionalTextKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Promotional Copy, Decorators, and other miscellanea. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/promotionalText) */
export type PromotionalTextTextArgs = {
  locale: Maybe<Scalars['String']>;
};

export type PromotionalTextCollection = {
  __typename?: 'PromotionalTextCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PromotionalText>>;
};

export type PromotionalTextFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  text_exists: Maybe<Scalars['Boolean']>;
  text: Maybe<Scalars['String']>;
  text_not: Maybe<Scalars['String']>;
  text_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  text_contains: Maybe<Scalars['String']>;
  text_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<PromotionalTextFilter>>>;
  AND: Maybe<Array<Maybe<PromotionalTextFilter>>>;
};

export type PromotionalTextLinkingCollections = {
  __typename?: 'PromotionalTextLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  navigationCollection: Maybe<NavigationCollection>;
  headbandCollection: Maybe<HeadbandCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
};


export type PromotionalTextLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type PromotionalTextLinkingCollectionsNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromotionalTextLinkingCollectionsNavigationCollectionOrder>>>;
};


export type PromotionalTextLinkingCollectionsHeadbandCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromotionalTextLinkingCollectionsHeadbandCollectionOrder>>>;
};


export type PromotionalTextLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<PromotionalTextLinkingCollectionsContentAggregateCollectionOrder>>>;
};

export enum PromotionalTextLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromotionalTextLinkingCollectionsHeadbandCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DeviceAsc = 'device_ASC',
  DeviceDesc = 'device_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromotionalTextLinkingCollectionsNavigationCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  ToggleLocatorAsc = 'toggleLocator_ASC',
  ToggleLocatorDesc = 'toggleLocator_DESC',
  ToggleAccountAsc = 'toggleAccount_ASC',
  ToggleAccountDesc = 'toggleAccount_DESC',
  ToggleCartAsc = 'toggleCart_ASC',
  ToggleCartDesc = 'toggleCart_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum PromotionalTextOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Question and Answer Pair [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/qAndA) */
export type QAndA = Entry & _Node & {
  __typename?: 'QAndA';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<QAndALinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  question: Maybe<Scalars['String']>;
  answer: Maybe<Scalars['String']>;
};


/** Question and Answer Pair [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/qAndA) */
export type QAndALinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Question and Answer Pair [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/qAndA) */
export type QAndAKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Question and Answer Pair [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/qAndA) */
export type QAndAQuestionArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Question and Answer Pair [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/qAndA) */
export type QAndAAnswerArgs = {
  locale: Maybe<Scalars['String']>;
};

export type QAndACollection = {
  __typename?: 'QAndACollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<QAndA>>;
};

export type QAndAFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  question_exists: Maybe<Scalars['Boolean']>;
  question: Maybe<Scalars['String']>;
  question_not: Maybe<Scalars['String']>;
  question_in: Maybe<Array<Maybe<Scalars['String']>>>;
  question_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  question_contains: Maybe<Scalars['String']>;
  question_not_contains: Maybe<Scalars['String']>;
  answer_exists: Maybe<Scalars['Boolean']>;
  answer: Maybe<Scalars['String']>;
  answer_not: Maybe<Scalars['String']>;
  answer_in: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  answer_contains: Maybe<Scalars['String']>;
  answer_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<QAndAFilter>>>;
  AND: Maybe<Array<Maybe<QAndAFilter>>>;
};

export type QAndALinkingCollections = {
  __typename?: 'QAndALinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  faqCollection: Maybe<FaqCollection>;
};


export type QAndALinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QAndALinkingCollectionsFaqCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<QAndALinkingCollectionsFaqCollectionOrder>>>;
};

export enum QAndALinkingCollectionsFaqCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum QAndAOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  QuestionAsc = 'question_ASC',
  QuestionDesc = 'question_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}


export type Query = {
  __typename?: 'Query';
  asset: Maybe<Asset>;
  assetCollection: Maybe<AssetCollection>;
  productDetailsCard: Maybe<ProductDetailsCard>;
  productDetailsCardCollection: Maybe<ProductDetailsCardCollection>;
  productNav: Maybe<ProductNav>;
  productNavCollection: Maybe<ProductNavCollection>;
  wwwLink: Maybe<WwwLink>;
  wwwLinkCollection: Maybe<WwwLinkCollection>;
  productRecommendationsCohort: Maybe<ProductRecommendationsCohort>;
  productRecommendationsCohortCollection: Maybe<ProductRecommendationsCohortCollection>;
  navigation: Maybe<Navigation>;
  navigationCollection: Maybe<NavigationCollection>;
  keyValue: Maybe<KeyValue>;
  keyValueCollection: Maybe<KeyValueCollection>;
  programs: Maybe<Programs>;
  programsCollection: Maybe<ProgramsCollection>;
  productRecommendationsTile: Maybe<ProductRecommendationsTile>;
  productRecommendationsTileCollection: Maybe<ProductRecommendationsTileCollection>;
  product: Maybe<Product>;
  productCollection: Maybe<ProductCollection>;
  faq: Maybe<Faq>;
  faqCollection: Maybe<FaqCollection>;
  ctaWithUrl: Maybe<CtaWithUrl>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
  studioSiteLink: Maybe<StudioSiteLink>;
  studioSiteLinkCollection: Maybe<StudioSiteLinkCollection>;
  headband: Maybe<Headband>;
  headbandCollection: Maybe<HeadbandCollection>;
  ctaWithUrlSet: Maybe<CtaWithUrlSet>;
  ctaWithUrlSetCollection: Maybe<CtaWithUrlSetCollection>;
  contentAggregate: Maybe<ContentAggregate>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  productBlock: Maybe<ProductBlock>;
  productBlockCollection: Maybe<ProductBlockCollection>;
  promo: Maybe<Promo>;
  promoCollection: Maybe<PromoCollection>;
  imageGallery: Maybe<ImageGallery>;
  imageGalleryCollection: Maybe<ImageGalleryCollection>;
  video: Maybe<Video>;
  videoCollection: Maybe<VideoCollection>;
  image: Maybe<Image>;
  imageCollection: Maybe<ImageCollection>;
  jsonKeyValue: Maybe<JsonKeyValue>;
  jsonKeyValueCollection: Maybe<JsonKeyValueCollection>;
  componentLeadGen: Maybe<ComponentLeadGen>;
  componentLeadGenCollection: Maybe<ComponentLeadGenCollection>;
  packageToSlugMapping: Maybe<PackageToSlugMapping>;
  packageToSlugMappingCollection: Maybe<PackageToSlugMappingCollection>;
  staticAsset: Maybe<StaticAsset>;
  staticAssetCollection: Maybe<StaticAssetCollection>;
  valueProp: Maybe<ValueProp>;
  valuePropCollection: Maybe<ValuePropCollection>;
  headerBody: Maybe<HeaderBody>;
  headerBodyCollection: Maybe<HeaderBodyCollection>;
  captureFormMessaging: Maybe<CaptureFormMessaging>;
  captureFormMessagingCollection: Maybe<CaptureFormMessagingCollection>;
  moduleConfiguration: Maybe<ModuleConfiguration>;
  moduleConfigurationCollection: Maybe<ModuleConfigurationCollection>;
  otdLookup: Maybe<OtdLookup>;
  otdLookupCollection: Maybe<OtdLookupCollection>;
  headerBodyImageWithLink: Maybe<HeaderBodyImageWithLink>;
  headerBodyImageWithLinkCollection: Maybe<HeaderBodyImageWithLinkCollection>;
  promoHero: Maybe<PromoHero>;
  promoHeroCollection: Maybe<PromoHeroCollection>;
  promoBanner: Maybe<PromoBanner>;
  promoBannerCollection: Maybe<PromoBannerCollection>;
  ctaWithDriftInteraction: Maybe<CtaWithDriftInteraction>;
  ctaWithDriftInteractionCollection: Maybe<CtaWithDriftInteractionCollection>;
  metadata: Maybe<Metadata>;
  metadataCollection: Maybe<MetadataCollection>;
  svgIcon: Maybe<SvgIcon>;
  svgIconCollection: Maybe<SvgIconCollection>;
  externalLink: Maybe<ExternalLink>;
  externalLinkCollection: Maybe<ExternalLinkCollection>;
  digitalLink: Maybe<DigitalLink>;
  digitalLinkCollection: Maybe<DigitalLinkCollection>;
  availableProducts: Maybe<AvailableProducts>;
  availableProductsCollection: Maybe<AvailableProductsCollection>;
  review: Maybe<Review>;
  reviewCollection: Maybe<ReviewCollection>;
  contentByCfu: Maybe<ContentByCfu>;
  contentByCfuCollection: Maybe<ContentByCfuCollection>;
  longHeaderBodyFootnote: Maybe<LongHeaderBodyFootnote>;
  longHeaderBodyFootnoteCollection: Maybe<LongHeaderBodyFootnoteCollection>;
  captureForm: Maybe<CaptureForm>;
  captureFormCollection: Maybe<CaptureFormCollection>;
  accountMetadata: Maybe<AccountMetadata>;
  accountMetadataCollection: Maybe<AccountMetadataCollection>;
  digitalCheckout: Maybe<DigitalCheckout>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotion: Maybe<DigitalPromotion>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
  shop: Maybe<Shop>;
  shopCollection: Maybe<ShopCollection>;
  section: Maybe<Section>;
  sectionCollection: Maybe<SectionCollection>;
  reviewSection: Maybe<ReviewSection>;
  reviewSectionCollection: Maybe<ReviewSectionCollection>;
  pressQuote: Maybe<PressQuote>;
  pressQuoteCollection: Maybe<PressQuoteCollection>;
  qAndA: Maybe<QAndA>;
  qAndACollection: Maybe<QAndACollection>;
  promotionalText: Maybe<PromotionalText>;
  promotionalTextCollection: Maybe<PromotionalTextCollection>;
  contentType2LKn3Xvlo4UCmGcweGaCyM: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyM>;
  contentType2LKn3Xvlo4UCmGcweGaCyMCollection: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMCollection>;
  modal: Maybe<Modal>;
  modalCollection: Maybe<ModalCollection>;
  markdownPage: Maybe<MarkdownPage>;
  markdownPageCollection: Maybe<MarkdownPageCollection>;
  legalText: Maybe<LegalText>;
  legalTextCollection: Maybe<LegalTextCollection>;
  hero: Maybe<Hero>;
  heroCollection: Maybe<HeroCollection>;
  headerBodyImage: Maybe<HeaderBodyImage>;
  headerBodyImageCollection: Maybe<HeaderBodyImageCollection>;
  forms: Maybe<Forms>;
  formsCollection: Maybe<FormsCollection>;
  companyPageText: Maybe<CompanyPageText>;
  companyPageTextCollection: Maybe<CompanyPageTextCollection>;
  boardOfDirector: Maybe<BoardOfDirector>;
  boardOfDirectorCollection: Maybe<BoardOfDirectorCollection>;
  accountKeyValue: Maybe<AccountKeyValue>;
  accountKeyValueCollection: Maybe<AccountKeyValueCollection>;
  track: Maybe<Track>;
  trackCollection: Maybe<TrackCollection>;
  promoHeroSet: Maybe<PromoHeroSet>;
  promoHeroSetCollection: Maybe<PromoHeroSetCollection>;
  productBlockSet: Maybe<ProductBlockSet>;
  productBlockSetCollection: Maybe<ProductBlockSetCollection>;
  headerBodyImageWithLinkGallery: Maybe<HeaderBodyImageWithLinkGallery>;
  headerBodyImageWithLinkGalleryCollection: Maybe<HeaderBodyImageWithLinkGalleryCollection>;
  mediaSection: Maybe<MediaSection>;
  mediaSectionCollection: Maybe<MediaSectionCollection>;
  valuePropSet: Maybe<ValuePropSet>;
  valuePropSetCollection: Maybe<ValuePropSetCollection>;
  headerBodyImageGallery: Maybe<HeaderBodyImageGallery>;
  headerBodyImageGalleryCollection: Maybe<HeaderBodyImageGalleryCollection>;
  contentType1VcIyFnR8OC2Imaa2Su2W2: Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2>;
  contentType1VcIyFnR8OC2Imaa2Su2W2Collection: Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Collection>;
  contentType3FI5SLqlYIeaKguyIkeyAm: Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAm>;
  contentType3FI5SLqlYIeaKguyIkeyAmCollection: Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmCollection>;
  registrationPage: Maybe<RegistrationPage>;
  registrationPageCollection: Maybe<RegistrationPageCollection>;
  breakpointVideo: Maybe<BreakpointVideo>;
  breakpointVideoCollection: Maybe<BreakpointVideoCollection>;
  quoteBanner: Maybe<QuoteBanner>;
  quoteBannerCollection: Maybe<QuoteBannerCollection>;
  banner: Maybe<Banner>;
  bannerCollection: Maybe<BannerCollection>;
  contentType1OJlz6NgUyeeaIk8Uci2Ow: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2Ow>;
  contentType1OJlz6NgUyeeaIk8Uci2OwCollection: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwCollection>;
  entryCollection: Maybe<EntryCollection>;
  _node: Maybe<_Node>;
};


export type QueryAssetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<AssetFilter>;
  order: Maybe<Array<Maybe<AssetOrder>>>;
};


export type QueryProductDetailsCardArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductDetailsCardCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductDetailsCardFilter>;
  order: Maybe<Array<Maybe<ProductDetailsCardOrder>>>;
};


export type QueryProductNavArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductNavCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductNavFilter>;
  order: Maybe<Array<Maybe<ProductNavOrder>>>;
};


export type QueryWwwLinkArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryWwwLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<WwwLinkFilter>;
  order: Maybe<Array<Maybe<WwwLinkOrder>>>;
};


export type QueryProductRecommendationsCohortArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductRecommendationsCohortCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductRecommendationsCohortFilter>;
  order: Maybe<Array<Maybe<ProductRecommendationsCohortOrder>>>;
};


export type QueryNavigationArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryNavigationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<NavigationFilter>;
  order: Maybe<Array<Maybe<NavigationOrder>>>;
};


export type QueryKeyValueArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryKeyValueCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<KeyValueFilter>;
  order: Maybe<Array<Maybe<KeyValueOrder>>>;
};


export type QueryProgramsArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProgramsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProgramsFilter>;
  order: Maybe<Array<Maybe<ProgramsOrder>>>;
};


export type QueryProductRecommendationsTileArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductRecommendationsTileCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductRecommendationsTileFilter>;
  order: Maybe<Array<Maybe<ProductRecommendationsTileOrder>>>;
};


export type QueryProductArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductFilter>;
  order: Maybe<Array<Maybe<ProductOrder>>>;
};


export type QueryFaqArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryFaqCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FaqFilter>;
  order: Maybe<Array<Maybe<FaqOrder>>>;
};


export type QueryCtaWithUrlArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlFilter>;
  order: Maybe<Array<Maybe<CtaWithUrlOrder>>>;
};


export type QueryStudioSiteLinkArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryStudioSiteLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<StudioSiteLinkFilter>;
  order: Maybe<Array<Maybe<StudioSiteLinkOrder>>>;
};


export type QueryHeadbandArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeadbandCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeadbandFilter>;
  order: Maybe<Array<Maybe<HeadbandOrder>>>;
};


export type QueryCtaWithUrlSetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCtaWithUrlSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithUrlSetFilter>;
  order: Maybe<Array<Maybe<CtaWithUrlSetOrder>>>;
};


export type QueryContentAggregateArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentAggregateFilter>;
  order: Maybe<Array<Maybe<ContentAggregateOrder>>>;
};


export type QueryProductBlockArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductBlockCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductBlockFilter>;
  order: Maybe<Array<Maybe<ProductBlockOrder>>>;
};


export type QueryPromoArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPromoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoFilter>;
  order: Maybe<Array<Maybe<PromoOrder>>>;
};


export type QueryImageGalleryArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageGalleryFilter>;
  order: Maybe<Array<Maybe<ImageGalleryOrder>>>;
};


export type QueryVideoArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<VideoFilter>;
  order: Maybe<Array<Maybe<VideoOrder>>>;
};


export type QueryImageArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
  order: Maybe<Array<Maybe<ImageOrder>>>;
};


export type QueryJsonKeyValueArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryJsonKeyValueCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<JsonKeyValueFilter>;
  order: Maybe<Array<Maybe<JsonKeyValueOrder>>>;
};


export type QueryComponentLeadGenArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryComponentLeadGenCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ComponentLeadGenFilter>;
  order: Maybe<Array<Maybe<ComponentLeadGenOrder>>>;
};


export type QueryPackageToSlugMappingArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPackageToSlugMappingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PackageToSlugMappingFilter>;
  order: Maybe<Array<Maybe<PackageToSlugMappingOrder>>>;
};


export type QueryStaticAssetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryStaticAssetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<StaticAssetFilter>;
  order: Maybe<Array<Maybe<StaticAssetOrder>>>;
};


export type QueryValuePropArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryValuePropCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ValuePropFilter>;
  order: Maybe<Array<Maybe<ValuePropOrder>>>;
};


export type QueryHeaderBodyArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeaderBodyCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyFilter>;
  order: Maybe<Array<Maybe<HeaderBodyOrder>>>;
};


export type QueryCaptureFormMessagingArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCaptureFormMessagingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CaptureFormMessagingFilter>;
  order: Maybe<Array<Maybe<CaptureFormMessagingOrder>>>;
};


export type QueryModuleConfigurationArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryModuleConfigurationCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ModuleConfigurationFilter>;
  order: Maybe<Array<Maybe<ModuleConfigurationOrder>>>;
};


export type QueryOtdLookupArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryOtdLookupCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<OtdLookupFilter>;
  order: Maybe<Array<Maybe<OtdLookupOrder>>>;
};


export type QueryHeaderBodyImageWithLinkArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeaderBodyImageWithLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageWithLinkFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageWithLinkOrder>>>;
};


export type QueryPromoHeroArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPromoHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoHeroFilter>;
  order: Maybe<Array<Maybe<PromoHeroOrder>>>;
};


export type QueryPromoBannerArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPromoBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoBannerFilter>;
  order: Maybe<Array<Maybe<PromoBannerOrder>>>;
};


export type QueryCtaWithDriftInteractionArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCtaWithDriftInteractionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CtaWithDriftInteractionFilter>;
  order: Maybe<Array<Maybe<CtaWithDriftInteractionOrder>>>;
};


export type QueryMetadataArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryMetadataCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<MetadataFilter>;
  order: Maybe<Array<Maybe<MetadataOrder>>>;
};


export type QuerySvgIconArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QuerySvgIconCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SvgIconFilter>;
  order: Maybe<Array<Maybe<SvgIconOrder>>>;
};


export type QueryExternalLinkArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryExternalLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ExternalLinkFilter>;
  order: Maybe<Array<Maybe<ExternalLinkOrder>>>;
};


export type QueryDigitalLinkArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryDigitalLinkCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<DigitalLinkFilter>;
  order: Maybe<Array<Maybe<DigitalLinkOrder>>>;
};


export type QueryAvailableProductsArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryAvailableProductsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<AvailableProductsFilter>;
  order: Maybe<Array<Maybe<AvailableProductsOrder>>>;
};


export type QueryReviewArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryReviewCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ReviewFilter>;
  order: Maybe<Array<Maybe<ReviewOrder>>>;
};


export type QueryContentByCfuArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentByCfuCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentByCfuFilter>;
  order: Maybe<Array<Maybe<ContentByCfuOrder>>>;
};


export type QueryLongHeaderBodyFootnoteArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryLongHeaderBodyFootnoteCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LongHeaderBodyFootnoteFilter>;
  order: Maybe<Array<Maybe<LongHeaderBodyFootnoteOrder>>>;
};


export type QueryCaptureFormArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCaptureFormCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CaptureFormFilter>;
  order: Maybe<Array<Maybe<CaptureFormOrder>>>;
};


export type QueryAccountMetadataArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryAccountMetadataCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<AccountMetadataFilter>;
  order: Maybe<Array<Maybe<AccountMetadataOrder>>>;
};


export type QueryDigitalCheckoutArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<DigitalCheckoutFilter>;
  order: Maybe<Array<Maybe<DigitalCheckoutOrder>>>;
};


export type QueryDigitalPromotionArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<DigitalPromotionFilter>;
  order: Maybe<Array<Maybe<DigitalPromotionOrder>>>;
};


export type QueryShopArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryShopCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ShopFilter>;
  order: Maybe<Array<Maybe<ShopOrder>>>;
};


export type QuerySectionArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QuerySectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SectionFilter>;
  order: Maybe<Array<Maybe<SectionOrder>>>;
};


export type QueryReviewSectionArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryReviewSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ReviewSectionFilter>;
  order: Maybe<Array<Maybe<ReviewSectionOrder>>>;
};


export type QueryPressQuoteArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPressQuoteCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PressQuoteFilter>;
  order: Maybe<Array<Maybe<PressQuoteOrder>>>;
};


export type QueryQAndAArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryQAndACollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<QAndAFilter>;
  order: Maybe<Array<Maybe<QAndAOrder>>>;
};


export type QueryPromotionalTextArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPromotionalTextCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromotionalTextFilter>;
  order: Maybe<Array<Maybe<PromotionalTextOrder>>>;
};


export type QueryContentType2LKn3Xvlo4UCmGcweGaCyMArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentType2LKn3Xvlo4UCmGcweGaCyMCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMFilter>;
  order: Maybe<Array<Maybe<ContentType2LKn3Xvlo4UCmGcweGaCyMOrder>>>;
};


export type QueryModalArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryModalCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ModalFilter>;
  order: Maybe<Array<Maybe<ModalOrder>>>;
};


export type QueryMarkdownPageArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryMarkdownPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<MarkdownPageFilter>;
  order: Maybe<Array<Maybe<MarkdownPageOrder>>>;
};


export type QueryLegalTextArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryLegalTextCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<LegalTextFilter>;
  order: Maybe<Array<Maybe<LegalTextOrder>>>;
};


export type QueryHeroArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeroCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeroFilter>;
  order: Maybe<Array<Maybe<HeroOrder>>>;
};


export type QueryHeaderBodyImageArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeaderBodyImageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageOrder>>>;
};


export type QueryFormsArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryFormsCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<FormsFilter>;
  order: Maybe<Array<Maybe<FormsOrder>>>;
};


export type QueryCompanyPageTextArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryCompanyPageTextCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<CompanyPageTextFilter>;
  order: Maybe<Array<Maybe<CompanyPageTextOrder>>>;
};


export type QueryBoardOfDirectorArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryBoardOfDirectorCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<BoardOfDirectorFilter>;
  order: Maybe<Array<Maybe<BoardOfDirectorOrder>>>;
};


export type QueryAccountKeyValueArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryAccountKeyValueCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<AccountKeyValueFilter>;
  order: Maybe<Array<Maybe<AccountKeyValueOrder>>>;
};


export type QueryTrackArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryTrackCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<TrackFilter>;
  order: Maybe<Array<Maybe<TrackOrder>>>;
};


export type QueryPromoHeroSetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryPromoHeroSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PromoHeroSetFilter>;
  order: Maybe<Array<Maybe<PromoHeroSetOrder>>>;
};


export type QueryProductBlockSetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryProductBlockSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ProductBlockSetFilter>;
  order: Maybe<Array<Maybe<ProductBlockSetOrder>>>;
};


export type QueryHeaderBodyImageWithLinkGalleryArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeaderBodyImageWithLinkGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageWithLinkGalleryFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageWithLinkGalleryOrder>>>;
};


export type QueryMediaSectionArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryMediaSectionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<MediaSectionFilter>;
  order: Maybe<Array<Maybe<MediaSectionOrder>>>;
};


export type QueryValuePropSetArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryValuePropSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ValuePropSetFilter>;
  order: Maybe<Array<Maybe<ValuePropSetOrder>>>;
};


export type QueryHeaderBodyImageGalleryArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryHeaderBodyImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<HeaderBodyImageGalleryFilter>;
  order: Maybe<Array<Maybe<HeaderBodyImageGalleryOrder>>>;
};


export type QueryContentType1VcIyFnR8Oc2Imaa2Su2W2Args = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentType1VcIyFnR8Oc2Imaa2Su2W2CollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Filter>;
  order: Maybe<Array<Maybe<ContentType1VcIyFnR8Oc2Imaa2Su2W2Order>>>;
};


export type QueryContentType3Fi5SLqlYIeaKguyIkeyAmArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentType3Fi5SLqlYIeaKguyIkeyAmCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmFilter>;
  order: Maybe<Array<Maybe<ContentType3Fi5SLqlYIeaKguyIkeyAmOrder>>>;
};


export type QueryRegistrationPageArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryRegistrationPageCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<RegistrationPageFilter>;
  order: Maybe<Array<Maybe<RegistrationPageOrder>>>;
};


export type QueryBreakpointVideoArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryBreakpointVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<BreakpointVideoFilter>;
  order: Maybe<Array<Maybe<BreakpointVideoOrder>>>;
};


export type QueryQuoteBannerArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryQuoteBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<QuoteBannerFilter>;
  order: Maybe<Array<Maybe<QuoteBannerOrder>>>;
};


export type QueryBannerArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryBannerCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<BannerFilter>;
  order: Maybe<Array<Maybe<BannerOrder>>>;
};


export type QueryContentType1OJlz6NgUyeeaIk8Uci2OwArgs = {
  id: Scalars['String'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type QueryContentType1OJlz6NgUyeeaIk8Uci2OwCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwFilter>;
  order: Maybe<Array<Maybe<ContentType1OJlz6NgUyeeaIk8Uci2OwOrder>>>;
};


export type QueryEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<EntryFilter>;
  order: Maybe<Array<Maybe<EntryOrder>>>;
};


export type Query_NodeArgs = {
  id: Scalars['ID'];
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

/** A collection of press quotes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/quoteBanner) */
export type QuoteBanner = Entry & _Node & {
  __typename?: 'QuoteBanner';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<QuoteBannerLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  quotesCollection: Maybe<QuoteBannerQuotesCollection>;
};


/** A collection of press quotes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/quoteBanner) */
export type QuoteBannerLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A collection of press quotes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/quoteBanner) */
export type QuoteBannerKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A collection of press quotes [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/quoteBanner) */
export type QuoteBannerQuotesCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<PressQuoteFilter>;
  order: Maybe<Array<Maybe<QuoteBannerQuotesCollectionOrder>>>;
};

export type QuoteBannerCollection = {
  __typename?: 'QuoteBannerCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<QuoteBanner>>;
};

export type QuoteBannerFilter = {
  quotes: Maybe<CfPressQuoteNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  quotesCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<QuoteBannerFilter>>>;
  AND: Maybe<Array<Maybe<QuoteBannerFilter>>>;
};

export type QuoteBannerLinkingCollections = {
  __typename?: 'QuoteBannerLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type QuoteBannerLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum QuoteBannerOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type QuoteBannerQuotesCollection = {
  __typename?: 'QuoteBannerQuotesCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<PressQuote>>;
};

export enum QuoteBannerQuotesCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  QuoteAsc = 'quote_ASC',
  QuoteDesc = 'quote_DESC',
  PublicationAsc = 'publication_ASC',
  PublicationDesc = 'publication_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPage = Entry & _Node & {
  __typename?: 'RegistrationPage';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<RegistrationPageLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  image: Maybe<Image>;
  eyebrow: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  cta: Maybe<Scalars['String']>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageImageArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ImageFilter>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageEyebrowArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** Template registration page (default, free-trial, bike, digital, etc.) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/registrationPage) */
export type RegistrationPageCtaArgs = {
  locale: Maybe<Scalars['String']>;
};

export type RegistrationPageCollection = {
  __typename?: 'RegistrationPageCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<RegistrationPage>>;
};

export type RegistrationPageFilter = {
  image: Maybe<CfImageNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  image_exists: Maybe<Scalars['Boolean']>;
  eyebrow_exists: Maybe<Scalars['Boolean']>;
  eyebrow: Maybe<Scalars['String']>;
  eyebrow_not: Maybe<Scalars['String']>;
  eyebrow_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  eyebrow_contains: Maybe<Scalars['String']>;
  eyebrow_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  cta_exists: Maybe<Scalars['Boolean']>;
  cta: Maybe<Scalars['String']>;
  cta_not: Maybe<Scalars['String']>;
  cta_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  cta_contains: Maybe<Scalars['String']>;
  cta_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<RegistrationPageFilter>>>;
  AND: Maybe<Array<Maybe<RegistrationPageFilter>>>;
};

export type RegistrationPageLinkingCollections = {
  __typename?: 'RegistrationPageLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type RegistrationPageLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum RegistrationPageOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  EyebrowAsc = 'eyebrow_ASC',
  EyebrowDesc = 'eyebrow_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CtaAsc = 'cta_ASC',
  CtaDesc = 'cta_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type Review = Entry & _Node & {
  __typename?: 'Review';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ReviewLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  rating: Maybe<Scalars['Int']>;
  username: Maybe<Scalars['String']>;
  location: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewRatingArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewUsernameArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewLocationArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A review left by a user [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/review) */
export type ReviewBodyArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ReviewCollection = {
  __typename?: 'ReviewCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Review>>;
};

export type ReviewFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  rating_exists: Maybe<Scalars['Boolean']>;
  rating: Maybe<Scalars['Int']>;
  rating_not: Maybe<Scalars['Int']>;
  rating_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating_not_in: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating_gt: Maybe<Scalars['Int']>;
  rating_gte: Maybe<Scalars['Int']>;
  rating_lt: Maybe<Scalars['Int']>;
  rating_lte: Maybe<Scalars['Int']>;
  username_exists: Maybe<Scalars['Boolean']>;
  username: Maybe<Scalars['String']>;
  username_not: Maybe<Scalars['String']>;
  username_in: Maybe<Array<Maybe<Scalars['String']>>>;
  username_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  username_contains: Maybe<Scalars['String']>;
  username_not_contains: Maybe<Scalars['String']>;
  location_exists: Maybe<Scalars['Boolean']>;
  location: Maybe<Scalars['String']>;
  location_not: Maybe<Scalars['String']>;
  location_in: Maybe<Array<Maybe<Scalars['String']>>>;
  location_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  location_contains: Maybe<Scalars['String']>;
  location_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ReviewFilter>>>;
  AND: Maybe<Array<Maybe<ReviewFilter>>>;
};

export type ReviewLinkingCollections = {
  __typename?: 'ReviewLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ReviewLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ReviewOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  RatingAsc = 'rating_ASC',
  RatingDesc = 'rating_DESC',
  UsernameAsc = 'username_ASC',
  UsernameDesc = 'username_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSection = Entry & _Node & {
  __typename?: 'ReviewSection';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ReviewSectionLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  count: Maybe<Scalars['String']>;
  overallRating: Maybe<Scalars['Float']>;
};


/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSectionLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSectionKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSectionTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSectionCountArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A section with aggregate info about reviews. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/reviewSection) */
export type ReviewSectionOverallRatingArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ReviewSectionCollection = {
  __typename?: 'ReviewSectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ReviewSection>>;
};

export type ReviewSectionFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  count_exists: Maybe<Scalars['Boolean']>;
  count: Maybe<Scalars['String']>;
  count_not: Maybe<Scalars['String']>;
  count_in: Maybe<Array<Maybe<Scalars['String']>>>;
  count_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  count_contains: Maybe<Scalars['String']>;
  count_not_contains: Maybe<Scalars['String']>;
  overallRating_exists: Maybe<Scalars['Boolean']>;
  overallRating: Maybe<Scalars['Float']>;
  overallRating_not: Maybe<Scalars['Float']>;
  overallRating_in: Maybe<Array<Maybe<Scalars['Float']>>>;
  overallRating_not_in: Maybe<Array<Maybe<Scalars['Float']>>>;
  overallRating_gt: Maybe<Scalars['Float']>;
  overallRating_gte: Maybe<Scalars['Float']>;
  overallRating_lt: Maybe<Scalars['Float']>;
  overallRating_lte: Maybe<Scalars['Float']>;
  OR: Maybe<Array<Maybe<ReviewSectionFilter>>>;
  AND: Maybe<Array<Maybe<ReviewSectionFilter>>>;
};

export type ReviewSectionLinkingCollections = {
  __typename?: 'ReviewSectionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type ReviewSectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum ReviewSectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  CountAsc = 'count_ASC',
  CountDesc = 'count_DESC',
  OverallRatingAsc = 'overallRating_ASC',
  OverallRatingDesc = 'overallRating_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A simple section with a Title and a Body [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/section) */
export type Section = Entry & _Node & {
  __typename?: 'Section';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<SectionLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
};


/** A simple section with a Title and a Body [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/section) */
export type SectionLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A simple section with a Title and a Body [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/section) */
export type SectionKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A simple section with a Title and a Body [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/section) */
export type SectionTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A simple section with a Title and a Body [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/section) */
export type SectionBodyArgs = {
  locale: Maybe<Scalars['String']>;
};

export type SectionCollection = {
  __typename?: 'SectionCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Section>>;
};

export type SectionFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<SectionFilter>>>;
  AND: Maybe<Array<Maybe<SectionFilter>>>;
};

export type SectionLinkingCollections = {
  __typename?: 'SectionLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type SectionLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type SectionLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<SectionLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type SectionLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<SectionLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum SectionLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SectionLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * Key value mappings that fall under the shop domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/shop)
 */
export type Shop = Entry & _Node & {
  __typename?: 'Shop';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ShopLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  value: Maybe<Scalars['String']>;
};


/**
 * Key value mappings that fall under the shop domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/shop)
 */
export type ShopLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Key value mappings that fall under the shop domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/shop)
 */
export type ShopKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Key value mappings that fall under the shop domain. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/shop)
 */
export type ShopValueArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ShopCollection = {
  __typename?: 'ShopCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Shop>>;
};

export type ShopFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  value_exists: Maybe<Scalars['Boolean']>;
  value: Maybe<Scalars['String']>;
  value_not: Maybe<Scalars['String']>;
  value_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  value_contains: Maybe<Scalars['String']>;
  value_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ShopFilter>>>;
  AND: Maybe<Array<Maybe<ShopFilter>>>;
};

export type ShopLinkingCollections = {
  __typename?: 'ShopLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  captureFormMessagingCollection: Maybe<CaptureFormMessagingCollection>;
  contentByCfuCollection: Maybe<ContentByCfuCollection>;
  longHeaderBodyFootnoteCollection: Maybe<LongHeaderBodyFootnoteCollection>;
};


export type ShopLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ShopLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ShopLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type ShopLinkingCollectionsCaptureFormMessagingCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ShopLinkingCollectionsCaptureFormMessagingCollectionOrder>>>;
};


export type ShopLinkingCollectionsContentByCfuCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ShopLinkingCollectionsContentByCfuCollectionOrder>>>;
};


export type ShopLinkingCollectionsLongHeaderBodyFootnoteCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ShopLinkingCollectionsLongHeaderBodyFootnoteCollectionOrder>>>;
};

export enum ShopLinkingCollectionsCaptureFormMessagingCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  HeaderAsc = 'header_ASC',
  HeaderDesc = 'header_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ShopLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ShopLinkingCollectionsContentByCfuCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ShopLinkingCollectionsLongHeaderBodyFootnoteCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ShopOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A content type to represent links to static assets e.g pdfs. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/staticAsset) */
export type StaticAsset = Entry & _Node & {
  __typename?: 'StaticAsset';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<StaticAssetLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  available: Maybe<Scalars['Boolean']>;
  asset: Maybe<Asset>;
};


/** A content type to represent links to static assets e.g pdfs. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/staticAsset) */
export type StaticAssetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A content type to represent links to static assets e.g pdfs. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/staticAsset) */
export type StaticAssetKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A content type to represent links to static assets e.g pdfs. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/staticAsset) */
export type StaticAssetAvailableArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A content type to represent links to static assets e.g pdfs. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/staticAsset) */
export type StaticAssetAssetArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type StaticAssetCollection = {
  __typename?: 'StaticAssetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<StaticAsset>>;
};

export type StaticAssetFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  available_exists: Maybe<Scalars['Boolean']>;
  available: Maybe<Scalars['Boolean']>;
  available_not: Maybe<Scalars['Boolean']>;
  asset_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<StaticAssetFilter>>>;
  AND: Maybe<Array<Maybe<StaticAssetFilter>>>;
};

export type StaticAssetLinkingCollections = {
  __typename?: 'StaticAssetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
};


export type StaticAssetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export enum StaticAssetOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  AvailableAsc = 'available_ASC',
  AvailableDesc = 'available_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A predefined list of studio site URLs (ex: /schedule, /shop, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/studioSiteLink) */
export type StudioSiteLink = Entry & _Node & {
  __typename?: 'StudioSiteLink';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<StudioSiteLinkLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};


/** A predefined list of studio site URLs (ex: /schedule, /shop, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/studioSiteLink) */
export type StudioSiteLinkLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A predefined list of studio site URLs (ex: /schedule, /shop, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/studioSiteLink) */
export type StudioSiteLinkKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A predefined list of studio site URLs (ex: /schedule, /shop, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/studioSiteLink) */
export type StudioSiteLinkUrlArgs = {
  locale: Maybe<Scalars['String']>;
};

export type StudioSiteLinkCollection = {
  __typename?: 'StudioSiteLinkCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<StudioSiteLink>>;
};

export type StudioSiteLinkFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  url_exists: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  url_not: Maybe<Scalars['String']>;
  url_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains: Maybe<Scalars['String']>;
  url_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<StudioSiteLinkFilter>>>;
  AND: Maybe<Array<Maybe<StudioSiteLinkFilter>>>;
};

export type StudioSiteLinkLinkingCollections = {
  __typename?: 'StudioSiteLinkLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
};


export type StudioSiteLinkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type StudioSiteLinkLinkingCollectionsCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<StudioSiteLinkLinkingCollectionsCtaWithUrlCollectionOrder>>>;
};

export enum StudioSiteLinkLinkingCollectionsCtaWithUrlCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum StudioSiteLinkOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIcon = Entry & _Node & {
  __typename?: 'SvgIcon';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<SvgIconLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  iconComponent: Maybe<Scalars['String']>;
  fill: Maybe<Scalars['String']>;
  tooltip: Maybe<Scalars['String']>;
};


/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIconLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIconKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIconIconComponentArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIconFillArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A configurable reference to an icon that MUST ALSO EXIST IN THE CODEBASE as a
 * TypeScript-wrapped SVG. [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/svgIcon)
 */
export type SvgIconTooltipArgs = {
  locale: Maybe<Scalars['String']>;
};

export type SvgIconCollection = {
  __typename?: 'SvgIconCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<SvgIcon>>;
};

export type SvgIconFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  iconComponent_exists: Maybe<Scalars['Boolean']>;
  iconComponent: Maybe<Scalars['String']>;
  iconComponent_not: Maybe<Scalars['String']>;
  iconComponent_in: Maybe<Array<Maybe<Scalars['String']>>>;
  iconComponent_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  iconComponent_contains: Maybe<Scalars['String']>;
  iconComponent_not_contains: Maybe<Scalars['String']>;
  fill_exists: Maybe<Scalars['Boolean']>;
  fill: Maybe<Scalars['String']>;
  fill_not: Maybe<Scalars['String']>;
  fill_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fill_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  fill_contains: Maybe<Scalars['String']>;
  fill_not_contains: Maybe<Scalars['String']>;
  tooltip_exists: Maybe<Scalars['Boolean']>;
  tooltip: Maybe<Scalars['String']>;
  tooltip_not: Maybe<Scalars['String']>;
  tooltip_in: Maybe<Array<Maybe<Scalars['String']>>>;
  tooltip_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  tooltip_contains: Maybe<Scalars['String']>;
  tooltip_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<SvgIconFilter>>>;
  AND: Maybe<Array<Maybe<SvgIconFilter>>>;
};

export type SvgIconLinkingCollections = {
  __typename?: 'SvgIconLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  valuePropCollection: Maybe<ValuePropCollection>;
};


export type SvgIconLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type SvgIconLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<SvgIconLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type SvgIconLinkingCollectionsValuePropCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<SvgIconLinkingCollectionsValuePropCollectionOrder>>>;
};

export enum SvgIconLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SvgIconLinkingCollectionsValuePropCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OnClickAsc = 'onClick_ASC',
  OnClickDesc = 'onClick_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum SvgIconOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  IconComponentAsc = 'iconComponent_ASC',
  IconComponentDesc = 'iconComponent_DESC',
  FillAsc = 'fill_ASC',
  FillDesc = 'fill_DESC',
  TooltipAsc = 'tooltip_ASC',
  TooltipDesc = 'tooltip_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type Sys = {
  __typename?: 'Sys';
  id: Scalars['String'];
  spaceId: Scalars['String'];
  environmentId: Scalars['String'];
  publishedAt: Maybe<Scalars['DateTime']>;
  firstPublishedAt: Maybe<Scalars['DateTime']>;
  publishedVersion: Maybe<Scalars['Int']>;
  /** The locale that was requested. */
  locale: Maybe<Scalars['String']>;
};

export type SysFilter = {
  id_exists: Maybe<Scalars['Boolean']>;
  id: Maybe<Scalars['String']>;
  id_not: Maybe<Scalars['String']>;
  id_in: Maybe<Array<Maybe<Scalars['String']>>>;
  id_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  id_contains: Maybe<Scalars['String']>;
  id_not_contains: Maybe<Scalars['String']>;
  publishedAt_exists: Maybe<Scalars['Boolean']>;
  publishedAt: Maybe<Scalars['DateTime']>;
  publishedAt_not: Maybe<Scalars['DateTime']>;
  publishedAt_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  publishedAt_gt: Maybe<Scalars['DateTime']>;
  publishedAt_gte: Maybe<Scalars['DateTime']>;
  publishedAt_lt: Maybe<Scalars['DateTime']>;
  publishedAt_lte: Maybe<Scalars['DateTime']>;
  firstPublishedAt_exists: Maybe<Scalars['Boolean']>;
  firstPublishedAt: Maybe<Scalars['DateTime']>;
  firstPublishedAt_not: Maybe<Scalars['DateTime']>;
  firstPublishedAt_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_not_in: Maybe<Array<Maybe<Scalars['DateTime']>>>;
  firstPublishedAt_gt: Maybe<Scalars['DateTime']>;
  firstPublishedAt_gte: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lt: Maybe<Scalars['DateTime']>;
  firstPublishedAt_lte: Maybe<Scalars['DateTime']>;
  publishedVersion_exists: Maybe<Scalars['Boolean']>;
  publishedVersion: Maybe<Scalars['Float']>;
  publishedVersion_not: Maybe<Scalars['Float']>;
  publishedVersion_in: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_not_in: Maybe<Array<Maybe<Scalars['Float']>>>;
  publishedVersion_gt: Maybe<Scalars['Float']>;
  publishedVersion_gte: Maybe<Scalars['Float']>;
  publishedVersion_lt: Maybe<Scalars['Float']>;
  publishedVersion_lte: Maybe<Scalars['Float']>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *         Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-concepts
 */
export type TaxonomyConcept = {
  __typename?: 'TaxonomyConcept';
  id: Maybe<Scalars['String']>;
};

/**
 * Contains attributes for track elements that are children of audio and video
 * elements. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/track)
 */
export type Track = Entry & _Node & {
  __typename?: 'Track';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<TrackLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  kind: Maybe<Scalars['String']>;
  src: Maybe<Asset>;
};


/**
 * Contains attributes for track elements that are children of audio and video
 * elements. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/track)
 */
export type TrackLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * Contains attributes for track elements that are children of audio and video
 * elements. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/track)
 */
export type TrackKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Contains attributes for track elements that are children of audio and video
 * elements. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/track)
 */
export type TrackKindArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * Contains attributes for track elements that are children of audio and video
 * elements. [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/track)
 */
export type TrackSrcArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};

export type TrackCollection = {
  __typename?: 'TrackCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Track>>;
};

export type TrackFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  kind_exists: Maybe<Scalars['Boolean']>;
  kind: Maybe<Scalars['String']>;
  kind_not: Maybe<Scalars['String']>;
  kind_in: Maybe<Array<Maybe<Scalars['String']>>>;
  kind_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  kind_contains: Maybe<Scalars['String']>;
  kind_not_contains: Maybe<Scalars['String']>;
  src_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<TrackFilter>>>;
  AND: Maybe<Array<Maybe<TrackFilter>>>;
};

export type TrackLinkingCollections = {
  __typename?: 'TrackLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  videoCollection: Maybe<VideoCollection>;
};


export type TrackLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type TrackLinkingCollectionsVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<TrackLinkingCollectionsVideoCollectionOrder>>>;
};

export enum TrackLinkingCollectionsVideoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LoopAsc = 'loop_ASC',
  LoopDesc = 'loop_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum TrackOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValueProp = Entry & _Node & {
  __typename?: 'ValueProp';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ValuePropLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  body: Maybe<Scalars['String']>;
  icon: Maybe<Asset>;
  svgIcon: Maybe<SvgIcon>;
  onClick: Maybe<Scalars['String']>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropTitleArgs = {
  locale: Maybe<Scalars['String']>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropBodyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropIconArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropSvgIconArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<SvgIconFilter>;
};


/** One of those cool value prop/perk cards [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valueProp) */
export type ValuePropOnClickArgs = {
  locale: Maybe<Scalars['String']>;
};

export type ValuePropCollection = {
  __typename?: 'ValuePropCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ValueProp>>;
};

export type ValuePropFilter = {
  svgIcon: Maybe<CfSvgIconNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  title_exists: Maybe<Scalars['Boolean']>;
  title: Maybe<Scalars['String']>;
  title_not: Maybe<Scalars['String']>;
  title_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  title_contains: Maybe<Scalars['String']>;
  title_not_contains: Maybe<Scalars['String']>;
  body_exists: Maybe<Scalars['Boolean']>;
  body: Maybe<Scalars['String']>;
  body_not: Maybe<Scalars['String']>;
  body_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  body_contains: Maybe<Scalars['String']>;
  body_not_contains: Maybe<Scalars['String']>;
  icon_exists: Maybe<Scalars['Boolean']>;
  svgIcon_exists: Maybe<Scalars['Boolean']>;
  onClick_exists: Maybe<Scalars['Boolean']>;
  onClick: Maybe<Scalars['String']>;
  onClick_not: Maybe<Scalars['String']>;
  onClick_in: Maybe<Array<Maybe<Scalars['String']>>>;
  onClick_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  onClick_contains: Maybe<Scalars['String']>;
  onClick_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<ValuePropFilter>>>;
  AND: Maybe<Array<Maybe<ValuePropFilter>>>;
};

export type ValuePropLinkingCollections = {
  __typename?: 'ValuePropLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  valuePropSetCollection: Maybe<ValuePropSetCollection>;
};


export type ValuePropLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ValuePropLinkingCollectionsValuePropSetCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ValuePropLinkingCollectionsValuePropSetCollectionOrder>>>;
};

export enum ValuePropLinkingCollectionsValuePropSetCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ValuePropOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OnClickAsc = 'onClick_ASC',
  OnClickDesc = 'onClick_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * An ordered list of Value Prop entries that can be pulled into code using a
 * single key [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valuePropSet)
 */
export type ValuePropSet = Entry & _Node & {
  __typename?: 'ValuePropSet';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<ValuePropSetLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  valuePropItemCollection: Maybe<ValuePropSetValuePropItemCollection>;
};


/**
 * An ordered list of Value Prop entries that can be pulled into code using a
 * single key [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valuePropSet)
 */
export type ValuePropSetLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * An ordered list of Value Prop entries that can be pulled into code using a
 * single key [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valuePropSet)
 */
export type ValuePropSetKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * An ordered list of Value Prop entries that can be pulled into code using a
 * single key [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/valuePropSet)
 */
export type ValuePropSetValuePropItemCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<ValuePropFilter>;
  order: Maybe<Array<Maybe<ValuePropSetValuePropItemCollectionOrder>>>;
};

export type ValuePropSetCollection = {
  __typename?: 'ValuePropSetCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ValuePropSet>>;
};

export type ValuePropSetFilter = {
  valuePropItem: Maybe<CfValuePropNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  valuePropItemCollection_exists: Maybe<Scalars['Boolean']>;
  OR: Maybe<Array<Maybe<ValuePropSetFilter>>>;
  AND: Maybe<Array<Maybe<ValuePropSetFilter>>>;
};

export type ValuePropSetLinkingCollections = {
  __typename?: 'ValuePropSetLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  digitalCheckoutCollection: Maybe<DigitalCheckoutCollection>;
  digitalPromotionCollection: Maybe<DigitalPromotionCollection>;
};


export type ValuePropSetLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type ValuePropSetLinkingCollectionsDigitalCheckoutCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ValuePropSetLinkingCollectionsDigitalCheckoutCollectionOrder>>>;
};


export type ValuePropSetLinkingCollectionsDigitalPromotionCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<ValuePropSetLinkingCollectionsDigitalPromotionCollectionOrder>>>;
};

export enum ValuePropSetLinkingCollectionsDigitalCheckoutCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ValuePropSetLinkingCollectionsDigitalPromotionCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum ValuePropSetOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type ValuePropSetValuePropItemCollection = {
  __typename?: 'ValuePropSetValuePropItemCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<ValueProp>>;
};

export enum ValuePropSetValuePropItemCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  OnClickAsc = 'onClick_ASC',
  OnClickDesc = 'onClick_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type Video = Entry & _Node & {
  __typename?: 'Video';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<VideoLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  poster: Maybe<Asset>;
  video: Maybe<Asset>;
  tracksCollection: Maybe<VideoTracksCollection>;
  loop: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoPosterArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoVideoArgs = {
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoTracksCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  where: Maybe<TrackFilter>;
  order: Maybe<Array<Maybe<VideoTracksCollectionOrder>>>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoLoopArgs = {
  locale: Maybe<Scalars['String']>;
};


/**
 * A video asset (preferably a Vimeo link) with associated poster image [See type
 * definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/video)
 */
export type VideoCaptionArgs = {
  locale: Maybe<Scalars['String']>;
};

export type VideoCollection = {
  __typename?: 'VideoCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Video>>;
};

export type VideoFilter = {
  tracks: Maybe<CfTrackNestedFilter>;
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  poster_exists: Maybe<Scalars['Boolean']>;
  video_exists: Maybe<Scalars['Boolean']>;
  tracksCollection_exists: Maybe<Scalars['Boolean']>;
  loop_exists: Maybe<Scalars['Boolean']>;
  loop: Maybe<Scalars['Boolean']>;
  loop_not: Maybe<Scalars['Boolean']>;
  caption_exists: Maybe<Scalars['Boolean']>;
  caption: Maybe<Scalars['String']>;
  caption_not: Maybe<Scalars['String']>;
  caption_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  caption_contains: Maybe<Scalars['String']>;
  caption_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<VideoFilter>>>;
  AND: Maybe<Array<Maybe<VideoFilter>>>;
};

export type VideoLinkingCollections = {
  __typename?: 'VideoLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  contentAggregateCollection: Maybe<ContentAggregateCollection>;
  imageGalleryCollection: Maybe<ImageGalleryCollection>;
  breakpointVideoCollection: Maybe<BreakpointVideoCollection>;
};


export type VideoLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type VideoLinkingCollectionsContentAggregateCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<VideoLinkingCollectionsContentAggregateCollectionOrder>>>;
};


export type VideoLinkingCollectionsImageGalleryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<VideoLinkingCollectionsImageGalleryCollectionOrder>>>;
};


export type VideoLinkingCollectionsBreakpointVideoCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<VideoLinkingCollectionsBreakpointVideoCollectionOrder>>>;
};

export enum VideoLinkingCollectionsBreakpointVideoCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum VideoLinkingCollectionsContentAggregateCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum VideoLinkingCollectionsImageGalleryCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum VideoOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  LoopAsc = 'loop_ASC',
  LoopDesc = 'loop_DESC',
  CaptionAsc = 'caption_ASC',
  CaptionDesc = 'caption_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type VideoTracksCollection = {
  __typename?: 'VideoTracksCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<Track>>;
};

export enum VideoTracksCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

/** A predefined list of www URLs (ex: /bike, /tread, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/wwwLink) */
export type WwwLink = Entry & _Node & {
  __typename?: 'WwwLink';
  sys: Sys;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom: Maybe<WwwLinkLinkingCollections>;
  _id: Scalars['ID'];
  key: Maybe<Scalars['String']>;
  url: Maybe<Scalars['String']>;
};


/** A predefined list of www URLs (ex: /bike, /tread, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/wwwLink) */
export type WwwLinkLinkedFromArgs = {
  allowedLocales: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** A predefined list of www URLs (ex: /bike, /tread, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/wwwLink) */
export type WwwLinkKeyArgs = {
  locale: Maybe<Scalars['String']>;
};


/** A predefined list of www URLs (ex: /bike, /tread, etc) [See type definition](https://app.contentful.com/spaces/6jnflt57iyzx/content_types/wwwLink) */
export type WwwLinkUrlArgs = {
  locale: Maybe<Scalars['String']>;
};

export type WwwLinkCollection = {
  __typename?: 'WwwLinkCollection';
  total: Scalars['Int'];
  skip: Scalars['Int'];
  limit: Scalars['Int'];
  items: Array<Maybe<WwwLink>>;
};

export type WwwLinkFilter = {
  sys: Maybe<SysFilter>;
  contentfulMetadata: Maybe<ContentfulMetadataFilter>;
  key_exists: Maybe<Scalars['Boolean']>;
  key: Maybe<Scalars['String']>;
  key_not: Maybe<Scalars['String']>;
  key_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  key_contains: Maybe<Scalars['String']>;
  key_not_contains: Maybe<Scalars['String']>;
  url_exists: Maybe<Scalars['Boolean']>;
  url: Maybe<Scalars['String']>;
  url_not: Maybe<Scalars['String']>;
  url_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_not_in: Maybe<Array<Maybe<Scalars['String']>>>;
  url_contains: Maybe<Scalars['String']>;
  url_not_contains: Maybe<Scalars['String']>;
  OR: Maybe<Array<Maybe<WwwLinkFilter>>>;
  AND: Maybe<Array<Maybe<WwwLinkFilter>>>;
};

export type WwwLinkLinkingCollections = {
  __typename?: 'WwwLinkLinkingCollections';
  entryCollection: Maybe<EntryCollection>;
  ctaWithUrlCollection: Maybe<CtaWithUrlCollection>;
};


export type WwwLinkLinkingCollectionsEntryCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
};


export type WwwLinkLinkingCollectionsCtaWithUrlCollectionArgs = {
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  preview: Maybe<Scalars['Boolean']>;
  locale: Maybe<Scalars['String']>;
  order: Maybe<Array<Maybe<WwwLinkLinkingCollectionsCtaWithUrlCollectionOrder>>>;
};

export enum WwwLinkLinkingCollectionsCtaWithUrlCollectionOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  TextAsc = 'text_ASC',
  TextDesc = 'text_DESC',
  TargetBlankAsc = 'targetBlank_ASC',
  TargetBlankDesc = 'targetBlank_DESC',
  AriaLabelAsc = 'ariaLabel_ASC',
  AriaLabelDesc = 'ariaLabel_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export enum WwwLinkOrder {
  KeyAsc = 'key_ASC',
  KeyDesc = 'key_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC'
}

export type AccountKeyValueQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountKeyValueQuery = (
  { __typename?: 'Query' }
  & { accountKeyValue: Maybe<(
    { __typename: 'AccountKeyValue' }
    & Pick<AccountKeyValue, 'key' | 'value'>
  )> }
);

export type AccountMetadataQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AccountMetadataQuery = (
  { __typename?: 'Query' }
  & { accountMetadata: Maybe<(
    { __typename: 'AccountMetadata' }
    & Pick<AccountMetadata, 'key' | 'title' | 'description' | 'keywords' | 'ogtype'>
    & { image: Maybe<(
      { __typename: 'Asset' }
      & Pick<Asset, 'url' | 'title'>
    )> }
  )> }
);

export type CopyQueryQueryVariables = Exact<{
  locale: Maybe<Scalars['String']>;
  preview: Maybe<Scalars['Boolean']>;
}>;


export type CopyQueryQuery = (
  { __typename?: 'Query' }
  & { copy1: Maybe<(
    { __typename: 'AccountKeyValueCollection' }
    & { items: Array<Maybe<(
      { __typename: 'AccountKeyValue' }
      & Pick<AccountKeyValue, 'key' | 'value'>
    )>> }
  )>, copy2: Maybe<(
    { __typename: 'AccountKeyValueCollection' }
    & { items: Array<Maybe<(
      { __typename: 'AccountKeyValue' }
      & Pick<AccountKeyValue, 'key' | 'value'>
    )>> }
  )>, copy3: Maybe<(
    { __typename: 'AccountKeyValueCollection' }
    & { items: Array<Maybe<(
      { __typename: 'AccountKeyValue' }
      & Pick<AccountKeyValue, 'key' | 'value'>
    )>> }
  )>, accountMetadataCollection: Maybe<(
    { __typename: 'AccountMetadataCollection' }
    & { items: Array<Maybe<(
      { __typename: 'AccountMetadata' }
      & Pick<AccountMetadata, 'key' | 'title' | 'description' | 'keywords' | 'ogtype'>
      & { image: Maybe<(
        { __typename: 'Asset' }
        & Pick<Asset, 'url' | 'title'>
      )> }
    )>> }
  )>, formsCollection: Maybe<(
    { __typename: 'FormsCollection' }
    & { items: Array<Maybe<(
      { __typename: 'Forms' }
      & Pick<Forms, 'key' | 'value'>
    )>> }
  )> }
);

export type CtaWithDriftInteractionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CtaWithDriftInteractionQuery = (
  { __typename?: 'Query' }
  & { ctaWithDriftInteraction: Maybe<(
    { __typename?: 'CtaWithDriftInteraction' }
    & Pick<CtaWithDriftInteraction, 'key' | 'text' | 'interactionId'>
  )> }
);

export type FormsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FormsQuery = (
  { __typename?: 'Query' }
  & { forms: Maybe<(
    { __typename: 'Forms' }
    & Pick<Forms, 'key' | 'value'>
  )> }
);

export type KeyValueQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type KeyValueQuery = (
  { __typename?: 'Query' }
  & { keyValue: Maybe<(
    { __typename?: 'KeyValue' }
    & Pick<KeyValue, 'key' | 'value'>
  )> }
);

export type SectionQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type SectionQuery = (
  { __typename?: 'Query' }
  & { section: Maybe<(
    { __typename?: 'Section' }
    & Pick<Section, 'key' | 'title' | 'body'>
  )> }
);


export const AccountKeyValueDocument = gql`
    query AccountKeyValue($id: String!) {
  accountKeyValue(id: $id) {
    key
    value
    __typename
  }
}
    `;

/**
 * __useAccountKeyValueQuery__
 *
 * To run a query within a React component, call `useAccountKeyValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountKeyValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountKeyValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountKeyValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountKeyValueQuery, AccountKeyValueQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountKeyValueQuery, AccountKeyValueQueryVariables>(AccountKeyValueDocument, baseOptions);
      }
export function useAccountKeyValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountKeyValueQuery, AccountKeyValueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountKeyValueQuery, AccountKeyValueQueryVariables>(AccountKeyValueDocument, baseOptions);
        }
export type AccountKeyValueQueryHookResult = ReturnType<typeof useAccountKeyValueQuery>;
export type AccountKeyValueLazyQueryHookResult = ReturnType<typeof useAccountKeyValueLazyQuery>;
export type AccountKeyValueQueryResult = ApolloReactCommon.QueryResult<AccountKeyValueQuery, AccountKeyValueQueryVariables>;
export const AccountMetadataDocument = gql`
    query AccountMetadata($id: String!) {
  accountMetadata(id: $id) {
    key
    title
    description
    keywords
    ogtype
    image {
      url
      title
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useAccountMetadataQuery__
 *
 * To run a query within a React component, call `useAccountMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAccountMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountMetadataQuery, AccountMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountMetadataQuery, AccountMetadataQueryVariables>(AccountMetadataDocument, baseOptions);
      }
export function useAccountMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountMetadataQuery, AccountMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountMetadataQuery, AccountMetadataQueryVariables>(AccountMetadataDocument, baseOptions);
        }
export type AccountMetadataQueryHookResult = ReturnType<typeof useAccountMetadataQuery>;
export type AccountMetadataLazyQueryHookResult = ReturnType<typeof useAccountMetadataLazyQuery>;
export type AccountMetadataQueryResult = ApolloReactCommon.QueryResult<AccountMetadataQuery, AccountMetadataQueryVariables>;
export const CopyQueryDocument = gql`
    query CopyQuery($locale: String, $preview: Boolean) {
  copy1: accountKeyValueCollection(locale: $locale, preview: $preview, skip: 0, limit: 1000, order: sys_firstPublishedAt_ASC) {
    items {
      key
      value
      __typename
    }
    __typename
  }
  copy2: accountKeyValueCollection(locale: $locale, preview: $preview, skip: 1000, limit: 1000, order: sys_firstPublishedAt_ASC) {
    items {
      key
      value
      __typename
    }
    __typename
  }
  copy3: accountKeyValueCollection(locale: $locale, preview: $preview, skip: 2000, limit: 1000, order: sys_firstPublishedAt_ASC) {
    items {
      key
      value
      __typename
    }
    __typename
  }
  accountMetadataCollection(locale: $locale, preview: $preview, limit: 1000, order: sys_firstPublishedAt_ASC) {
    items {
      key
      title
      description
      keywords
      ogtype
      image {
        url
        title
        __typename
      }
      __typename
    }
    __typename
  }
  formsCollection(limit: 200, locale: $locale, preview: $preview, order: sys_firstPublishedAt_ASC) {
    items {
      key
      value
      __typename
    }
    __typename
  }
}
    `;

/**
 * __useCopyQueryQuery__
 *
 * To run a query within a React component, call `useCopyQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyQueryQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useCopyQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CopyQueryQuery, CopyQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<CopyQueryQuery, CopyQueryQueryVariables>(CopyQueryDocument, baseOptions);
      }
export function useCopyQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CopyQueryQuery, CopyQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CopyQueryQuery, CopyQueryQueryVariables>(CopyQueryDocument, baseOptions);
        }
export type CopyQueryQueryHookResult = ReturnType<typeof useCopyQueryQuery>;
export type CopyQueryLazyQueryHookResult = ReturnType<typeof useCopyQueryLazyQuery>;
export type CopyQueryQueryResult = ApolloReactCommon.QueryResult<CopyQueryQuery, CopyQueryQueryVariables>;
export const CtaWithDriftInteractionDocument = gql`
    query CtaWithDriftInteraction($id: String!) {
  ctaWithDriftInteraction(id: $id) {
    key
    text
    interactionId
  }
}
    `;

/**
 * __useCtaWithDriftInteractionQuery__
 *
 * To run a query within a React component, call `useCtaWithDriftInteractionQuery` and pass it any options that fit your needs.
 * When your component renders, `useCtaWithDriftInteractionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCtaWithDriftInteractionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCtaWithDriftInteractionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CtaWithDriftInteractionQuery, CtaWithDriftInteractionQueryVariables>) {
        return ApolloReactHooks.useQuery<CtaWithDriftInteractionQuery, CtaWithDriftInteractionQueryVariables>(CtaWithDriftInteractionDocument, baseOptions);
      }
export function useCtaWithDriftInteractionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CtaWithDriftInteractionQuery, CtaWithDriftInteractionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CtaWithDriftInteractionQuery, CtaWithDriftInteractionQueryVariables>(CtaWithDriftInteractionDocument, baseOptions);
        }
export type CtaWithDriftInteractionQueryHookResult = ReturnType<typeof useCtaWithDriftInteractionQuery>;
export type CtaWithDriftInteractionLazyQueryHookResult = ReturnType<typeof useCtaWithDriftInteractionLazyQuery>;
export type CtaWithDriftInteractionQueryResult = ApolloReactCommon.QueryResult<CtaWithDriftInteractionQuery, CtaWithDriftInteractionQueryVariables>;
export const FormsDocument = gql`
    query Forms($id: String!) {
  forms(id: $id) {
    key
    value
    __typename
  }
}
    `;

/**
 * __useFormsQuery__
 *
 * To run a query within a React component, call `useFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FormsQuery, FormsQueryVariables>) {
        return ApolloReactHooks.useQuery<FormsQuery, FormsQueryVariables>(FormsDocument, baseOptions);
      }
export function useFormsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FormsQuery, FormsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FormsQuery, FormsQueryVariables>(FormsDocument, baseOptions);
        }
export type FormsQueryHookResult = ReturnType<typeof useFormsQuery>;
export type FormsLazyQueryHookResult = ReturnType<typeof useFormsLazyQuery>;
export type FormsQueryResult = ApolloReactCommon.QueryResult<FormsQuery, FormsQueryVariables>;
export const KeyValueDocument = gql`
    query KeyValue($id: String!) {
  keyValue(id: $id) {
    key
    value
  }
}
    `;

/**
 * __useKeyValueQuery__
 *
 * To run a query within a React component, call `useKeyValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useKeyValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKeyValueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useKeyValueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<KeyValueQuery, KeyValueQueryVariables>) {
        return ApolloReactHooks.useQuery<KeyValueQuery, KeyValueQueryVariables>(KeyValueDocument, baseOptions);
      }
export function useKeyValueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<KeyValueQuery, KeyValueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<KeyValueQuery, KeyValueQueryVariables>(KeyValueDocument, baseOptions);
        }
export type KeyValueQueryHookResult = ReturnType<typeof useKeyValueQuery>;
export type KeyValueLazyQueryHookResult = ReturnType<typeof useKeyValueLazyQuery>;
export type KeyValueQueryResult = ApolloReactCommon.QueryResult<KeyValueQuery, KeyValueQueryVariables>;
export const SectionDocument = gql`
    query Section($id: String!) {
  section(id: $id) {
    key
    title
    body
  }
}
    `;

/**
 * __useSectionQuery__
 *
 * To run a query within a React component, call `useSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SectionQuery, SectionQueryVariables>) {
        return ApolloReactHooks.useQuery<SectionQuery, SectionQueryVariables>(SectionDocument, baseOptions);
      }
export function useSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SectionQuery, SectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SectionQuery, SectionQueryVariables>(SectionDocument, baseOptions);
        }
export type SectionQueryHookResult = ReturnType<typeof useSectionQuery>;
export type SectionLazyQueryHookResult = ReturnType<typeof useSectionLazyQuery>;
export type SectionQueryResult = ApolloReactCommon.QueryResult<SectionQuery, SectionQueryVariables>;