import type { Maybe, OrderItem } from '@account/graphql/types.generated';

export const orderIncludesDigital = (itemSet?: OrderItem[]) => {
  const items = itemSet ? itemSet.filter(i => i.isDigitalSubscription) : [];
  return items.length > 0;
};

export const orderIsStandaloneExtendedWarrantyOrder = (itemSet: OrderItem[] = []) => {
  return itemSet.length === 1 && itemSet.every(item => itemIsExtendedWarranty(item));
};

export const orderIsBuyoutOnly = (itemSet: Maybe<OrderItem[]> = []) => {
  if (!itemSet) {
    return false;
  }
  const buyoutItems = itemSet.filter(i => isFaasBuyout(i.slug));
  /** Buyout orders will contain a buyout sku and an AAM  */
  return buyoutItems.length === 1 && itemSet.length === 2;
};

export const isFaasBuyout = (slug?: Maybe<string>) => {
  return slug && slug.toLowerCase().includes('opc-b-');
};

const itemIsExtendedWarranty = (item: OrderItem) => {
  const skuName = item.skuName!.toLowerCase();
  return extendedWarrantySkus.includes(skuName);
};

export const getCfuTypeFromWarranty = (warranty: OrderItem) => {
  const skuName = warranty.skuName!.toLowerCase();
  return bikeExtendedWarrantySkus.includes(skuName)
    ? 'Bike'
    : bikePlusExtendedWarrantySkus.includes(skuName)
    ? 'Bike+'
    : treadExtendedWarrantySkus.includes(skuName)
    ? 'Tread'
    : rowExtendedWarrantySkus.includes(skuName)
    ? 'Row'
    : guideExtendedWarrantySkus.includes(skuName)
    ? 'Guide'
    : '';
};

const bikeExtendedWarrantySkus = [
  'ext-bikecpo-warr-us-48m-adh',
  'ext-bike-warr-us-48m-adh',
];

const bikePlusExtendedWarrantySkus = [
  'ext-bikecpo-plus-warr-us-48m-adh',
  'ext-bike-plus-warr-us-48m-adh',
];

const treadExtendedWarrantySkus = ['ext-tread-warr-us-48m-adh'];

const rowExtendedWarrantySkus = ['ext-row-warr-us-48m-adh'];

const guideExtendedWarrantySkus = ['ext-guide-warr-us-24m-adh'];

export const extendedWarrantySkus = [
  bikeExtendedWarrantySkus,
  bikePlusExtendedWarrantySkus,
  treadExtendedWarrantySkus,
  rowExtendedWarrantySkus,
  guideExtendedWarrantySkus,
].flat();
