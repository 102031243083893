import { limitedEdition } from '@pelotoncycle/design-system';

export const UPSELL_TAGS = {
  accessory: 'upsellAccessory',
  bundle: 'upsellBundle',
  guide: 'upsellGuide',
  intro: 'upsellIntro',
  introMulti: 'upsellIntroMulti',
};

type ColorHexValue = string;
interface ColorSlugMap {
  [slug: string]: {
    colorName: string; // used for design system and page builder types (ex: roseGold)
    packageName: string; // used for the packageName property on the Selected Attribute event
    displayName: string; // used for query params and analytics (ex: galaxy)
    bikeColor: ColorHexValue;
  };
}

export const colorBikes: ColorSlugMap = {
  'bike-plus-patina-package-us': {
    colorName: 'patina',
    packageName: 'Peloton Bike+ in Ocean',
    displayName: 'ocean',
    bikeColor: limitedEdition.patina,
  },
  'bike-plus-peloton-red-package-us': {
    colorName: 'pelotonRed',
    packageName: 'Peloton Bike+ in Candy',
    displayName: 'candy',
    bikeColor: limitedEdition.pelotonRed,
  },
  'bike-plus-rose-gold-package-us': {
    colorName: 'roseGold',
    packageName: 'Peloton Bike+ in Galaxy',
    displayName: 'galaxy',
    bikeColor: limitedEdition.roseGold,
  },
  'bike-plus-color-package': {
    colorName: 'lilac',
    packageName: 'Peloton Bike+ in Lilac',
    displayName: 'lilac',
    bikeColor: limitedEdition.lilac,
  },
  'bike-plus-color-package-maize': {
    colorName: 'lime',
    packageName: 'Peloton Bike+ in Lime',
    displayName: 'lime',
    bikeColor: limitedEdition.lime,
  },
};

export const partnershipBikes: ColorSlugMap = {
  'bike-plus-umich-package-us': {
    colorName: 'michiganBlue',
    packageName: 'University of Michigan Peloton Bike+',
    displayName: 'michiganBlue',
    bikeColor: limitedEdition.michiganBlue,
  },
  'faas-pkg-umich': {
    colorName: 'michiganBlue',
    packageName: 'University of Michigan Peloton Bike',
    displayName: 'michiganBlue',
    bikeColor: limitedEdition.michiganBlue,
  },
  'faas-pkg-umich-bp': {
    colorName: 'michiganBlue',
    packageName: 'University of Michigan Peloton Bike+',
    displayName: 'michiganBlue',
    bikeColor: limitedEdition.michiganBlue,
  },
};

export const colorSlugMap: ColorSlugMap = {
  ...colorBikes,
  ...partnershipBikes,
};

export const partnershipSkus = {
  'PARTNERSHIP-TEST-SKU': true, // permanent unit test sku
  'BA02-0025': true, // umich-NA (ibas product sku)
  'BA02-0026': true, // umich-NA (rexon product sku)
  'BK02-0027': true, // umich-NA (ibas shipping sku)
  'BK02-0018': true, // umich-NA (rexon shipping sku)
};

export const limitedEditionSkus = {
  ...partnershipSkus,
  'LIMITED-EDITION-TEST-SKU': true, // permanent unit test sku
  'BA02-0020-BLUE': true, // blue-test
  'BA02-0020-MAIZE': true, // maize-test
  // product skus
  'BA02-0031': true, // peloton red, aka "candy"
  'BA02-0032': true, // rose gold, aka "galaxy"
  'BA02-0034': true, // patina, aka "ocean"
  // shipping skus
  'BK02-0031': true, // peloton red, aka "candy"
  'BK02-0032': true, // rose gold, aka "galaxy"
  'BK02-0034': true, // patina, aka "ocean"
};
