import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import { ALLOW_GRAPHQL_DEV_TOOLS } from '@peloton/app-config';
import { useOauth } from '@peloton/auth';
import { CHECKOUT_ACCESS_TOKEN_STORAGE_KEY } from '@peloton/auth/constants';
import type { ExtLinkEnv } from '@peloton/external-links';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from '@peloton/graphql/apolloToggle';
import { toClientNameAndVersion } from '@peloton/graphql/toClientNameAndVersion';
import { redirect } from '@peloton/navigation';
import { toLink, toUri } from '@peloton/with-apollo/v3/toClient';
import { useLoginUrl } from '@account/auth/OauthProvider';
import useLocalStorage from '@ecomm/hooks/useLocalStorage';

const NOT_LOGGED_IN_MESSAGE = 'Not logged in';

export const getHeadersWithToken = (headers: object, token?: string | null) => {
  if (!token) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};
// run account tests
const ProviderV3: React.FC<
  React.PropsWithChildren<{ env: ExtLinkEnv; name: string }>
> = ({ env, name, ...props }) => {
  const { isAuthenticated, getAccessTokenSilently } = useOauth();
  const loginUrl = useLoginUrl();
  const [checkoutAccessToken] = useLocalStorage(CHECKOUT_ACCESS_TOKEN_STORAGE_KEY, null);
  let checkoutToken = checkoutAccessToken as string | null;
  if (typeof checkoutToken === 'string') {
    // remove potential double quotes from token being stringified
    checkoutToken = checkoutToken.replace(/"/g, '');
  }
  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      return getHeadersWithToken(headers, token);
    } else {
      return getHeadersWithToken(headers, checkoutAccessToken);
    }
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const errorMessages = graphQLErrors.map(({ message }) => message);
      if (errorMessages.includes(NOT_LOGGED_IN_MESSAGE)) {
        redirect(loginUrl(window.location.href));
      }
    }
  });
  function nullable() {
    // Create a generic field policy that allows any field to be null by default:
    return {
      read(existing = null) {
        return existing;
      },
    };
  }
  const client = new ApolloClient({
    cache: new InMemoryCache({
      addTypename: true,
      // NOTE: The below typePolicies were added due to missing data from GQL queries that cause errors in the Apollo 3 Client
      // see this thread for the solution and more info: https://github.com/apollographql/apollo-client/issues/8677
      // TODO: GraphQL server should return null for missing fields so client doesn't think there is an error
      typePolicies: {
        Order: {
          fields: {
            isServiceBench: nullable(),
          },
        },
        Delivery: {
          fields: {
            start: nullable(),
            end: nullable(),
          },
        },
        Query: {
          fields: {
            salesOrder: {
              keyArgs: ['id', 'isReschedule'],
              read(existing) {
                return existing;
              },
              merge(existing, incoming) {
                return { ...existing, ...incoming };
              },
            },
            order: {
              keyArgs: false,
              merge(existing, incoming) {
                return { ...existing, ...incoming };
              },
              read(existing) {
                return existing;
              },
            },
          },
        },
      },
    }),
    // @ts-expect-error
    link: ApolloLink.from([errorLink, authLink, toLink(toUri(env))]),
    connectToDevTools: ALLOW_GRAPHQL_DEV_TOOLS,
    ...toClientNameAndVersion(name),
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ProviderV3;
