import React from 'react';

const HyattLogo: React.FC<
  React.PropsWithChildren<React.SVGProps<SVGSVGElement>>
> = props => (
  <svg
    width="192"
    height="16"
    viewBox="0 0 192 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M102.83 14.8933H105.864V7.66477H110.901V6.35757H105.864V1.59882H111.752V0.291626H102.83V14.8933Z"
      fill="white"
    />
    <path
      d="M97.398 7.58124C97.3924 5.18657 96.8167 3.23968 95.7348 1.93804C94.7279 0.728193 93.4208 0.088501 91.7631 0.088501C90.1027 0.088501 88.7983 0.728193 87.7887 1.93804C86.704 3.23968 86.1283 5.18657 86.1255 7.58124C86.1255 7.58124 86.1255 7.5868 86.1255 7.59236C86.1255 7.59792 86.1255 7.59792 86.1255 7.60349C86.1311 9.99816 86.7068 11.945 87.7887 13.2467C88.7955 14.4565 90.1027 15.0962 91.7631 15.0962C93.4235 15.0962 94.7279 14.4565 95.7348 13.2467C96.8195 11.945 97.3952 9.99816 97.398 7.60349C97.398 7.60349 97.398 7.59792 97.398 7.59236C97.398 7.5868 97.398 7.5868 97.398 7.58124ZM91.7603 13.7974C90.3864 13.7974 89.4519 12.5708 89.4491 7.59236C89.4491 2.61389 90.3836 1.38735 91.7603 1.38735C93.1371 1.38735 94.0688 2.61389 94.0688 7.59236C94.0688 12.5708 93.1343 13.7974 91.7576 13.7974H91.7603Z"
      fill="white"
    />
    <path
      d="M63.0348 0.202976V14.8046H67.6434C71.1867 14.8046 73.2448 12.0846 73.2448 7.50659C73.2448 2.92862 71.1867 0.200195 67.6434 0.200195H63.0348V0.202976ZM67.0482 1.50739C68.9978 1.50739 70.2132 4.05781 70.2132 7.51771C70.2132 10.897 69.0535 13.3945 67.1817 13.4891H66.0692V1.50739H67.0482Z"
      fill="white"
    />
    <path
      d="M44.5002 14.8048L41.324 8.34668C42.8592 7.72089 43.5823 6.48601 43.5823 4.46959C43.5823 1.6021 41.9247 0.203125 38.2479 0.203125H34.3513V14.8048H37.3857V8.8334C37.3857 8.8334 38.2979 8.8334 38.3258 8.8334L41.2628 14.8076H44.5002V14.8048ZM37.3885 7.5262V1.50754H38.1978C39.8499 1.50754 40.5257 2.6562 40.5257 4.46959C40.5257 6.51938 39.708 7.5262 38.1978 7.5262H37.3885Z"
      fill="white"
    />
    <path
      d="M12.0429 0.203125L10.1822 8.3133L8.2854 0.203125H6.76126L4.90059 8.31608L3.00377 0.203125H0L3.4154 14.8048H4.93953L6.83358 6.53607L8.69703 14.8048H10.2212L13.6727 0.203125H12.0429Z"
      fill="white"
    />
    <path
      d="M58.4956 13.4976H53.1667V0.203125H50.1323V14.8048H58.4956V13.4976Z"
      fill="white"
    />
    <path
      d="M28.8721 7.49274C28.8665 5.09806 28.2908 3.15118 27.2089 1.84954C26.2021 0.639692 24.8949 0 23.2372 0C21.5768 0 20.2724 0.639692 19.2628 1.84954C18.1781 3.15118 17.6024 5.09806 17.5996 7.49274C17.5996 7.49274 17.5996 7.4983 17.5996 7.50386C17.5996 7.50942 17.5996 7.50942 17.5996 7.51499C17.6052 9.90966 18.1809 11.8565 19.2628 13.1582C20.2696 14.368 21.5768 15.0077 23.2372 15.0077C24.8977 15.0077 26.2021 14.368 27.2089 13.1582C28.2936 11.8565 28.8693 9.90966 28.8721 7.51499C28.8721 7.51499 28.8721 7.50942 28.8721 7.50386C28.8721 7.4983 28.8721 7.4983 28.8721 7.49274ZM23.2345 13.7061C21.8605 13.7061 20.926 12.4796 20.9232 7.50108C20.9232 2.52261 21.8577 1.29607 23.2345 1.29607C24.6112 1.29607 25.5429 2.52261 25.5429 7.50108C25.5429 12.4796 24.6084 13.7061 23.2317 13.7061H23.2345Z"
      fill="white"
    />
    <path
      d="M167.9 1.51571H171.221V14.799H174.255V1.51571H177.576V0.197388H167.9V1.51571Z"
      fill="white"
    />
    <path
      d="M182.324 1.51571H185.645V14.799H188.679V1.51571H192V0.197388H182.324V1.51571Z"
      fill="white"
    />
    <path
      d="M162.018 14.799H165.161L160.488 0.197388H158.555L153.585 14.799H155.504L156.806 10.9748H160.791L162.015 14.799H162.018ZM157.251 9.67317L158.864 4.93945L160.38 9.67317H157.254H157.251Z"
      fill="white"
    />
    <path
      d="M130.834 6.86687H126.868V0.197388H123.834V14.799H126.868V8.18519H130.834V14.799H133.863V0.197388H130.834V6.86687Z"
      fill="white"
    />
    <path
      d="M149.51 0.197388L146.128 6.45524L142.71 0.197388H139.428L143.731 8.02388V14.799H146.79V8.12678L151.065 0.197388H149.51Z"
      fill="white"
    />
  </svg>
);

export default HyattLogo;
